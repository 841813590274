import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useDeviceChange } from '../../../dashboard/components/HomeBottomBar/DevicesSettingsModal/hooks';
import { useConferenceState } from '../../../../hooks/conferenceContext';
import { MytaverseLogger } from '../../../../helpers/logger';
import { getCatchErrorMessage } from '../../../../helpers/error';

import { IDevicePermissionState } from '../../../../interfaces/permissions';

export const useInitMicrophonesDevices = () => {
  const [initializing, setInitializing] = useState(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [permissionState, setPermissionState] =
    useState<IDevicePermissionState>('granted');

  const { activeMicroDeviceId, setActiveMicroDeviceId } = useConferenceState();

  useDeviceChange(setInitializing);

  useAsyncEffect(async () => {
    const initMicrophonesDevices = async (state: IDevicePermissionState) => {
      setPermissionState(state);

      switch (state) {
        case 'prompt': {
          setInitializing(false);
          navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          break;
        }
        case 'denied': {
          setDevices([]);
          setInitializing(false);
          break;
        }
        case 'granted': {
          if (!initializing) {
            return;
          }

          if (!navigator.mediaDevices?.enumerateDevices) {
            throw new Error('enumerateDevices() not supported.');
          }

          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const microDevices = (devices || []).filter(
              (device) => device.kind === 'audioinput',
            );

            localStorage.setItem('microDevices', JSON.stringify(microDevices));

            const activeDeviceId =
              microDevices.find(
                ({ deviceId }) => deviceId === activeMicroDeviceId,
              )?.deviceId || microDevices[0]?.deviceId;

            setDevices(microDevices);
            if (activeDeviceId) {
              setActiveMicroDeviceId(activeDeviceId);
            }
            setInitializing(false);
          } catch (error) {
            throw Error(getCatchErrorMessage(error));
          }
          break;
        }
      }
    };

    try {
      const permissionData = await navigator.permissions.query({
        // eslint-disable-next-line
        name: 'microphone' as any,
      });

      initMicrophonesDevices(permissionData.state);

      permissionData.onchange = () => {
        initMicrophonesDevices(permissionData.state);
      };
    } catch (error) {
      initMicrophonesDevices('denied');
      MytaverseLogger.error(getCatchErrorMessage(error));
    }
  }, [activeMicroDeviceId, initializing, setActiveMicroDeviceId]);

  return {
    initializing,
    devices,
    activeDeviceId: activeMicroDeviceId,
    setActiveDeviceId: setActiveMicroDeviceId,
    permissionState,
  };
};
