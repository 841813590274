import React from 'react';

import { Box } from '@mui/material';

import ChatDrawer from '../../components/ChatDrawer';
import DashboardContent from '../../components/DashboardContent';
import DashboardTopBar from '../../components/DashboardTopBar';
import EventDrawer from '../../components/EventDrawer';
import HomeBottomBar from '../../components/HomeBottomBar';
import PoiPreview from '../../components/PoiPreview';
import OrientationOverlay from '../../components/OrientationOverlay';
import LeaveRegionDialog from '../../components/LeaveRegionDialog';
import TeleportToRoomDialog from '../../components/TeleportToRoomDialog';
import MillicastShareScreenModal from '../../components/MillicastSharing/MillicastScreen';
import MillicastShareVideoModal from '../../components/MillicastSharing/MillicastVideo';
import MillicastScreenBroadcastingData from '../../components/MillicastSharing/MillicastScreenBroadcastingData';
import AdminSettings from '../../components/AdminSettings';
import DolbyCameraDialog from '../../components/DolbyCameraDialog';

import { IHomePageView } from './interfaces';

import { useStyles } from './styles';
import { StreamingProviders } from '../../providers/MytaverseEventProvider/interfaces';

const HomePageView = ({
  showOverlay,
  showControls,
  openMillicastShareScreenModal,
  openMillicastScreenBroadcastingDataModal,
  openAdminSettingsModal,
  gameReadyToPlay,
  streamingProvider,
}: IHomePageView) => {
  const classes = useStyles(showOverlay);

  return (
    <>
      <Box id="home" sx={classes.homePage}>
        {streamingProvider === StreamingProviders.CoreWeave &&
        gameReadyToPlay &&
        showControls ? (
          <DashboardTopBar />
        ) : null}
        {streamingProvider !== StreamingProviders.CoreWeave && showControls ? (
          <DashboardTopBar />
        ) : null}
        <EventDrawer />
        <ChatDrawer />
        <Box sx={classes.root}>
          <DashboardContent />
          <PoiPreview />
          <LeaveRegionDialog />
          <TeleportToRoomDialog />
          <DolbyCameraDialog />
          {openAdminSettingsModal && <AdminSettings />}
          {openMillicastShareScreenModal && <MillicastShareScreenModal />}
          <MillicastShareVideoModal />
          {openMillicastScreenBroadcastingDataModal && (
            <MillicastScreenBroadcastingData />
          )}
        </Box>
        {streamingProvider === StreamingProviders.CoreWeave &&
        gameReadyToPlay &&
        showControls ? (
          <HomeBottomBar />
        ) : null}

        {streamingProvider !== StreamingProviders.CoreWeave && showControls ? (
          <HomeBottomBar />
        ) : null}
      </Box>
      {showOverlay && <OrientationOverlay />}
    </>
  );
};

export default HomePageView;
