/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { useStyles } from './styles';
import { useMytaverseEvent } from '../../providers';
import { IParticipant } from '../../../../interfaces/participants';
import SharingWindowVideo from './SharingWindowVideo/SharingWindowVideo';
import { useNotificationContext } from '../../../../providers/NotificationProvider';
import { useChatState } from '../../../../hooks/context';
import { useConference } from '../DashboardContent/Dolby';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const SharingWindow = () => {
  const {
    participants,
    sharingWindowFullsceen,
    setSharingWindowFullscreen,
    currentParticipantId,
    isMinimizedScreenSharing,
  } = useMytaverseEvent();
  const { screenMediaStreams } = useConference();
  const { open: isOpenChat } = useChatState();

  const [streamingParticipant, setStreamingParticipant] =
    React.useState<IParticipant | null>(null);

  const { setHasSharedScreen } = useNotificationContext();

  const classes = useStyles(sharingWindowFullsceen, isOpenChat);

  React.useEffect(() => {
    if (screenMediaStreams.length) {
      const participant =
        participants.find(
          ({ id }) => id === screenMediaStreams[0].participantId,
        ) || null;

      setStreamingParticipant(participant);
    } else {
      setStreamingParticipant(null);
    }
  }, [screenMediaStreams]);

  useEffect(() => {
    const hasSharedScreen = sharingWindowFullsceen
      ? false
      : !!streamingParticipant;

    setHasSharedScreen(hasSharedScreen);
  }, [sharingWindowFullsceen, streamingParticipant]);

  if (isMinimizedScreenSharing && !sharingWindowFullsceen) {
    return null;
  }

  return (
    <Box component="div" sx={classes.root} id="chatWindow">
      {streamingParticipant ? (
        <SharingWindowVideo
          participant={streamingParticipant as IParticipant}
          mediaStream={screenMediaStreams[0].stream}
          muted={streamingParticipant.id === currentParticipantId}
        />
      ) : null}
      <IconButton sx={classes.fullscreen} onClick={setSharingWindowFullscreen}>
        {sharingWindowFullsceen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
      <IconButton
        sx={classes.fullscreenCollapse}
        id="gameCollapse"
        onClick={setSharingWindowFullscreen}
      >
        <CloseFullscreenIcon />
      </IconButton>
    </Box>
  );
};

export default SharingWindow;
