import React, { useCallback, useEffect } from 'react';
import { createBrowserHistory, Update } from 'history';
import { NavigationType } from 'react-router-dom';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import ROUTES from '../../../../../constants/routes';
import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';
import { MytaverseLogger } from '../../../../../helpers/logger';

const history = createBrowserHistory();

interface IProps {
  cleanStates: () => void;
  initMessageSended: boolean;
  ue5WebsocketConnected: boolean;
  setUe5WebsocketConnected: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useBackButton = ({
  cleanStates,
  initMessageSended,
  ue5WebsocketConnected,
  setUe5WebsocketConnected,
}: IProps) => {
  const { currentEventId, setCurrentEventId, sendJSONMessageToWebSocket } =
    useMytaverse();

  const handleCloseGame = useCallback(() => {
    sendJSONMessageToWebSocket({ action: WebsocketAction.CloseGame });
    setUe5WebsocketConnected(false);
  }, [setUe5WebsocketConnected, sendJSONMessageToWebSocket]);

  useEffect(() => {
    const unlisten = history.listen(({ action, location }: Update) => {
      if (
        action === NavigationType.Pop &&
        location.pathname === ROUTES.DEVICES_TEST
      ) {
        setCurrentEventId('');
        cleanStates();

        let reload = false;
        if (currentEventId) {
          if (initMessageSended && !ue5WebsocketConnected) {
            reload = true;
          } else {
            handleCloseGame();
          }
        }
        sendCustomSentryBreadcrumb({ message: 'press back button in event' });
        MytaverseLogger.log('press back button in event');

        history.replace(ROUTES.SELECT_EVENT);

        if (reload) {
          window.location.reload();
        }
      }
    });

    return unlisten;
  }, [
    cleanStates,
    setCurrentEventId,
    currentEventId,
    initMessageSended,
    ue5WebsocketConnected,
    handleCloseGame,
    setUe5WebsocketConnected,
  ]);
};
