import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import { useConference } from '../../../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../../../providers';
import { sendCustomSentryBreadcrumb } from '../../../../../../helpers/sentry';

import ParticipantAvatar from '../../../DashboardContent/ParticipantAvatar';
import WarningButtonBadge from '../../../WarningButtonBadge';
import HomeBarButton from '../../../HomeBarButton';
import DolbyShareWebcamButton from './DolbyShareWebcamButton';

import { useStyles } from '../styles';

const WebcamSharing = () => {
  const classes = useStyles();

  const {
    ueWebcamScreenName,
    isConferenceInitialized,
    isConferenceListener,
    videoStarted,
    toggleVideo,
    cameras,
  } = useConference();

  const { currentParticipant } = useMytaverseEvent();

  const { t: translate } = useTranslation('common');

  const showDolbyShareWebcam = !!ueWebcamScreenName;
  const cameraDeviceId = cameras.length ? cameras[0].deviceId : '';

  const handleVideoClick = () => {
    sendCustomSentryBreadcrumb({
      message: `${!videoStarted ? 'start' : 'stop'} video`,
    });
    toggleVideo();
  };

  const showWarningButtonBadge = useCallback(
    (icon: ReactNode) => {
      return (
        <WarningButtonBadge classes={{ root: classes.badge(cameraDeviceId) }}>
          {icon}
        </WarningButtonBadge>
      );
    },
    [cameraDeviceId],
  );

  if (videoStarted && !!cameraDeviceId && !isConferenceListener) {
    return <ParticipantAvatar participant={currentParticipant} />;
  }

  if (showDolbyShareWebcam) {
    return (
      <DolbyShareWebcamButton
        cameraDeviceId={cameraDeviceId}
        onVideoClick={handleVideoClick}
        iconColorToggle={classes.stop}
        showWarningBadge={showWarningButtonBadge}
      />
    );
  }

  return (
    <HomeBarButton
      label={translate('tooltips.toggleCam')}
      handleClick={handleVideoClick}
      disabled={!isConferenceInitialized || isConferenceListener}
      icon={
        <WarningButtonBadge classes={{ root: classes.badge(cameraDeviceId) }}>
          <VideocamOffIcon />
        </WarningButtonBadge>
      }
    />
  );
};

export default WebcamSharing;
