import { useCallback, useState } from 'react';
import { useManualTimer } from '../../../../auth/hooks/timer';
import { useMytaverseEvent } from '../../../providers';
import { mytaverseWebsocketLogger } from '../../../../../providers/MytaverseProvider';
import { UseHandleUEConnectionType } from './interfaces';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';
import { GameCastStreamSession } from '../GameCast/interfaces';

export const useHandleUEConnection: UseHandleUEConnectionType = () => {
  const {
    isTeleporting,
    isTeleportingToRoomByUnreal,
    setIsTeleportingToRoomByUnreal,
    setUe5WebsocketConnected,
    streamingProvider,
    setUe5CoreWeaveDisabled,
  } = useMytaverseEvent();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [streamSession, setStreamSession] =
    useState<GameCastStreamSession | null>(null);
  const { intervalId, startTimer, clearTimer } = useManualTimer();

  const isTeleportRun = isTeleporting || isTeleportingToRoomByUnreal;

  const handleUe5WsConnected = useCallback(() => {
    mytaverseWebsocketLogger.log('UE5 connected');

    setTimeout(() => {
      setUe5WebsocketConnected(true);
    }, 8000);

    if (streamingProvider === StreamingProviders.CoreWeave) {
      setTimeout(() => {
        setUe5CoreWeaveDisabled(false);
      }, 8000);
    }

    if (isTeleportRun) {
      if (isTeleportingToRoomByUnreal) {
        mytaverseWebsocketLogger.log(
          'Close teleport modal due to UE5 connected',
        );
        setIsTeleportingToRoomByUnreal(false);
      }
    }

    if (intervalId) {
      clearTimer();
    }
  }, [
    isTeleportingToRoomByUnreal,
    setIsTeleportingToRoomByUnreal,
    isTeleportRun,
    intervalId,
    clearTimer,
    streamingProvider,
  ]);

  const handleUe5WsDisconnected = useCallback(async () => {
    if (!isTeleportRun) {
      mytaverseWebsocketLogger.error('UE5 disconnected');
    } else {
      mytaverseWebsocketLogger.log('UE5 disconnected due teleport to room');
    }

    setUe5WebsocketConnected(false);

    //for CoreWeave there is no need to display notification and reload page
    if (streamingProvider === StreamingProviders.CoreWeave) {
      setUe5CoreWeaveDisabled(true);
      return;
    }

    if (isTeleportRun) {
      return;
    }

    // TODO: Reimplement AWS GC reconnection
    if (streamingProvider === StreamingProviders.GameCast) {
      try {
        handleUe5WsConnected();
      } catch (error) {
        mytaverseWebsocketLogger.error(error);
      }
    }

    startTimer();
  }, [isTeleportRun, startTimer, streamingProvider]);

  return {
    handleUe5WsConnected,
    handleUe5WsDisconnected,
  };
};
