/* eslint-disable @typescript-eslint/no-inferrable-types */
import React from 'react';

import { useMytaverseEvent } from '../../../../providers';
import MillicastVideoView from './MilicastVideoView';
import MillicastService from '../helpers';
import useAsyncEffect from 'use-async-effect';
import EventsService from '../../../../../../services/EventsService';
import { IMillicastPublisher } from '../../../../../../interfaces/millicastPublisher';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../components/Notification';
import { getCatchErrorMessage } from '../../../../../../helpers/error';

import { useConferenceState } from '../../../../../../hooks/conferenceContext';

const MillicastVideo = () => {
  const {
    currentEvent,
    shareMediaParams,
    shareVideoPublishers,
    currentRegion,
    setShareVideoPublishers,
    startShareVideo,
    stopShareVideo,
    shareVideoMediaStream,
    setShareVideoMediaStream,
    shareMillicastVideoWithSound,
    closeShareVideoModal,
    loadingShareVideoModal,
    setLoadingShareVideoModal,
    getMillicastStreamTokens,
  } = useMytaverseEvent();

  const { showNotification, getMillicastNotification } = useNotification();

  const { activeCameraDeviceId } = useConferenceState();

  useAsyncEffect(async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: activeCameraDeviceId,
          frameRate: { min: 20, max: 30, ideal: 30 },
        },
        audio: true,
      });

      setShareVideoMediaStream((prev) => {
        if (prev) {
          MillicastService.stopMediaStreamTracks(prev);
        }

        return mediaStream;
      });
    } catch (error) {
      showNotification(
        getMillicastNotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Can't initialize camera: ${getCatchErrorMessage(error)}`,
        }),
      );
      await closeShareVideoModal();
    }
  }, [activeCameraDeviceId, closeShareVideoModal]);

  const onShareVideoStreamClick = React.useCallback(async () => {
    setLoadingShareVideoModal(true);

    if (currentEvent && shareMediaParams && shareVideoMediaStream) {
      const streamName = EventsService.getMillicastStreamName(
        shareMediaParams.roomId,
        shareMediaParams.region || '',
        shareMediaParams.screenName,
      );

      let unlockScreen = false;

      try {
        const millicastTokens = await getMillicastStreamTokens(streamName);

        if (!millicastTokens) {
          throw new Error(`Can't load millicast tokens`);
        }

        if (!shareMillicastVideoWithSound) {
          shareVideoMediaStream.getAudioTracks().forEach((track) => {
            track.stop();
          });
        }

        const publisher = (await MillicastService.broadcast(
          millicastTokens.publishingToken,
          millicastTokens.streamName,
          shareVideoMediaStream,
        )) as unknown as IMillicastPublisher;

        publisher.region = currentRegion?.region || null;
        publisher.newPanel = true;

        await startShareVideo(
          publisher,
          millicastTokens,
          !shareMillicastVideoWithSound,
        );
      } catch (error: unknown | Error) {
        unlockScreen = true;
        showNotification(
          getMillicastNotification({
            type: NOTIFICATION_TYPES.ERROR,
            message: getCatchErrorMessage(error),
          }),
        );
      }

      setLoadingShareVideoModal(false);
      await closeShareVideoModal(unlockScreen);
    }
  }, [
    shareVideoPublishers,
    setShareVideoPublishers,
    startShareVideo,
    stopShareVideo,
    currentRegion,
    currentEvent,
    shareMediaParams,
    shareVideoMediaStream,
    shareMillicastVideoWithSound,
    getMillicastStreamTokens,
    closeShareVideoModal,
  ]);
  return (
    <MillicastVideoView
      loading={loadingShareVideoModal}
      onShareVideoStreamClick={onShareVideoStreamClick}
      shareVideoPublishers={shareVideoPublishers}
      mediaStream={shareVideoMediaStream}
    />
  );
};
export default MillicastVideo;
