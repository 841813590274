import { COLORS } from '../../../../constants/colors';
import { useMytaverseEvent } from '../../providers';
import { StreamingProviders } from '../../providers/MytaverseEventProvider/interfaces';

/* eslint-disable no-nested-ternary */
export const useStyles = (
  sharingWindowFullsceen: boolean,
  sharingMedia: boolean,
  isOpenChat: boolean,
  streamingProvider: StreamingProviders | null,
) => {
  const { isMinimizedScreenSharing } = useMytaverseEvent();
  return {
    root: {
      position: sharingWindowFullsceen ? 'fixed' : 'relative',
      height: '100vh',
      right: 0,
      top: 0,
      '&:hover': {
        '& #gameFull': {
          display: sharingMedia ? 'flex' : 'none !important',
        },
        '& #gameCollapse': {
          display: sharingMedia ? 'flex' : 'none !important',
        },
      },
      '& video': {
        width:
          sharingMedia && sharingWindowFullsceen
            ? 400
            : isOpenChat
            ? 'calc(100vw - 400px)'
            : '100vw',
        height: sharingMedia && sharingWindowFullsceen ? 225 : '100vh',
        zIndex: sharingMedia && sharingWindowFullsceen ? 15 : 5,
      },

      '& #coreweaveWrapper': {
        width:
          sharingMedia && sharingWindowFullsceen
            ? 400
            : isOpenChat
            ? 'calc(100vw - 400px)'
            : '100vw',
        height: sharingMedia && sharingWindowFullsceen ? 225 : '100vh',
        zIndex: 5,
        right: 0,
        top: 0,
        display:
          sharingMedia && isMinimizedScreenSharing && sharingWindowFullsceen
            ? 'none'
            : 'flex',
      },

      '& #chatWindow': {
        width: sharingMedia
          ? sharingWindowFullsceen
            ? 'calc(100vw - 400px)'
            : 400
          : 0,
        height: sharingMedia ? (sharingWindowFullsceen ? '100vh' : 225) : 0,
        zIndex:
          streamingProvider === StreamingProviders.CoreWeave
            ? sharingWindowFullsceen
              ? 1
              : 11
            : streamingProvider === StreamingProviders.Pureweb &&
              sharingWindowFullsceen &&
              !isMinimizedScreenSharing
            ? -1
            : streamingProvider === StreamingProviders.GameCast &&
              sharingWindowFullsceen
            ? -1
            : 1,
      },
    },
    loading: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      top: 50,
      color: COLORS.MYTAVERSE_BLUE,
      fontSize: 22,
    },
  };
};
