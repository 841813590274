import React, {
  ReactNode,
  useContext,
  useEffect,
  // useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import {
  DefaultStreamerOptions,
  ILocalAgent,
  InputEmitter,
  ModelDefinition,
  PlatformNext,
  StreamerStatus,
  UndefinedModelDefinition,
} from '@pureweb/platform-sdk';
import useAsyncEffect from 'use-async-effect';
import {
  System,
  useLaunchRequest,
  useStreamer,
} from '@pureweb/platform-sdk-react';

import { useParticipantPositionListener } from './hooks';

import purewebLogger from './logger';
import PurewebClientOptions from './helpers';
import { useMytaverseEvent } from '../../../providers';

import { useNotification } from '../../../../../components/Notification';
import { getCatchErrorMessage } from '../../../../../helpers/error';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../constants/routes';

interface IPurewebContext {
  clientOptions: PurewebClientOptions | null;
  loading: boolean;
  launch: () => Promise<void>;
  streamerStatus: StreamerStatus;
  inputEmitter: InputEmitter;
  videoStream: MediaStream;
  audioStream: MediaStream;
  messageSubject: Subject<string>;
}

// @ts-ignore
const PurewebContext = React.createContext<IPurewebContext>({});

export const usePureweb = () => useContext(PurewebContext);

type PurewebProviderProps = {
  children: ReactNode;
};

const platform = new PlatformNext();

export const PurewebProvider: React.FC<PurewebProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [modelDefinition, setModelDefinition] = useState(
    new UndefinedModelDefinition(),
  );
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const localAgent = React.useRef<ILocalAgent>();
  const {
    gameSound,
    clientOptions,
    // initMessageSended,
    // currentRoom,
    // ue5WebsocketConnected,
  } = useMytaverseEvent();
  const streamerOptions = DefaultStreamerOptions;
  const { showNotification, getStreamingNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  // const unrealEngineResponseTimer = useRef<NodeJS.Timer | null>(null);
  // const streamerStatusTimer = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (!System.IsBrowserSupported()) {
      purewebLogger.error(translate('notifications.unsupportedBrowser'));
    }
  }, []);

  React.useEffect(() => {
    if (!clientOptions) {
      return;
    }

    try {
      platform.initialize({
        endpoint: clientOptions.Endpoint || 'https://api.pureweb.io',
      });
    } catch (error) {
      // const notificationConfig = getStreamingNotification({
      //   message: translate('notifications.capacityIssue'),
      // });
      // showNotification(notificationConfig);
    }
  }, [clientOptions]);

  const disconnectLocalAgent = React.useCallback(() => {
    if (!localAgent.current) {
      return;
    }

    localAgent.current.disconnect();
    purewebLogger.info(
      `Disconnected Pureweb Local Agent: ${localAgent.current?.id}`,
    );

    localAgent.current = undefined;
  }, [localAgent]);

  useAsyncEffect(async () => {
    if (!clientOptions) {
      return;
    }

    purewebLogger.info(
      `Initializing available models: ${clientOptions.ProjectId}`,
    );

    try {
      try {
        await platform.useAnonymousCredentials(
          clientOptions.ProjectId,
          clientOptions.EnvironmentId,
        );
      } catch (error) {
        // const notificationConfig = getStreamingNotification({
        //   message: translate('notifications.capacityIssue'),
        // });
        // showNotification(notificationConfig);
      }

      localAgent.current = await platform.connect();

      purewebLogger.info(`Connected Pureweb Agent: ${localAgent.current?.id}`);

      streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers;
      streamerOptions.forceRelay = clientOptions.ForceRelay;
      const models = await platform.getModels();

      setAvailableModels(models);
      if (models.length === 0) {
        purewebLogger.error(translate('notifications.noModelsAvailable'));
        // const notificationConfig = getStreamingNotification({
        //   message: translate('notifications.capacityIssue'),
        // });
        // showNotification(notificationConfig);
      }

      purewebLogger.debug('Available models', models);
    } catch (error: unknown | Error) {
      purewebLogger.error(error);
    }

    return () => {
      if (!localAgent.current) {
        return;
      }

      try {
        disconnectLocalAgent();
      } catch (error: unknown | Error) {
        purewebLogger.error(error);
      }
    };
  }, [disconnectLocalAgent]);

  React.useEffect(() => {
    if (!clientOptions) {
      return;
    }

    if (availableModels?.length) {
      const selectedModels = availableModels.filter(
        (model: ModelDefinition): boolean => {
          if (clientOptions.ModelId === model.id) {
            if (
              clientOptions.Version &&
              clientOptions.Version === model.version
            ) {
              return true;
            }

            if (!clientOptions.Version && model.active) {
              return true;
            }
          }

          return false;
        },
      );

      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0]);
      } else {
        purewebLogger.error(translate('notifications.modelDoesNotExist'));
      }
    }
  }, [availableModels, clientOptions]);

  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(
    platform,
    modelDefinition,
    {
      regionOverride: clientOptions?.regionOverride,
      virtualizationProviderOverride:
        clientOptions?.virtualizationProviderOverride,
    },
  );

  const audio = React.useRef(new Audio());
  audio.current.autoplay = true;

  useEffect(() => {
    audio.current.volume = gameSound;
  }, [gameSound]);

  const [
    streamerStatus,
    inputEmitter,
    videoStream,
    audioStream,
    messageSubject,
  ] = useStreamer(platform, launchRequest, streamerOptions);

  useParticipantPositionListener(messageSubject);

  useEffect(() => {
    if (audioStream) {
      audio.current.srcObject = audioStream;
    }
  }, [audioStream]);

  useEffect(() => {
    return () => {
      if (audio.current) {
        audio.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Connected) {
      setLoading(false);
    } else if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }
  }, [streamerStatus]);

  // useEffect(() => {
  //   if (!initMessageSended || currentRoom || ue5WebsocketConnected) {
  //     if (unrealEngineResponseTimer.current) {
  //       clearTimeout(unrealEngineResponseTimer.current);
  //       unrealEngineResponseTimer.current = null;
  //     }
  //
  //     return;
  //   }
  //
  //   unrealEngineResponseTimer.current = setTimeout(() => {
  //     const notificationConfig = getStreamingNotification({
  //       message: translate('notifications.noGameSession'),
  //     });
  //
  //     showNotification(notificationConfig);
  //   }, 42000);
  // }, [initMessageSended, ue5WebsocketConnected, currentRoom]);

  // useEffect(() => {
  //   if (streamerStatus === StreamerStatus.New && !streamerStatusTimer.current) {
  //     streamerStatusTimer.current = setTimeout(() => {
  //       const notificationConfig = getStreamingNotification({
  //         message: translate('notifications.noGameSession'),
  //       });
  //
  //       showNotification(notificationConfig);
  //     }, 30000);
  //
  //     return;
  //   }
  //
  //   if (streamerStatusTimer.current) {
  //     clearInterval(streamerStatusTimer.current);
  //     streamerStatusTimer.current = null;
  //   }
  // }, [streamerStatus]);

  useEffect(
    () => () => {
      if (!localAgent.current) {
        return;
      }

      try {
        disconnectLocalAgent();
      } catch (error: unknown | Error) {
        purewebLogger.error(translate('notifications.modelDoesNotExist'));
      }
    },
    [disconnectLocalAgent],
  );

  const launch = React.useCallback(async () => {
    if (!clientOptions) {
      return;
    }

    audio.current.load();

    if (clientOptions.LaunchType !== 'local') {
      try {
        await queueLaunchRequest();
      } catch (error: unknown | Error) {
        showNotification(
          getStreamingNotification({
            message: translate('notifications.noScheduledMachines'),
          }),
        );

        purewebLogger.error(error);

        navigate(ROUTES.SELECT_EVENT);
      }
    }
  }, [clientOptions, queueLaunchRequest]);

  React.useEffect(() => {
    purewebLogger.info(`Launch request status: ${JSON.stringify(status)}`);
    purewebLogger.info(`Streamer status: ${streamerStatus}`);

    if (status.status === 'error') {
      purewebLogger.error(status.message as string);
    }
  }, [status, streamerStatus]);

  if (!clientOptions || !clientOptions.isValid()) {
    purewebLogger.error(translate('notifications.clientConfigurationError'));
  }

  React.useEffect(() => {
    if (streamerStatus === StreamerStatus.Disconnected) {
      purewebLogger.error(translate('notifications.disconnectedFromStream'));
    }

    if (streamerStatus === StreamerStatus.Failed) {
      purewebLogger.error(translate('notifications.failureDuringStream'));
    }

    if (streamerStatus === StreamerStatus.Withdrawn) {
      purewebLogger.error(
        translate('notifications.streamerContributionWithdrawn'),
      );
    }
  }, [streamerStatus]);

  useAsyncEffect(async () => {
    if (modelDefinition instanceof UndefinedModelDefinition) {
      return;
    }

    try {
      await launch();
    } catch (e: unknown | Error) {
      showNotification(
        getStreamingNotification({
          message: getCatchErrorMessage(e),
        }),
      );

      purewebLogger.error(e);

      navigate(ROUTES.SELECT_EVENT);
    }
  }, [modelDefinition]);

  if (!clientOptions) {
    return null;
  }
  return (
    <PurewebContext.Provider
      value={{
        clientOptions,
        launch,
        loading,
        streamerStatus,
        inputEmitter,
        videoStream,
        audioStream,
        messageSubject,
      }}
    >
      {children}
    </PurewebContext.Provider>
  );
};
