import { getCatchErrorMessage } from './error';

import { SessionStorage } from '../constants/storage';

export const getSessionStorageValue = <T>(key: SessionStorage): T | null => {
  try {
    const item = window.sessionStorage.getItem(key);

    return item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};

export const setSessionStorageValue = (key: SessionStorage, value: unknown) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};
