import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
// @ts-ignore
import { View, Director } from '@millicast/sdk';

import {
  IMillicastStream,
  IMillicastStreamViewer,
  MillicastPropsType,
} from './interfaces';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../components/Notification';
import {
  IMillicastEventTokens,
  IMillicastStreamTokens,
  IMillicastTokens,
} from '../../../../../../interfaces/millicastTokens';
import { IShareMediaParams } from '../../../../../../interfaces/shareMediaParams';
import { IMillicastPublisher } from '../../../../../../interfaces/millicastPublisher';
import MillicastService from '../../../../components/HomeBottomBar/ShareScreen/helpers';
import EventsService from '../../../../../../services/EventsService';
import {
  ILastWebsocketJsonMessage,
  WebsocketAction,
} from '../../../../../../interfaces/webSocketConnectionInfo';

const {
  StartShareScreenRequest,
  StartShareVideoRequest,
  StopShareScreenRequest,
  StopShareVideoRequest,
  ShowMillicastScreenBroadcastingDataRequest,
  ParticipantState,
} = WebsocketAction;

export const useMillicast = ({
  currentRoom,
  currentRegion,
  currentParticipant,
  muted,
  setMuted,
  setOpenCameraPublisherDialog,
}: MillicastPropsType) => {
  const { t: translate } = useTranslation('common');
  const { showNotification, getMillicastNotification } = useNotification();

  const [millicastTokens, setMillicastTokens] =
    React.useState<IMillicastEventTokens | null>(null);

  const [millicastStreamViewers, setMillicastStreamViewers] = React.useState<
    IMillicastStreamViewer[]
  >([]);

  const [millicastStreams, setMillicastStreams] = React.useState<
    IMillicastStream[]
  >([]);

  const [shareMillicastVideoWithSound, setShareMillicastVideoWithSound] =
    useState(false);

  const [isShareScreenModalOpen, setIsShareScreenModalOpen] = useState(false);
  const [isShareVideoModalOpen, setIsShareVideoModalOpen] = useState(false);
  const [
    openMillicastScreenBroadcastingDataModal,
    setOpenMillicastScreenBroadcastingDataModal,
  ] = useState(false);
  const prevMutedState = useRef<boolean | null>(null);
  const checkPrevMutedState = useRef(false);

  const [loadingShareScreenModal, setLoadingShareScreenModal] = useState(false);
  const [loadingShareVideoModal, setLoadingShareVideoModal] = useState(false);

  const [shareMediaParams, setShareMediaParams] =
    useState<IShareMediaParams | null>(null);

  const [shareVideoPublishers, setShareVideoPublishers] =
    useState<IMillicastPublisher | null>(null);
  const [shareScreenPublishers, setShareScreenPublishers] = useState<
    IMillicastPublisher[]
  >([]);
  const [shareVideoMediaStream, setShareVideoMediaStream] =
    useState<MediaStream | null>(null);
  const [shareScreenMediaStreams, setShareScreenMediaStreams] = useState<
    MediaStream[]
  >([]);

  const [micUsingByMillicast, setMicUsingByMillicast] = useState(false);

  const getMillicastStreamTokens = useCallback(
    async (streamName: string): Promise<IMillicastStreamTokens | null> => {
      if (millicastTokens) {
        const streamExistsInPublishToken =
          millicastTokens.publishToken.streams.some((sn) => sn === streamName);
        const streamExistsInSubscribeToken =
          millicastTokens.subscribeToken.streams.some(
            (sn) => sn === streamName,
          );

        if (!streamExistsInPublishToken) {
          await MillicastService.updatePublishToken(
            millicastTokens.publishToken.id,
            streamName,
          );
        }

        if (!streamExistsInSubscribeToken) {
          await MillicastService.updateSubscribeToken(
            millicastTokens.subscribeToken.id,
            streamName,
          );
        }

        if (!streamExistsInPublishToken || !streamExistsInSubscribeToken) {
          setMillicastTokens((prev) => {
            if (!prev) {
              return prev;
            }

            let publishToken = prev.publishToken;
            let subscribeToken = prev.subscribeToken;

            if (!streamExistsInPublishToken) {
              publishToken = {
                ...publishToken,
                streams: [...publishToken.streams, streamName],
              };
            }

            if (!streamExistsInSubscribeToken) {
              subscribeToken = {
                ...subscribeToken,
                streams: [...subscribeToken.streams, streamName],
              };
            }

            return {
              ...prev,
              publishToken,
              subscribeToken,
            };
          });
        }

        return {
          streamName,
          accountId: millicastTokens.accountId,
          publishingToken: millicastTokens.publishToken.token,
          subscribingToken: millicastTokens.subscribeToken.token,
        };
      }

      return null;
    },
    [millicastTokens],
  );

  const startShareVideoHandler = useCallback(
    async (
      shareVideoPublishers: IMillicastPublisher,
      shareVideoTokens: IMillicastTokens,
      muted = false,
    ) => {
      if (!currentRoom || !shareMediaParams) {
        return;
      }

      const data = {
        roomId: currentRoom.id,
        region: currentRegion?.region,
        spotId: shareMediaParams.screenName,
        accountId: shareVideoTokens.accountId,
        ueIdentifier: shareMediaParams.screenName,
        streamName: shareVideoPublishers.streamName,
        mediaStreamId: shareVideoPublishers.options.mediaStream.id,
        subscribingToken: shareVideoTokens.subscribingToken,
        participantId: currentParticipant ? currentParticipant.userId : null,
        isScreen: false,
        isCamera: true,
        nameOwner: currentParticipant.fullName,
        target: 'UE5',
        muted,
      };

      await EventsService.startStreamVideo(data);
      setShareVideoPublishers(shareVideoPublishers);

      // eslint-disable-next-line no-console
      console.log(`Start broadcast video: ${JSON.stringify(data)}`);
    },
    [
      setShareVideoPublishers,
      currentRoom,
      shareMediaParams,
      currentRegion,
      currentParticipant,
    ],
  );

  const stopShareVideoHandler = useCallback(async () => {
    if (!shareVideoPublishers) {
      return;
    }

    await MillicastService.stopStream(shareVideoPublishers);
    setShareVideoPublishers(null);

    // eslint-disable-next-line no-console
    console.log(
      `Stop broadcast video: streamName=${shareVideoPublishers.streamName}`,
    );
  }, [shareVideoPublishers, setShareVideoPublishers]);

  const startShareScreenHandler = useCallback(
    async (
      shareScreenPublishers: IMillicastPublisher,
      shareScreenTokens: IMillicastTokens,
      muted = false,
    ) => {
      if (!currentRoom || !shareMediaParams) {
        return;
      }

      const data = {
        roomId: currentRoom.id,
        region: currentRegion?.region,
        spotId: shareMediaParams.screenName,
        accountId: shareScreenTokens.accountId,
        ueIdentifier: shareMediaParams.screenName,
        streamName: shareScreenPublishers.streamName,
        mediaStreamId: shareScreenPublishers.options.mediaStream.id,
        subscribingToken: shareScreenTokens.subscribingToken,
        participantId: currentParticipant ? currentParticipant.userId : null,
        isScreen: true,
        isCamera: false,
        nameOwner: currentParticipant.fullName,
        target: 'ALL',
        muted,
      };

      await EventsService.startStreamVideo(data);
      setShareScreenPublishers((prev) => [...prev, shareScreenPublishers]);

      // eslint-disable-next-line no-console
      console.log(`Start broadcast screen: ${JSON.stringify(data)}`);
    },
    [
      setShareScreenPublishers,
      currentRoom,
      currentRegion,
      shareMediaParams,
      currentParticipant,
    ],
  );

  const stopShareScreenHandler = useCallback(
    async (streamId: string) => {
      const currentPublisher = shareScreenPublishers.find(
        (p) => p.options.mediaStream.id === streamId,
      );

      if (!currentPublisher) {
        return;
      }

      await MillicastService.stopStream(currentPublisher);

      setShareScreenPublishers((prev) =>
        prev.filter((p) => p.options.mediaStream.id !== streamId),
      );
      // eslint-disable-next-line no-console
      console.log(
        `Stop broadcast screen: streamName=${currentPublisher.streamName}`,
      );
    },
    [shareScreenPublishers, setShareScreenPublishers],
  );

  const lockMillicastScreen = React.useCallback(
    async (shareMediaParams: IShareMediaParams): Promise<boolean> => {
      const { roomId, region, screenName } = shareMediaParams;

      if (!currentParticipant || !roomId || !screenName) {
        return false;
      }

      const streamName = EventsService.getMillicastStreamName(
        roomId,
        region || '',
        screenName,
      );

      const millicastStream = await MillicastService.getMillicastStream(
        streamName,
      );

      if (
        millicastStream &&
        millicastStream.participantId !== 'obs' &&
        millicastStream.participantId !== currentParticipant.userId
      ) {
        showNotification(
          getMillicastNotification({
            title: translate('notifications.millicast.screenLocked'),
            type: NOTIFICATION_TYPES.WARNING,
            message: `${translate('notifications.millicast.screenLockedBy')} ${
              millicastStream.lockedByName
                ? millicastStream.lockedByName
                : translate(
                    'notifications.millicast.screenLockedByOtherParticipant',
                  )
            }`,
          }),
        );

        return false;
      }

      await MillicastService.lockMillicastScreen(streamName, {
        roomId,
        region,
        ueIdentifier: screenName,
        lockedByName: currentParticipant.fullName,
      });

      return true;
    },
    [currentParticipant],
  );

  const unlockMillicastScreen = React.useCallback(
    async (shareMediaParams: IShareMediaParams): Promise<void> => {
      const { roomId, region, screenName } = shareMediaParams;

      const streamName = EventsService.getMillicastStreamName(
        roomId,
        region || '',
        screenName,
      );

      await MillicastService.unlockMillicastScreen(streamName);
    },
    [],
  );

  const openShareVideoModal = React.useCallback(
    async (shareMediaParams: IShareMediaParams) => {
      const isScreenAvailable = await lockMillicastScreen(shareMediaParams);

      if (isScreenAvailable) {
        setShareMediaParams(shareMediaParams);
        setIsShareVideoModalOpen(true);
      }
    },
    [shareMediaParams, lockMillicastScreen],
  );

  const closeShareVideoModal = React.useCallback(
    async (unlockScreen = true) => {
      if (unlockScreen && shareMediaParams) {
        await unlockMillicastScreen(shareMediaParams);
      }

      setIsShareVideoModalOpen(false);
    },
    [shareMediaParams, unlockMillicastScreen],
  );

  const openShareScreenModal = React.useCallback(
    async (shareMediaParams: IShareMediaParams) => {
      const isScreenAvailable = await lockMillicastScreen(shareMediaParams);

      if (isScreenAvailable) {
        setShareMediaParams(shareMediaParams);
        setIsShareScreenModalOpen(true);
      }
    },
    [shareMediaParams, lockMillicastScreen],
  );

  const closeShareScreenModal = React.useCallback(
    async (unlockScreen = true) => {
      if (unlockScreen && shareMediaParams) {
        await unlockMillicastScreen(shareMediaParams);
      }

      setIsShareScreenModalOpen(false);
    },
    [shareMediaParams],
  );

  useAsyncEffect(async () => {
    if (shareVideoMediaStream) {
      shareVideoMediaStream.getVideoTracks().forEach((t) => {
        t.onended = async () => {
          setShareVideoMediaStream(null);
          setShareVideoPublishers(null);
          setIsShareVideoModalOpen(false);
        };
      });
    }

    if (shareVideoPublishers) {
      shareVideoPublishers.options.mediaStream.getVideoTracks().forEach((t) => {
        t.onended = async () => {
          if (shareVideoPublishers) {
            showNotification(
              getMillicastNotification({
                type: NOTIFICATION_TYPES.ERROR,
                message: translate(
                  'notifications.millicast.videoSharingStopped',
                ),
              }),
            );

            await stopShareVideoHandler();
          }

          setShareVideoMediaStream(null);
          setShareVideoPublishers(null);
          setIsShareVideoModalOpen(false);
        };
      });
    } else if (!isShareVideoModalOpen && shareVideoMediaStream) {
      await MillicastService.stopMediaStreamTracks(shareVideoMediaStream);
      setShareVideoMediaStream(null);
      setShareVideoPublishers(null);
      setIsShareVideoModalOpen(false);
    }
  }, [
    shareVideoPublishers,
    shareVideoMediaStream,
    stopShareVideoHandler,
    isShareVideoModalOpen,
  ]);

  useEffect(() => {
    if (shareScreenMediaStreams?.length) {
      shareScreenMediaStreams.forEach(
        (s) =>
          (s.getVideoTracks()[0].onended = async () => {
            if (shareScreenPublishers) {
              showNotification(
                getMillicastNotification({
                  type: NOTIFICATION_TYPES.ERROR,
                  message: translate(
                    'notifications.millicast.videoSharingStopped',
                  ),
                }),
              );

              await handleStopScreenStream(s.id);
              closeShareScreenModal();
            }

            setShareScreenMediaStreams((prev) =>
              prev.filter((streams) => streams.id !== s.id),
            );

            MillicastService.stopMediaStreamTracks(s);
          }),
      );
    }
  }, [shareScreenMediaStreams, shareScreenPublishers, closeShareScreenModal]);

  useEffect(() => {
    if (!isShareScreenModalOpen) {
      const publishedStreamIds = shareScreenPublishers.map(
        (stream) => stream.options.mediaStream.id,
      );

      setShareScreenMediaStreams((prev) => {
        return prev.filter(async (s) => {
          const isStreamPublished = publishedStreamIds.find(
            (id) => id === s.id,
          );

          if (!isStreamPublished) {
            MillicastService.stopMediaStreamTracks(s);

            return false;
          }

          return true;
        });
      });
    }
  }, [isShareScreenModalOpen, shareScreenPublishers]);

  useAsyncEffect(async () => {
    if (shareVideoPublishers && shareMillicastVideoWithSound) {
      if (currentRegion?.region === shareVideoPublishers.region) {
        if (!muted) {
          prevMutedState.current = false;
          checkPrevMutedState.current = true;
          setMuted(true);
        }
        setMicUsingByMillicast(true);
      }

      return;
    }

    if (muted !== prevMutedState.current && checkPrevMutedState.current) {
      setMuted(prevMutedState.current !== null ? prevMutedState.current : true);
      checkPrevMutedState.current = false;
    }

    setMicUsingByMillicast(false);
  }, [shareMillicastVideoWithSound, shareVideoPublishers, currentRegion]);

  const initScreenMediaStream = useCallback(async () => {
    const mediaStream = await navigator.mediaDevices
      .getDisplayMedia({
        video: { frameRate: { max: 30, ideal: 30 } },
        audio: true,
      })
      .catch((e) => {
        console.log(e);
        closeShareScreenModal();
        return;
      });

    if (!mediaStream) {
      return;
    }

    if (!shareScreenPublishers) {
      return;
    }

    const publishedIds = shareScreenPublishers.map(
      (stream) => stream.options.mediaStream.id,
    );

    setShareScreenMediaStreams((prev) => {
      const publishedStreams = prev.filter((s) => {
        const isStreamPublished = publishedIds.find((id) => id === s.id);

        if (!isStreamPublished) {
          handleStopScreenStream(s.id);
          return false;
        }

        return true;
      });

      return [...publishedStreams, mediaStream];
    });
  }, [
    shareScreenMediaStreams,
    setShareScreenMediaStreams,
    shareScreenPublishers,
    closeShareScreenModal,
  ]);

  const handleStopScreenStream = useCallback(
    async (streamId: string) => {
      const currentPublisher = shareScreenPublishers?.find(
        (p) => p.options.mediaStream.id === streamId,
      );

      if (currentPublisher) {
        await MillicastService.stopStream(currentPublisher);
      }

      if (shareScreenMediaStreams?.length) {
        const currentMediaStream = shareScreenMediaStreams.find(
          (s) => s.id === streamId,
        );

        if (!currentMediaStream) {
          return;
        }

        MillicastService.stopMediaStreamTracks(currentMediaStream);
      }

      setShareScreenMediaStreams((prev) =>
        prev.filter((s) => s.id !== streamId),
      );

      await stopShareScreenHandler(streamId);
    },
    [
      shareScreenPublishers,
      shareScreenMediaStreams,
      stopShareScreenHandler,
      initScreenMediaStream,
    ],
  );

  const handleMillicastWSMessage = React.useCallback(
    async (lastJsonMessage: ILastWebsocketJsonMessage) => {
      const {
        action,
        roomId = '',
        screenName,
        millicastStreams,
      } = lastJsonMessage;

      if (!action) {
        return;
      }

      let { region = '' } = lastJsonMessage;

      if (region) {
        region = camelCase(region);
      }

      switch (action) {
        case ShowMillicastScreenBroadcastingDataRequest: {
          if (roomId && screenName) {
            setShareMediaParams({
              roomId,
              region,
              screenName,
            });

            setOpenMillicastScreenBroadcastingDataModal(true);
          }
          break;
        }
        case StartShareVideoRequest: {
          if (roomId && screenName) {
            if (shareVideoPublishers) {
              setShareMediaParams({
                roomId,
                region,
                screenName,
              });
              setOpenCameraPublisherDialog(true);
            } else {
              await openShareVideoModal({ roomId, region, screenName });
            }
          }

          break;
        }

        case StopShareVideoRequest: {
          await stopShareVideoHandler();
          break;
        }

        case StartShareScreenRequest: {
          if (roomId && screenName) {
            await openShareScreenModal({ roomId, region, screenName });
          }

          break;
        }

        case StopShareScreenRequest: {
          if (screenName && shareScreenPublishers) {
            const stream = shareScreenPublishers?.find(
              (s) =>
                s.streamName.split('-')[2].toLowerCase() ===
                camelCase(screenName).toLowerCase(),
            );

            if (stream) {
              await handleStopScreenStream(stream.options.mediaStream.id);
            }
          }
          break;
        }
        case ParticipantState:
          if (millicastStreams) {
            setMillicastStreams((prev) => {
              if (
                prev.length === millicastStreams.length &&
                prev.every((millicastStream) => {
                  return !!millicastStreams.find(
                    (ms: IMillicastStream) =>
                      ms.millicastStreamName ===
                      millicastStream.millicastStreamName,
                  );
                })
              ) {
                return prev;
              }

              return millicastStreams;
            });
          } else {
            setMillicastStreams([]);
          }
          break;
        default:
          break;
      }
    },
    [
      shareVideoPublishers,
      shareScreenPublishers,
      openShareVideoModal,
      stopShareVideoHandler,
      openShareScreenModal,
    ],
  );

  useAsyncEffect(async () => {
    setMillicastStreamViewers((prev) => {
      let newMillicastStreamViewers = prev;

      newMillicastStreamViewers = newMillicastStreamViewers.filter(
        (millicastStreamViewer) => {
          const millicastStream = millicastStreams.find(
            (ms) =>
              ms.millicastStreamName ===
              millicastStreamViewer.millicastStream.millicastStreamName,
          );

          if (!millicastStream) {
            console.log(
              `Stop millicast audio for ${millicastStreamViewer.millicastStream.millicastStreamName}`,
            );
            millicastStreamViewer.millicastView.stop();
            millicastStreamViewer.audio.pause();
            millicastStreamViewer.audio.srcObject = null;
            return false;
          }

          return true;
        },
      );

      millicastStreams.map((millicastStream) => {
        const millicastStreamViewer = millicastStreamViewers.find(
          (msv) =>
            msv.millicastStream.millicastStreamName ===
            millicastStream.millicastStreamName,
        );

        if (!millicastStreamViewer && !millicastStream.muted) {
          const tokenGenerator = () =>
            Director.getSubscriber({
              streamName: millicastStream.millicastStreamName,
              streamAccountId: millicastStream.millicastAccountId,
              subscriberToken: millicastStream.millicastSubscribingToken,
            });

          const millicastView = new View(
            millicastStream.millicastStreamName,
            tokenGenerator,
          );

          const audio = new Audio();
          audio.autoplay = true;

          console.log(
            `Start millicast audio for ${millicastStream.millicastStreamName}`,
          );

          millicastView.on('track', (event: RTCTrackEvent) => {
            audio.srcObject = event.streams[0];
          });

          millicastView.connect({
            disableVideo: true,
          });

          newMillicastStreamViewers = [
            ...millicastStreamViewers,
            {
              millicastStream,
              millicastView,
              audio,
            },
          ];
        }
      });

      return newMillicastStreamViewers;
    });
  }, [millicastStreams]);

  return {
    millicastTokens,
    setMillicastTokens,
    getMillicastStreamTokens,
    shareMillicastVideoWithSound,
    shareMediaParams,
    shareVideoPublishers,
    shareScreenPublishers,
    shareVideoMediaStream,
    shareScreenMediaStreams,
    openScreenModal: isShareScreenModalOpen,
    micUsingByMillicast,
    setMicUsingByMillicast,
    isShareVideoModalOpen,
    openShareVideoModal,
    closeShareVideoModal,
    isShareScreenModalOpen,
    openShareScreenModal,
    closeShareScreenModal,
    setOpenScreenModal: setIsShareScreenModalOpen,
    initScreenMediaStream,
    handleStopScreenStream,
    stopShareScreenHandler,
    startShareScreenHandler,
    stopShareVideoHandler,
    startShareVideoHandler,
    setShareScreenMediaStreams,
    setShareVideoMediaStream,
    setShareVideoPublishers,
    setShareScreenPublishers,
    setShareMediaParams,
    setShareMillicastVideoWithSound,

    loadingShareVideoModal,
    setLoadingShareVideoModal,

    loadingShareScreenModal,
    setLoadingShareScreenModal,

    openMillicastScreenBroadcastingDataModal,
    setOpenMillicastScreenBroadcastingDataModal,

    handleMillicastWSMessage,
  };
};
