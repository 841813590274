import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { colors } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 84px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        px: 2,
      },
    },
    title: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '36px',
      color: colors.patrick,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
    description: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: colors.oxford_60,
      textAlign: 'center',
      marginTop: '16px',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    footer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      width: '100%',
      padding: '0px 19px',
    },
    box: {
      display: 'grid',
    },
    step: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: colors.oxford_60,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    nextBtn: {
      display: 'flex',
      alignItems: 'center',
      justifySelf: 'center',
      columnGap: 2,
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '36px',
      color: colors.white,
      textTransform: 'capitalize',
      backgroundColor: appTheme.mainColor,
      border: `1px solid ${colors.white_40}`,
      borderRadius: '100px',
      transition: 'none',
      boxShadow: 'none',
      padding: '10px 16px',
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: appTheme.mainColor,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 18,
        lineHeight: '24px',
        gap: 1,
      },
    },
    icon: {
      width: 32,
      height: 32,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        width: 24,
        height: 24,
      },
    },
    skipBtn: {
      justifySelf: 'flex-end',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: colors.oxford_60,
      textTransform: 'capitalize',
      transition: 'none',
      boxShadow: 'none',
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 14,
        lineHeight: '24px',
        padding: 0,
        justifyContent: 'end',
      },
    },
  };
};
