import { IWsConnection } from '../interfaces/profile';
import { WsConnectionClientType } from '../interfaces';
//import { delay } from './index';

//let communicationFromUE = false;

export const getHasMultipleWsWebAppConnection = (
  wsConnections: IWsConnection[],
): boolean => {
  const webappConnection = wsConnections.filter(
    (connection) => connection.clientType === WsConnectionClientType.Webapp,
  );

  return webappConnection.length > 1;
};

// export const startCommunicationFromUeTimer = async () => {
//   if (!communicationFromUE) {
//     await delay(Number(process.env.REACT_APP_WS_UE_CHECK || 30000)); // default to 30s
//     if (!communicationFromUE) {
//       // If the flag is still false we didn't get the UE msg
//       throw new Error('Failed to receive message from UE in time');
//     }
//   }
// };
//
// export const stopCommunicationFromUeTimer = () => {
//   communicationFromUE = true;
// };
