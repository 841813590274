import React from 'react';

import NotificationView from './NotificationView';

import { useNotification } from './hooks';

import { getMessageData, setDismissNotificationMessage } from './helpers';

import { INotification } from './interfaces';

const Notification = ({
  ref,
  id,
  title = '',
  message,
  type,
  withReloadButton = false,
  withDismissButton = true,
}: INotification) => {
  const { hideNotification } = useNotification();
  const { icon, color, background } = getMessageData(type);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleDismiss = () => {
    setDismissNotificationMessage(message);
    handleClickClose();
  };

  const handleClickClose = () => hideNotification(id);

  return (
    <NotificationView
      ref={ref}
      title={title}
      message={message}
      color={color}
      background={background}
      icon={icon}
      reloadPage={reloadPage}
      onDismiss={handleDismiss}
      withReloadButton={withReloadButton}
      withDismissButton={withDismissButton}
      onClickClose={handleClickClose}
    />
  );
};

export default Notification;
