import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from './stylex';

import { IPlayScreenLoading } from './interfaces';
import { useMytaverseEvent } from '../../providers';

const PlayScreenLoading = ({
  text = null,
  t: translate,
}: IPlayScreenLoading) => {
  const classes = useStyles();
  const { isTeleporting, isTeleportingToRoomByUnreal, isReconnectDisabled } =
    useMytaverseEvent();

  const isTeleportRun = isTeleporting || isTeleportingToRoomByUnreal;

  if (!isTeleportRun && isReconnectDisabled) {
    return null;
  }

  const renderMessage = () => {
    if (text) {
      return translate(text);
    }

    return translate(
      isTeleportRun ? 'loading.teleporting' : 'playScreen.reconnect',
    );
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <CircularProgress sx={classes.check} />
        <Typography>{renderMessage()}</Typography>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(PlayScreenLoading);
