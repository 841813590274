import { useEffect, useMemo, useState } from 'react';
import SpeedTest from '@cloudflare/speedtest';
import { useTranslation } from 'react-i18next';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../components/Notification';
import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';
import { getNotification } from '../../../../components/Notification/helpers';
import { useMytaverseEvent } from '../../../dashboard/providers';

import { delay, getIsLowInternetSpeed } from '../../../../helpers';
import { getAverageValue, convertBitToMB } from './helpers';
import { configOptions } from './constants';

import StreamService from '../../../../services/StreamService';

export const useFastCheckInternetConnectionSpeed = () => {
  const [speed, setSpeed] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const { t: translate } = useTranslation('common');
  const { showNotification } = useNotification();

  useEffect(() => {
    const speedResult = new SpeedTest(configOptions);

    speedResult.onFinish = (results) => {
      setSpeed(convertBitToMB(results.getDownloadBandwidth() ?? 0));
      setIsCompleted(true);
    };

    speedResult.onResultsChange = () => {
      setSpeed(convertBitToMB(speedResult.results.getDownloadBandwidth() ?? 0));
    };
  }, []);

  useEffect(() => {
    if (!isCompleted) return;

    const isLowInternetSpeed = getIsLowInternetSpeed(speed);
    if (!isLowInternetSpeed) return;

    const message = `Internet connection speed is ${speed} Mbps`;

    const notificationData = getNotification({
      message: translate('notifications.unstableInternet'),
      type: NOTIFICATION_TYPES.WARNING,
    });

    showNotification({ ...notificationData, logMessage: message });
    sendCustomSentryBreadcrumb({ message });
  }, [isCompleted, speed, translate, showNotification]);

  return {
    speed,
    isCompleted,
  };
};

export const useCheckServiceLatency = (isFirstTestCompleted: boolean) => {
  const [serviceLatencies, setServiceLatencies] = useState<number[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const { gameCastStreamRegion, currentEvent } = useMytaverseEvent();

  const averageServiceLatency = useMemo(
    () => getAverageValue(serviceLatencies),
    [serviceLatencies],
  );

  useEffect(() => {
    const region = gameCastStreamRegion || currentEvent?.region;

    if (!isFirstTestCompleted || !region) return;

    const setServiceLatencyValue = async () => {
      await delay(1000);
      const serviceLatency = await StreamService.checkServiceLatency(region);

      setServiceLatencies((prev) => prev.concat(serviceLatency));
    };

    const startCheckServiceLatency = async () => {
      const checkServiceLatencyList = [...new Array(5)];

      for (const [i] of checkServiceLatencyList.entries()) {
        await setServiceLatencyValue();

        if (i === checkServiceLatencyList.length - 1) {
          setIsCompleted(true);
        }
      }
    };

    startCheckServiceLatency();
  }, [currentEvent?.region, gameCastStreamRegion, isFirstTestCompleted]);

  return {
    serviceLatency: averageServiceLatency,
    isCompleted,
  };
};
