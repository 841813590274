import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import EventsService from '../../../../services/EventsService';
import { useMytaverseEvent } from '../../../dashboard/providers/MytaverseEventProvider';
import { useMytaverse } from '../../../../providers/MytaverseProvider';
import AvaturnService from '../../../../services/AvaturnService';
import useAsyncEffect from 'use-async-effect';
import { AvaturnSDK } from '@avaturn/sdk';

export const useAvaturn = () => {
  const navigate = useNavigate();
  const { setCustomAvatarUrl, setCurrentSkin } = useMytaverseEvent();
  const { currentEventId, userId } = useMytaverse();

  const handleBackBtnClick = () => {
    navigate(ROUTES.AVATAR_CHOOSING);
  };

  useAsyncEffect(async () => {
    if (!userId || !currentEventId) {
      return;
    }
    const participant = await EventsService.getEventUserData(
      currentEventId,
      userId,
    );

    let avaturnId = participant?.avaturnId;

    if (!avaturnId) {
      const avaturnUser = await AvaturnService.createUser();

      await EventsService.setEventUserData(currentEventId, userId, {
        avaturnId: avaturnUser.id,
      });

      avaturnId = avaturnUser.id;
    }

    if (!avaturnId) {
      return;
    }

    const session = await AvaturnService.createSession(avaturnId);
    const container = document.getElementById('avaturn-sdk-container');

    const sdk = new AvaturnSDK();

    await sdk
      .init(container, {
        url: session.url,
        disableUi: false,
      })
      .then(() => {
        sdk.on('export', async (data) => {
          setCustomAvatarUrl(data.url);
          setCurrentSkin(null);
          await EventsService.setEventUserData(currentEventId, userId, {
            avatarId: '',
            customAvatarUrl: data.url,
            skinId: '',
          });
          navigate(ROUTES.DEVICES_TEST);
        });
      });
  }, [currentEventId, userId]);

  return {
    handleBackBtnClick,
  };
};
