import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material';
import { useMytaverseEvent } from '../../../providers';
import { useCoreWeave } from '../../DashboardContent/CoreWeave/CoreWeaveProvider';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';
import { useConference } from '../../DashboardContent/Dolby';
import { ChangeEvent, useEffect, useRef } from 'react';
import { useStyles } from './styles';

import { colors } from '../../../../../constants/colors';

const ConfigTab = () => {
  const {
    initMessageHandler,
    streamingProvider,
    currentRoom,
    currentRegion,
    isReconnectDisabled,
    setIsReconnectDisabled,
    enableScreenResolutionMessages,
    setEnableScreenResolutionMessages,
  } = useMytaverseEvent();
  const { conference } = useConference();
  const { connectedPod } = useCoreWeave();
  const classes = useStyles();

  const currentInitMessage = useRef('');

  useEffect(() => {
    if (!initMessageHandler || currentInitMessage.current) {
      return;
    }
    const message = initMessageHandler();

    if (!message) {
      return;
    }

    currentInitMessage.current = JSON.stringify(message);
  }, [initMessageHandler]);

  const handleEnableReconnects = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsReconnectDisabled(checked);
  };

  return (
    <Box sx={classes.root} className="no-drag">
      <Button
        sx={{
          color: colors.white,
          backgroundColor: colors.mytaDark,
          textTransform: 'capitalize',
          mr: 2,
        }}
        onClick={() =>
          setEnableScreenResolutionMessages(!enableScreenResolutionMessages)
        }
      >
        {!enableScreenResolutionMessages ? 'Start' : 'Stop'} Sending Screen
        Resolution
      </Button>
      <FormControlLabel
        label="Disable reconnects"
        sx={{
          '& span': {
            color: 'white',
          },
        }}
        control={
          <Checkbox
            checked={isReconnectDisabled}
            onChange={handleEnableReconnects}
            sx={{ color: 'white' }}
          />
        }
      />{' '}
      <Typography>Streaming Provider: {streamingProvider}</Typography>
      <Typography>Current Room: {currentRoom?.id || '---'}</Typography>
      <Typography>Current Region: {currentRegion?.region || '---'} </Typography>
      <Typography>
        Dolby Conference Alias: {conference?.alias || '---'}
      </Typography>
      {streamingProvider === StreamingProviders.CoreWeave && (
        <Typography>Connected Pod: {connectedPod || '---'}</Typography>
      )}
      <Typography>
        Init Message: {currentInitMessage.current || '---'}
      </Typography>
    </Box>
  );
};

export default ConfigTab;
