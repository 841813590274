import { SvgIconProps } from '@mui/material';
import { customAppThemes } from '../constants/eventStyles';

export type SVGType = (props: SvgIconProps) => JSX.Element;

export type UnknownObjectType<K extends string | number | symbol, T> = {
  [key in K]: T;
};

export enum IEventThemeTypes {
  LIGHT = 'light',
  DARK = 'dark',
  DEFAULT = 'default',
}

type IDefaultEvenStyle = ReturnType<
  (typeof customAppThemes)[IEventThemeTypes.DEFAULT]
>;

export type IEventStyle = Omit<IDefaultEvenStyle, 'customerLogo'> & {
  customerLogo: JSX.Element | string;
};

export enum SentryEnvironment {
  Development = 'development',
  Local = 'local',
  Production = 'production',
}

export enum SentryBreadcrumbCategory {
  Xhr = 'xhr',
  Fetch = 'fetch',
  UserClick = 'user.click', // custom category
  Console = 'console',
  Transaction = 'sentry.transaction',
}

type XHRSendInput =
  | null
  | Blob
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | string;

export interface SentryXMLHttpRequest extends XMLHttpRequest {
  __sentry_xhr__?: {
    method?: string;
    url?: string;
    status_code?: number;
    body?: XHRSendInput;
  };
}

export interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export interface ISubscription {
  unsubscribe: () => void;
}

export interface ICustomerEvent {
  colorTheme: 'light' | 'dark';
  eventName: string;
  backgroundImage: string;
  loginButtonBackgroundColor: string;
  loginButtonBackgroundColorHover: string;
  helperTextColor: string;
  helperTextLinkColor: string;
  submitButtonBackgroundColor: string;
  submitButtonBackgroundColorHover: string;
  signFormSelectedTab: string;
  customerLogo: string;
}

export enum SocialProviders {
  Google = 'google',
  Microsoft = 'microsoft',
}

export interface IUseModalResponse {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

// Design for desktop displays from 1024×768 through 1920×1080
// Design for tablet displays from 601×962 through 800x1280
// Design for mobile displays from 360×640 through 414×896
export enum ScreenSizes {
  PcLarge = 1200,
  Pc = 1025,
  Tablet = 602,
  TabletHorizontal = 962,
  Mobile = 361,
}

// Design for desktop displays from 1024×768 through 1920×1080
// Design for tablet displays from 601×962 through 1280×800
// Design for mobile displays from 360×640 through 414×896
export enum SwiperScreenSizes {
  Pc = 1300,
  Laptop = 900,
  Tablet = 601,
  Mobile = 360,
}

export enum WsConnectionClientType {
  Webapp = 'WEBAPP',
  UE = 'UE',
}
