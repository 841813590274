const ROUTES = {
  LOGIN: '/login',
  PRIVACY_POLICY: '/privacy',
  CONFIRM_EMAIL: 'confirm',
  FORGOT_PASSWORD: 'reset_password',
  FORGOT_PASSWORD_VERIFICATION: 'verification',
  AVATAR_CHOOSING: '/avatar',
  SELECT_AVATAR_SKIN: '/avatar_skin',
  GAME_TUTORIAL: '/tutorial',
  HOME_PAGE: '/',
  SELECT_EVENT: '/event',
  REGISTER: 'register',
  CONFIRMED_EMAIL: 'confirmed',
  READY_PLAYER: '/readyplayer',
  AVATURN: '/avaturn',
  CONFIRM_EVENT: 'event_subscribe',
  RECOVER_PASSWORD: 'recovery',
  DEVICES_TEST: '/devices_test',
  TRY_DEMO: 'try_demo',
};

export default ROUTES;
