import { useCallback, useEffect } from 'react';

import { useConference } from '../Dolby';
import { useConferenceState } from '../../../../../hooks/conferenceContext';

export const useDeviceChange = () => {
  const {
    speakers,
    microphones,
    cameras,
    selectSpeaker,
    selectMicrophone,
    selectCamera,
  } = useConference();

  const { activeCameraDeviceId, activeSpeakerDeviceId, activeMicroDeviceId } =
    useConferenceState();

  const handleDeviceChange = useCallback(
    (
      devices: any[],
      activeDeviceId: string,
      selectDeviceHandle: (deviceId: string) => void,
    ) => {
      if (devices.length === 0 || !activeDeviceId) return;

      const activeDevice = devices.find(
        ({ deviceId }) => deviceId === activeDeviceId,
      );

      if (!activeDevice) {
        selectDeviceHandle(devices[0].deviceId);
      }
    },
    [],
  );

  useEffect(() => {
    handleDeviceChange(speakers, activeSpeakerDeviceId, selectSpeaker);
  }, [handleDeviceChange, speakers, activeSpeakerDeviceId, selectSpeaker]);

  useEffect(() => {
    handleDeviceChange(microphones, activeMicroDeviceId, selectMicrophone);
  }, [handleDeviceChange, microphones, activeMicroDeviceId, selectMicrophone]);

  useEffect(() => {
    handleDeviceChange(cameras, activeCameraDeviceId, selectCamera);
  }, [handleDeviceChange, cameras, activeCameraDeviceId, selectCamera]);
};
