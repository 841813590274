import React from 'react';

import AppLoader from '../../../AppLoader';
import LoadingProgressTutorial from '../../../LoadingProgressTutorial';

import { usePurewebProgressBar } from './hooks';

const PurewebLoader = () => {
  const {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
  } = usePurewebProgressBar();

  return (
    <AppLoader>
      <LoadingProgressTutorial
        secondStepLoading={secondStepLoading}
        secondStepCompleted={secondStepCompleted}
        thirdStepLoading={thirdStepLoading}
        thirdStepCompleted={thirdStepCompleted}
        forthStepLoading={forthStepLoading}
        forthStepCompleted={forthStepCompleted}
      />
    </AppLoader>
  );
};

export default PurewebLoader;
