import { useTheme } from '@mui/material';

import { COLORS, colors } from '../../../../constants/colors';
import { CHAT_DRAWER_WIDTH } from '../../../../constants';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../constants/devicesSizes';

import { IChatDrawerViewStyles } from './interfaces';
import { useMytaverseEvent } from '../../providers';

export const useStyles = ({
  isLoading,
  hasScreenMediaStreams,
  isExpanded,
}: IChatDrawerViewStyles) => {
  const { breakpoints } = useTheme();
  const { isMinimizedScreenSharing } = useMytaverseEvent();

  return {
    root: {
      '& .MuiPaper-root': {
        top: hasScreenMediaStreams && !isMinimizedScreenSharing ? '225px' : 0,
        display: 'grid',
        gridTemplateRows: isLoading ? 'auto 1fr' : 'auto auto 1fr',
        backgroundColor: COLORS.BACKGROUND_COLOR,
        backdropFilter: 'blur(12px)',
        width: CHAT_DRAWER_WIDTH,
        height: hasScreenMediaStreams && !isMinimizedScreenSharing ? 'calc(100vh - 225px)' : '100%',
        [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
          width: isExpanded ? '90%' : '50%',
          zIndex: 1500,
          overflow: 'unset',
        },
      },
      position: 'static',
      '& a': {
        color: COLORS.WHITE,
      },
    },
    tabs: {
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        backgroundColor: colors.gray,
      },
    },
    collapse: {
      color: COLORS.WHITE,
      backgroundColor: COLORS.BACKGROUND_COLOR,
      backdropFilter: 'blur(6px)',
      borderRadius: '10px',
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      py: 2.5,
      px: 0,
      paddingLeft: 0.5,
      width: 'auto',
      height: 'auto',
      visibility: 'hidden',
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        visibility: 'visible',
      },
    },
    collapseWrap: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate(-50%, -50%)',
      zIndex: 12,
      '& svg': {
        color: 'inherit',
        height: '14px',
        width: '14px',
        opacity: 1,
      },
    },
  };
};
