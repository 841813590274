/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import UsbIcon from '@mui/icons-material/Usb';
import CircularProgress from '@mui/material/CircularProgress';

import { useMytaverseEvent } from '../../../../providers';
import { useConference } from '../../../DashboardContent/Dolby';
import { useConferenceState } from '../../../../../../hooks/conferenceContext';

import LoadingProgress from '../../../../../../components/LoadingProgress';
import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings';

import { IMillicastVideoView } from './interfaces';
import { useStyles } from './styles';

const MillicastVideoView = ({
  loading,
  shareVideoPublishers,
  onShareVideoStreamClick,
  mediaStream,
  t: translate,
}: IMillicastVideoView) => {
  const classes = useStyles(shareVideoPublishers);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { shareMillicastVideoWithSound, setShareMillicastVideoWithSound } =
    useMytaverseEvent();

  const { cameras, selectCamera } = useConference();
  const { activeCameraDeviceId } = useConferenceState();

  useEffect(() => {
    if (!mediaStream) {
      return;
    }
    //@ts-ignore
    navigator.attachMediaStream(videoRef.current, mediaStream);
  }, [mediaStream]);

  return (
    <Box component="div" sx={classes.root}>
      <Box sx={classes.controls}>
        <HomeBottomButtonOptionsSettings
          devices={cameras}
          handleSelect={(e) => selectCamera(e.target.value)}
          label={null}
          selectedValue={activeCameraDeviceId}
          SelectIcon={UsbIcon}
        />
        <FormGroup sx={classes.checkbox}>
          <FormControlLabel
            label={
              <Typography sx={classes.checkboxTitle}>
                {translate('millicastWebcamShare.withSound')}
              </Typography>
            }
            control={
              <Checkbox
                checked={shareMillicastVideoWithSound}
                disabled={!!shareVideoPublishers}
                onChange={() =>
                  setShareMillicastVideoWithSound(!shareMillicastVideoWithSound)
                }
              />
            }
          />
        </FormGroup>
      </Box>

      <Box component="div" sx={classes.videoContainer}>
        {mediaStream ? (
          <video ref={videoRef} autoPlay playsInline muted={true} />
        ) : (
          <LoadingProgress />
        )}
      </Box>

      <Button
        variant="contained"
        onClick={onShareVideoStreamClick}
        disabled={loading || !mediaStream}
        sx={classes.button}
      >
        {translate(
          shareVideoPublishers
            ? 'millicastWebcamShare.stop'
            : 'millicastWebcamShare.share',
        )}
        {loading ? <CircularProgress size={20} sx={{ ml: 2 }} /> : ''}
      </Button>
    </Box>
  );
};

export default withTranslation('common')(MillicastVideoView);
