import React from 'react';
import SelectAvatarSkinItem from '../SelectAvatarSkinItem';

import { IAvaturn } from './interfaces';
import AvaturnLogo from '../../../../public/images/avaturn.gif';

const Avaturn = ({ onClick }: IAvaturn) => (
  <SelectAvatarSkinItem src={AvaturnLogo} alt="avaturn" onClick={onClick} />
);

export default Avaturn;
