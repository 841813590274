import React from 'react';

import { Box, Typography, Tab, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList } from '@mui/lab';

import AppModal from '../../../../../components/AppModal';

import DevicesSettingsVideo from './DevicesSettingsVideo';
import DevicesSettingsGeneral from './DevicesSettingsGeneral';
import DevicesSettingsSound from './DevicesSettingsSound';
import { DevicesSettingsModalViewProps } from './interfaces';

import { useStyles } from './styles';

const DevicesSettingsModalView = ({
  open,
  handleClose,
  checkVideo,
  setCheckVideo,
  settingsTab,
  setSettingsTab,
  translate,
}: DevicesSettingsModalViewProps) => {
  const classes = useStyles();

  const handleClick = () => {
    setCheckVideo(false);
    handleClose();
  };

  return (
    <AppModal onClick={handleClick} isOpen={open} sx={classes.modal}>
      <Box sx={classes.title} component="div">
        <Box component="div" sx={classes.titleWrapper}>
          <SettingsIcon />
          <Typography sx={classes.titleName}>
            {translate('settingsModal.settings')}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} sx={classes.titleButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TabContext value={settingsTab}>
        <Box sx={classes.tablistRoot} component="div">
          <TabList
            onChange={(event: React.SyntheticEvent, tab: any) =>
              setSettingsTab(tab)
            }
            sx={classes.tablist}
          >
            <Tab
              value="sound"
              label={
                <Box sx={classes.tab} component="div">
                  <Typography>{translate('settingsModal.sound')}</Typography>
                </Box>
              }
              sx={classes.tabSelect}
            />
            <Tab
              value="camera"
              label={
                <Box sx={classes.tab} component="div">
                  <Typography>{translate('settingsModal.camera')}</Typography>
                </Box>
              }
              sx={classes.tabSelect}
            />
            <Tab
              value="general"
              label={
                <Box sx={classes.tab} component="div">
                  <Typography>{translate('settingsModal.general')}</Typography>
                </Box>
              }
              sx={classes.tabSelect}
            />
          </TabList>
          <Box sx={classes.tabContentWrap} component="div">
            <Box sx={classes.tabContent} component="div">
              <DevicesSettingsSound />
              <DevicesSettingsVideo
                checkVideo={checkVideo}
                setCheckVideo={() => setCheckVideo(!checkVideo)}
              />
              <DevicesSettingsGeneral />
            </Box>
          </Box>
        </Box>
      </TabContext>
    </AppModal>
  );
};

export default DevicesSettingsModalView;
