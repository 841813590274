import React, { useRef } from 'react';
import { Box, Hidden } from '@mui/material';

import { embedYouTubeUrl } from './constants';
import { IMediaLoader } from './interfaces';
import { DocumentElementWithFullscreen } from '../../../hooks/interfaces';

import YoutubeEmbed from './YoutubeEmbed';
import Controls from './Controls';

import { useStyles } from './style';

const Media = ({ loadingAsset }: IMediaLoader) => {
  const ref = useRef<DocumentElementWithFullscreen | null>(null);
  const classes = useStyles();

  if (!loadingAsset) {
    return <YoutubeEmbed embedId="0DTN8KRIg5E" />;
  }

  if (loadingAsset.indexOf(embedYouTubeUrl) !== -1) {
    return <YoutubeEmbed embedId={loadingAsset.replace(embedYouTubeUrl, '')} />;
  }

  return (
    <Box ref={ref}>
      <Box
        component="img"
        src={loadingAsset}
        alt="Tip image"
        sx={classes.img}
      />
      {ref.current && (
        <Hidden mdDown>
          <Controls targetElement={ref.current} />
        </Hidden>
      )}
    </Box>
  );
};

export default Media;
