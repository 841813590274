/* eslint-disable no-case-declarations */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getUserBrowser, showInitMessage } from './helpers';
import { useMytaverseEvent } from '../../../providers';
import useAsyncEffect from 'use-async-effect';
import {
  CreateGameSessionResponse,
  GameCast,
  GameCastArgs,
  GamecastServerDisconnectReason,
} from './interfaces';
import GameCastService from '../../../../../services/GameCastService';
import { GAME_CAST_REQUEST_TIMEOUT } from './constants';
import { IInitMessage } from '../Pureweb/interfaces';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { RegisterTouchToMouse } from './touchtomouse';

export const useGameCastConnection = (
  videoElement: HTMLVideoElement | null,
) => {
  const [isStreamLoading, setIsStreamLoading] = React.useState(false);
  const [isStreamLoaded, setIsStreamLoaded] = React.useState(false);

  const [initMessage, setInitMessage] = React.useState<IInitMessage | null>(
    null,
  );

  const {
    currentParticipant,
    initMessageHandler,
    gameCastStreamRequestSended,
    setGameCastStreamRequestSended,
    setInitMessageSended,
    gameCastStreamId,
    gameCastStreamRegion,
  } = useMytaverseEvent();
  const browserType = getUserBrowser();

  const { initializeStream } = useGameInitialization({
    browserType,
    description: 'Test connection with gamecast',
    gameName: gameCastStreamId,
    streamId: gameCastStreamId,
    initMessage,
    participantId: currentParticipant?.id,
    region: gameCastStreamRegion,
    videoElement,
  });

  useEffect(() => {
    if (!initMessageHandler) {
      return;
    }

    if (!initMessageHandler) {
      return;
    }

    connectionTimer.current = new Date().getTime();
    const initMessage = initMessageHandler();

    setInitMessage(initMessage);
  }, [initMessageHandler]);

  const connectionTimer = useRef<number | null>(0);

  useAsyncEffect(async () => {
    if (
      !videoElement ||
      !currentParticipant ||
      gameCastStreamRequestSended ||
      !gameCastStreamId ||
      !gameCastStreamRegion
    ) {
      return;
    }

    try {
      setIsStreamLoading(true);
      if (!initMessage) {
        return;
      }

      setGameCastStreamRequestSended(true);
      setInitMessageSended(true);

      const isStarted = await initializeStream();

      if (isStarted) {
        setIsStreamLoading(false);
        setIsStreamLoaded(true);
      }
    } catch (error) {
      setIsStreamLoading(false);
    }
  }, [
    videoElement,
    currentParticipant,
    initMessage,
    gameCastStreamRequestSended,
    gameCastStreamRegion,
  ]);

  return {
    isStreamLoading,
    isStreamLoaded,
    setIsStreamLoaded,
    setIsStreamLoading,
    connectionTimer,
  };
};

const useGameInitialization = ({
  gameName,
  description,
  browserType,
  streamId,
  videoElement,
  participantId,
  initMessage,
  region,
}: {
  gameName: string | null;
  description: string;
  browserType: string;
  streamId: string | null;
  videoElement: HTMLVideoElement | null;
  participantId?: string;
  initMessage: IInitMessage | null;
  region: string;
}) => {
  const {
    setStreamingProvider,
    setGameCastVideoRef,
    setCurrentRoom,
    isGamecastCrash,
    setIsGamecastCrash,
  } = useMytaverseEvent();

  const { sendMessageToUnreal } = useMytaverse();

  const initializeStream = async () => {
    if (
      !gameName ||
      !streamId ||
      !initMessage ||
      !videoElement ||
      !participantId
    ) {
      return;
    }

    try {
      const gamecastClient = await initializeStreamReconnect(
        videoElement,
        participantId,
        initMessage,
        region,
        browserType,
        description,
        gameName,
        streamId,
        handleReconnectGamecast,
        setIsGamecastCrash,
        sendMessageToUnreal,
      );

      if (gamecastClient) {
        gamecastClient.attachInput();
      }

      setGameCastVideoRef(videoElement);

      return true;
    } catch (e) {
      console.error(e);
      console.log('Changing streaming provider to Coreweave');

      setStreamingProvider(StreamingProviders.CoreWeave);
    }
  };

  const handleReconnectGamecast = useCallback(async () => {
    if (!gameName || !participantId || !streamId || !initMessage) {
      console.log('here');
      return;
    }
    //@ts-ignore
    const GameCastClass = window.gamecastsdk.GameCast;

    const gamecast: GameCast = new GameCastClass({
      videoElement,
      inputConfiguration: {
        autoMouse: true,
        autoKeyboard: true,
        autoGamepad: true,
        hapticFeedback: true,
        setCursor: 'visibility',
        autoPointerLock: true,
      },
      clientConnection: {
        //TODO check how we can use it
        connectionState: (state) => {
          console.log('Connection state: ' + state);
        },
        channelError: (state) => {
          console.log('serverDisconnect state: ' + state);
        },
        serverDisconnect: async (state: GamecastServerDisconnectReason) => {
          switch (state) {
            case GamecastServerDisconnectReason.Terminated:
              await gamecast.close();
              setIsGamecastCrash(true);
              break;
            default:
              break;
          }
        },
        applicationMessage: (state) => {
          console.log('applicationMessage state: ' + state);
        },
      },
    } as GameCastArgs);
    RegisterTouchToMouse(videoElement as any);

    const gamecastSignalData = await gamecast.generateSignalRequest();

    const signalResponse = await startGameSessionHandler(
      description,
      gameName,
      participantId,
      gamecastSignalData,
      GAME_CAST_REQUEST_TIMEOUT,
      streamId,
      initMessage,
      region,
      sendMessageToUnreal,
    );

    await gamecast.processSignalResponse(signalResponse.SignalResponse);

    gamecast.attachInput();
  }, [gameName, description, participantId, streamId, initMessage, region]);

  useAsyncEffect(async () => {
    if (!isGamecastCrash) {
      return;
    }
    setCurrentRoom(null);
    await handleReconnectGamecast();

    setTimeout(() => {
      setIsGamecastCrash(false);
    }, 20000);
  }, [isGamecastCrash]);
  return {
    initializeStream,
  };
};

const initializeStreamReconnect = async (
  videoElement: HTMLVideoElement,
  participantId: string,
  initMessage: IInitMessage,
  region: string,
  browserType: string,
  description: string,
  gameName: string,
  streamId: string,
  handleReconnectGamecast: (
    gamecast: GameCast,
    gamecastSignalData: string,
  ) => Promise<void>,
  setIsGamecastCrash: React.Dispatch<React.SetStateAction<boolean>>,
  sendMessageToUnreal: (message: any) => void,
) => {
  if (!gameName || !streamId) {
    return;
  }

  //@ts-ignore
  const GameCastClass = window.gamecastsdk.GameCast;
  const gameCast: GameCast = new GameCastClass({
    videoElement,
    inputConfiguration: {
      autoMouse: true,
      autoKeyboard: true,
      autoGamepad: true,
      hapticFeedback: true,
      setCursor: 'visibility',
      autoPointerLock: true,
    },
    clientConnection: {
      //TODO check how we can use it
      connectionState: (state) => {
        console.log('Connection state: ' + state);
      },
      channelError: (state) => {
        console.log('serverDisconnect state: ' + state);
      },
      serverDisconnect: async (state: GamecastServerDisconnectReason) => {
        switch (state) {
          case GamecastServerDisconnectReason.Terminated:
            await gameCast.close();
            setIsGamecastCrash(true);
            break;
          default:
            break;
        }
      },
      applicationMessage: (state) => {
        console.log('applicationMessage state: ' + state);
      },
    },
  } as GameCastArgs);
  console.log({ RegisterTouchToMouse });
  RegisterTouchToMouse(videoElement as any);

  const gamecastSignalData = await gameCast.generateSignalRequest();

  const signalResponse = await startGameSessionHandler(
    description,
    gameName,
    participantId,
    gamecastSignalData,
    GAME_CAST_REQUEST_TIMEOUT,
    streamId,
    initMessage,
    region,
    sendMessageToUnreal,
  );

  await gameCast.processSignalResponse(signalResponse.SignalResponse);

  return gameCast;
};

const startGameSessionHandler = async (
  description: string,
  gameName: string,
  userId: string,
  signalRequest: string,
  timeout: number,
  streamId: string,
  initMessage: IInitMessage,
  region: string,
  sendMessageToUnreal: (message: any) => void,
) => {
  showInitMessage(JSON.stringify(initMessage));

  const response: CreateGameSessionResponse =
    await createAndWaitForStreamToBeActive(
      description,
      gameName,
      userId as string,
      signalRequest,
      timeout,
      streamId,
      initMessage,
      region,
    );

  //It might be here to help UE team with debug
  console.log('Gamecast session config', {
    arn: response.Arn,
    applicationArn: response.ApplicationArn,
    userId: response.UserId,
    streamGroupId: response.StreamGroupId,
  });

  sendMessageToUnreal({
    action: 'CLIENT_CONNECTION_DATA',
    streamingProvider: 'GAMECAST',
    sessionArn: response.Arn,
    region,
  });

  return response;
};

const createAndWaitForStreamToBeActive = (
  description: string,
  gameName: string,
  participantId: string,
  signalRequest: string,
  timeout: number,
  streamId: string,
  initMessage: IInitMessage,
  region: string,
) => {
  return new Promise<CreateGameSessionResponse>((resolve, reject) => {
    if (!initMessage) {
      return;
    }

    GameCastService.startGameSession({
      description,
      gameName,
      participantId,
      signalRequest,
      timeout,
      streamId,
      initMessage,
      region,
    })
      .then((data) => {
        resolve({
          SignalResponse: data.SignalResponse,
          Status: data.Status,
          WebSdkProtocolUrl: data.WebSdkProtocolUrl,
          Arn: data.Arn,
          ApplicationArn: data.ApplicationArn,
          StreamGroupId: data.StreamGroupId,
          UserId: data.UserId,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
