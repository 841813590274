import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useMytaverse } from '../../providers/MytaverseProvider';

import { IAppLogo } from './interfaces';

import { classes } from './styles';

const AppLogo = ({ to, color }: IAppLogo) => {
  const { appTheme } = useMytaverse();
  const Logo = appTheme.customerLogo;

  const LogoContent = (
    <Box sx={color ? { ...classes.logo, ...{ color } } : classes.logo}>
      {typeof Logo === 'string' ? <img src={Logo} alt="Event Logo" /> : Logo}
    </Box>
  );

  if (to) {
    return <Link to={to}>{LogoContent}</Link>;
  }

  return LogoContent;
};

export default AppLogo;
