import { useTheme } from '@mui/material';
import { colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      height: 4,
      width: 22,
      background: colors.oxford_10,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        width: 12,
      },
    },
  };
};
