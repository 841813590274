import React, { useState } from 'react';

import ChatDrawerView from './ChatDrawerView';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { useChatState } from '../../../../hooks/context';

import { toggleDrawer } from '../../../../helpers';

import { ToggleDrawerType } from '../../interfaces';
import { useConference } from '../DashboardContent/Dolby';

const ChatDrawer = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { selectedLanguage } = useMytaverse();
  const { screenMediaStreams } = useConference();
  const {
    open: isOpenChat,
    setOpen,
    initialLoading,
    streamClient,
    activeTab,
  } = useChatState();

  const handleToggleDrawer: ToggleDrawerType = (event) => {
    toggleDrawer(event, () => setOpen(!isOpenChat));
  };

  if (!streamClient) {
    return null;
  }

  return (
    <ChatDrawerView
      isOpenDrawer={isOpenChat}
      toggleDrawer={handleToggleDrawer}
      streamClient={streamClient}
      isLoading={initialLoading}
      language={selectedLanguage}
      activeTab={activeTab}
      hasScreenMediaStreams={!!screenMediaStreams.length}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    />
  );
};

export default ChatDrawer;
