import { COLORS, colors } from '../../../../../constants/colors';
import { SxProps } from '@mui/material';

export const useStyles = () => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: [280, 640],
    height: 640,
    border: `2px solid ${COLORS.NEW_BLACK}`,
    boxShadow: 24,
    p: ['20px 10px', 3],
    pt: 2.5,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    maxHeight: '90vh',
    maxWidth: '90vw',
    overflow: 'auto',
    '& p': {
      color: colors.white,
    },

    '@media screen and ( max-height: 750px )': {
      height: 540,
    },
  } as SxProps,
  icon: {
    color: colors.white,
    mr: 1,
  },
  title: {
    color: COLORS.WHITE,
    fontSize: 24,
    textAlign: 'center',
  },
  root: {
    border: 'none !important',
    width: '100%',
    height: '100%',
  },
  startSharing: {
    color: COLORS.WHITE,
    border: `1px solid ${COLORS.WHITE}`,
    fontSize: 16,
    borderRadius: 1,
    textTransform: 'capitalize',
    pt: 1,
    pb: 1,
    mt: 4,
    '&:hover': {
      transform: 'scale(1.01)',
    },
  } as SxProps,
});
