import { colors } from '../../constants/colors';

export const classes = {
  logo: {
    height: 32,
    color: colors.oxford,
    '& svg': {
      color: 'inherit',
      width: '100%',
      height: 'auto',
      maxWidth: 184,
      maxHeight: 80,
      objectFit: 'contain',
    },
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: 184,
      maxHeight: 80,
    },
  },
};
