import React from 'react';

import { SwiperSlide } from 'swiper/react';

import SwiperWrapper from '../SwiperWrapper';
import SelectEventItem from './SelectEventItem';

import { SLIDER_WIDTH } from './constants';

import { SwiperScreenSizes } from '../../../../interfaces';
import { ISelectEventContent } from './interfaces';

const breakpoints = {
  [SwiperScreenSizes.Mobile]: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    allowTouchMove: true,
  },
  [SwiperScreenSizes.Tablet]: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    allowTouchMove: true,
  },
  [SwiperScreenSizes.Laptop]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 30,
    allowTouchMove: false,
  },
  [SwiperScreenSizes.Pc]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 65,
    allowTouchMove: false,
  },
};

const SelectEventContent = ({ events, loop }: ISelectEventContent) => {
  return (
    <SwiperWrapper
      breakpoints={breakpoints}
      loop={loop}
      sliderWidth={SLIDER_WIDTH}
    >
      {events.map((event) => (
        <SwiperSlide key={event.id}>
          <SelectEventItem event={event} key={event.id} />
        </SwiperSlide>
      ))}
    </SwiperWrapper>
  );
};

export default SelectEventContent;
