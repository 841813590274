import React, { ReactNode, useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import useWebSocket, { Options } from 'react-use-websocket';
import useAsyncEffect from 'use-async-effect';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../components/Notification';
import { useHandleApiErrors } from './hooks';
import { useHandleWebsocketError } from '../../hooks/websocket';

import { getAppTheme } from './helpers';
import {
  setSentryAuthUserData,
  setSentryEventData,
} from '../../helpers/sentry';
import { removeLocalStorageValue } from '../../helpers/localStorage';

import EventsService from '../../services/EventsService';
import LoginService from '../../services/LoginService';
import UserService from '../../services/UserService';

import ROUTES from '../../constants/routes';

import { IEventStyle } from '../../interfaces';
import { IEvent } from '../../interfaces/event';
import {
  IUser,
  IUserCredentials,
  IAutoUserCredentials,
  ILoginUserResponse,
} from '../../interfaces/user';
import { IWebSocketConnectionInfo } from '../../interfaces/webSocketConnectionInfo';
import { InitialValues } from '../../components/UserProfileModal/UserProfileForm/interfaces';
import { FirebaseResponseProps } from '../../services/Firebase';
import { GetWebSocketOptionsType, GetWebSocketUrlType } from './interfaces';
import { StorageValues } from '../../interfaces/storage';
import ParticipantsService from '../../services/ParticipantsService';
import { reloadPageTimeout } from '../../helpers';

import { default as mytaverseWebsocketLogger } from './logger';
export { mytaverseWebsocketLogger };

interface IMytaverseContext {
  token: string;
  userId?: string | null;
  user: IUser;
  events: IEvent[];
  loginError?: boolean;
  currentEventId?: string;
  isReconnect: boolean;
  setIsReconnect: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentEventId: (eventId: string) => void;
  loginUser: (credo: IUserCredentials) => Promise<void>;
  autoLoginUser: (credo: IAutoUserCredentials) => Promise<void>;
  setUser?: React.Dispatch<IUser | any>;
  logoutUser: (withRedirection?: boolean) => void;
  changeUserProfile: (username: any) => void;
  loading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  socialLogin: (credentials: FirebaseResponseProps['user']) => Promise<void>;
  changingUserData: boolean;
  selectedLanguage: string;
  selectLanguage: (language: string) => void;
  appTheme: IEventStyle;
  websocketConnectionInfo?: IWebSocketConnectionInfo;
  setWebsocketConnectionInfo: (
    websocketConnectionInfo: IWebSocketConnectionInfo,
  ) => void;
  getWebsocketUrl: GetWebSocketUrlType;
  getWebSocketOptions: GetWebSocketOptionsType;
  websocketSessionId: string;

  setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  sendMessageToUnreal: (message: any) => void;
  sendMessageToEvent: (eventId: string, message: any) => void;
  sendMessageToRoom: (roomId: string, message: any) => void;
  sendMessageToParticipant: (participantId: string, message: any) => void;
  sendJSONMessageToWebSocket: (message: any) => void;
  loadEvents: (startLoading?: boolean | undefined) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MytaverseContext = React.createContext<IMytaverseContext>({});

export const useMytaverse = () => useContext(MytaverseContext);

type MytaverseProviderProps = {
  children: ReactNode;
};

export const MytaverseProvider: React.FC<MytaverseProviderProps> = ({
  children,
}) => {
  const { t: translate, i18n } = useTranslation('common');
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    localStorage.getItem('language') || 'EN',
  );

  const token = React.useRef<string>('');
  const [user, setUser] = React.useState<IUser | any>();
  const [userId, setUserId] = React.useState<string | null>(
    localStorage.getItem('userId') ? localStorage.getItem('userId') : null,
  );

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [changingUserData, setChangingUserData] = React.useState(false);
  const [events, setEvents] = React.useState<IEvent[]>([]);
  const [currentEventId, setCurrentEventId] = React.useState<string>('');
  const [isReconnect, setIsReconnect] = React.useState(false);
  const websocketSessionIdRef = React.useRef(nanoid(20));

  const { showNotification, getBadRequestNotification } = useNotification();

  const [appTheme, setAppTheme] = React.useState<any | null>(null);

  const location = useLocation();
  const redirectTo =
    location.pathname !== ROUTES.LOGIN
      ? location.pathname
      : ROUTES.SELECT_EVENT;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleWebsocketError = useHandleWebsocketError();

  const prepareWebSocketUrl = (): string => {
    const webSocketUrl = new URL(
      process.env.REACT_APP_SPATIAL_MANAGER_WEB_SOCKET_URL as string,
    );
    webSocketUrl.searchParams.append('token', token.current);
    webSocketUrl.searchParams.append('session', websocketSessionIdRef.current);

    return webSocketUrl.toString();
  };

  const getWebsocketUrl = useCallback(
    (): Promise<string> =>
      new Promise<string>((resolve) => {
        if (token.current) {
          resolve(prepareWebSocketUrl());
        } else {
          const interval = setInterval(() => {
            if (token.current) {
              clearInterval(interval);
              resolve(prepareWebSocketUrl());
            }
          }, 1000);
        }
      }),
    [],
  );

  const getWebSocketOptions: GetWebSocketOptionsType = useCallback(
    ({ withReconnection }) => {
      let options: Options = {
        share: true,
        onClose: (event) => {
          if (withReconnection) {
            mytaverseWebsocketLogger.log(
              'Disconnected from mytaverse websocket',
            );
            handleWebsocketError(event);
          }
        },
      };

      if (withReconnection) {
        options = {
          ...options,
          retryOnError: true,
          reconnectAttempts: 60,
          reconnectInterval: 1000,
          shouldReconnect: () => {
            mytaverseWebsocketLogger.log('Reconnecting to websocket');
            return true;
          },
          onOpen: () => {
            mytaverseWebsocketLogger.log('Connected to mytaverse websocket');
          },
          onError: (event) => {
            mytaverseWebsocketLogger.error(
              `Mytaverse websocket error: ${JSON.stringify(event)}`,
            );
            reloadPageTimeout(10, 'Mytaverse websocket error');
          },
        };
      }

      return options;
    },
    [],
  );

  const { sendJsonMessage } = useWebSocket(
    getWebsocketUrl,
    getWebSocketOptions({ withReconnection: true }),
  );

  const [websocketConnectionInfo, setWebsocketConnectionInfo] =
    React.useState<IWebSocketConnectionInfo>();

  const sendMessageToUnreal = useCallback(
    (message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_UNREAL',
        message,
      });
    },
    [sendJsonMessage],
  );

  const sendMessageToEvent = useCallback(
    (eventId: string, message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_EVENT',
        eventId,
        message,
      });
    },
    [sendJsonMessage],
  );

  const sendMessageToRoom = useCallback(
    (roomId: string, message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_ROOM',
        roomId,
        message,
      });
    },
    [sendJsonMessage],
  );

  const sendMessageToParticipant = useCallback(
    (participantId: string, message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_PARTICIPANT',
        participantId,
        message,
      });
    },
    [sendJsonMessage],
  );

  const loadEvents = useCallback(
    async (startLoading: boolean | undefined = false) => {
      if (startLoading) {
        setLoading(true);
      }
      const { events: lastJoinedEvents } =
        await ParticipantsService.getCurrentParticipantProfile();

      const selectedEventId = sessionStorage.getItem('selectedEventId') || '';

      const userEvents: IEvent[] = await EventsService.getEvents();

      if (!userEvents.length && user) {
        showNotification(
          getBadRequestNotification({
            message: `The email address ${user.email} is not invited to any worlds. Are you sure this is the correct email address?`,
          }),
        );

        console.error(
          `The email address ${user.email} is not invited to any worlds. Are you sure this is the correct email address?`,
        );

        setTimeout(async () => {
          await logoutUser();
        }, 10000);

        setLoading(false);

        return;
      }

      const mergedUserEvents = userEvents
        .reduce((acc: IEvent[], event) => {
          const visitedEvent = lastJoinedEvents.find(
            (e) => e.eventId === event.id,
          );

          if (!visitedEvent) {
            return [...acc, event];
          }

          return [
            ...acc,
            { ...event, lastJoinTime: visitedEvent.lastJoinTime },
          ];
        }, [])
        .sort((a, b) => (b.lastJoinTime || 0) - (a.lastJoinTime || 0));

      if (mergedUserEvents.length !== 0) {
        setEvents(mergedUserEvents);

        if (mergedUserEvents.length === 1 && pathname.includes(ROUTES.LOGIN)) {
          const event = mergedUserEvents[0];

          navigate(ROUTES.AVATAR_CHOOSING);
          setCurrentEventId(event.id);
          sessionStorage.setItem('selectedEventId', event.id);
          setSentryEventData({ id: event.id, name: event.name });
          setLoading(false);
          return;
        }

        if (pathname === ROUTES.SELECT_EVENT) {
          setLoading(false);
          return;
        }

        // !pathname.includes(ROUTES.CONFIRM_EVENT) - prevent redirect to ROUTES.CONFIRM_EVENT
        if (
          pathname.includes(ROUTES.LOGIN) &&
          !pathname.includes(ROUTES.CONFIRM_EVENT)
        ) {
          navigate(redirectTo);
        }
        setCurrentEventId(selectedEventId);

        if (selectedEventId) {
          const event = mergedUserEvents.find(
            (event) => event.id === selectedEventId,
          );
          setSentryEventData({ id: selectedEventId, name: event?.name || '' });
        }
      }

      setLoading(false);
    },
    [
      setLoading,
      setEvents,
      setCurrentEventId,
      loading,
      pathname,
      currentEventId,
      user,
      redirectTo,
    ],
  );

  const changeUserProfile = async ({
    company,
    username,
    linkedIn,
    phoneNumber,
    profileImage,
  }: InitialValues) => {
    const [firstName, ...lastName] = username
      .split(' ')
      .filter((item: string) => item !== ' ' && item !== '');
    if (user) {
      if (user.firstName !== firstName || user.lastName !== lastName) {
        setChangingUserData(true);
        const data = new FormData();

        Object.entries({
          company,
          firstName: firstName,
          lastName: lastName.join(',').replaceAll(',', ' '),
          linkedIn,
          phoneNumber,
          profileImage,
          email: user.email,
        }).forEach(([key, value]) => data.append(`${key}`, value as any));

        const updUser = await UserService.changeUser(user.id, data);

        setUser(updUser);
        setChangingUserData(false);
      }
    }
  };

  const setLoginData = (userData: ILoginUserResponse) => {
    token.current = userData.token;
    setUserId(userData.user.id);
    setUser(userData.user);
    setSentryAuthUserData({
      id: userData.user.id,
      email: userData.user.email,
      firstName: userData.user.firstName,
      lastName: userData.user.lastName,
    });

    Cookies.set('token', userData.token, {
      secure: true,
    });

    localStorage.setItem('userId', userData.user.id);
  };

  const loginUser = useCallback(
    async (credentials: any) => {
      setLoading(true);

      try {
        if (!user) {
          const userData = await LoginService.loginUser(credentials);

          setLoginData(userData);

          const inviteToken = sessionStorage.getItem('inviteToken');

          if (inviteToken) {
            navigate(`${ROUTES.LOGIN}/${ROUTES.CONFIRM_EVENT}/${inviteToken}`);
            return;
          }

          await loadEvents();
          setIsAuthenticated(true);
        }
      } catch (error: unknown | Error) {
        showNotification(
          getBadRequestNotification({ message: (error as Error).message }),
        );
        setIsAuthenticated(false);
        navigate(ROUTES.LOGIN);
        setLoading(false);
      }

      setLoading(false);
    },
    [user],
  );

  const socialLogin = useCallback(
    async (credentials: FirebaseResponseProps['user']) => {
      setLoading(true);

      if (!user) {
        if (credentials) {
          const userData = await LoginService.socialLogin(credentials);

          setLoginData(userData);

          const inviteToken = sessionStorage.getItem('inviteToken');

          if (inviteToken) {
            navigate(`${ROUTES.LOGIN}/${ROUTES.CONFIRM_EVENT}/${inviteToken}`);
            return;
          }

          await loadEvents();
          setIsAuthenticated(true);
          navigate(redirectTo);
        }

        setLoading(false);
      }
    },
    [user],
  );

  const autoLoginUser = useCallback(
    async (credentials: IAutoUserCredentials) => {
      setLoading(true);

      try {
        if (!user) {
          const userData = await LoginService.autoLoginUser(credentials);
          setLoginData(userData);
          return;
        }
      } catch (error: unknown | Error) {
        showNotification(
          getBadRequestNotification({ message: (error as Error).message }),
        );
        setIsAuthenticated(false);
        navigate(ROUTES.LOGIN);
        setLoading(false);
      }

      setLoading(false);
    },
    [user],
  );

  useEffect(() => {
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(
      selectedLanguage ? selectedLanguage.toLowerCase() : 'en',
    );
  }, [selectedLanguage]);

  useEffect(() => {
    if (
      pathname.startsWith(ROUTES.LOGIN) &&
      localStorage.getItem('userId') &&
      !pathname.includes(ROUTES.CONFIRM_EVENT) &&
      !pathname.includes(ROUTES.REGISTER) &&
      !pathname.includes('confirm')
    ) {
      sessionStorage.getItem('selectedEventId')
        ? navigate(ROUTES.HOME_PAGE)
        : navigate(ROUTES.SELECT_EVENT);
    }
  }, [pathname, user]);

  const clearUserLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('selectedEventId');
    localStorage.removeItem('currentRoomId');
    localStorage.removeItem('participantsSound');
    localStorage.removeItem('currentRoomId');
    Cookies.remove('tutorial');
    Cookies.remove('token');
  };

  const logoutUser = React.useCallback(
    async (withRedirection = true) => {
      setLoading(true);
      setUserId(null);
      setUser(null);
      setCurrentEventId('');
      token.current = '';

      Cookies.remove('token');
      removeLocalStorageValue(StorageValues.DismissMessages);

      setEvents([]);
      setIsAuthenticated(false);

      clearUserLocalStorage();

      if (withRedirection) {
        navigate(ROUTES.LOGIN);
        window.location.reload();

        return;
      }

      setLoading(false);
    },
    [
      setLoading,
      setUserId,
      setUser,
      setCurrentEventId,
      setEvents,
      setIsAuthenticated,
      clearUserLocalStorage,
      navigate,
    ],
  );

  useHandleApiErrors(logoutUser);

  useEffect(() => {
    const websocketHealthCheckInterval = setInterval(() => {
      sendJsonMessage({ action: 'HEALTH_CHECK' });
    }, 60000);

    return () => {
      if (websocketHealthCheckInterval) {
        clearInterval(websocketHealthCheckInterval);
      }
    };
  }, [sendJsonMessage]);

  useAsyncEffect(async () => {
    const cookieToken = Cookies.get('token');
    const userId = localStorage.getItem('userId');

    setLoading(true);
    setAppTheme(await getAppTheme());

    // if not logged user got to private route
    if ((!cookieToken || !userId) && !pathname.includes(ROUTES.LOGIN)) {
      setLoading(false);

      return navigate(ROUTES.LOGIN);
    }

    if (cookieToken) {
      token.current = cookieToken;
      Cookies.set('token', cookieToken, {
        secure: true,
      });
    }

    try {
      setLoading(true);

      if (!user && cookieToken && userId) {
        const { user } = await LoginService.getUser(userId);

        setUserId(userId);
        setUser(user);
        setSentryAuthUserData({
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        });

        setIsAuthenticated(true);

        // prevent loadEvent when go to /login/event_subscribe
        if (pathname.includes(`/${ROUTES.CONFIRM_EVENT}`)) return;

        await loadEvents();
      }

      setLoading(false);
    } catch (error: unknown | Error) {
      showNotification(
        getBadRequestNotification({
          title: translate('login.sessionExpired'),
          message: translate('login.loginAgain'),
          type: NOTIFICATION_TYPES.WARNING,
        }),
      );
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading && !isAuthenticated && !pathname.includes(ROUTES.LOGIN)) {
      navigate(ROUTES.LOGIN);
    }
  }, [loading, isAuthenticated]);

  const selectLanguageHandler = React.useCallback(
    (language: string) => {
      setSelectedLanguage(language);
      localStorage.setItem('language', language);
      i18n.changeLanguage(language ? language.toLowerCase() : 'en');
    },
    [setSelectedLanguage],
  );

  const setCurrentEventIdHandler = React.useCallback(
    (eventId: string) => {
      sessionStorage.setItem('selectedEventId', eventId);

      if (currentEventId) {
        sendJsonMessage({ action: 'LEAVE_EVENT' });
      }

      const event = events.find((event) => event.id === eventId);

      setCurrentEventId(eventId);
      setSentryEventData({ id: eventId, name: event?.name || '' });
    },
    [currentEventId, setCurrentEventId, events],
  );

  return (
    <MytaverseContext.Provider
      value={{
        token: token.current,
        userId,
        user,
        events,
        loading,
        setIsAuthenticated,
        socialLogin,
        changeUserProfile,
        changingUserData,
        loginUser,
        autoLoginUser,
        currentEventId,
        setLoading,
        setCurrentEventId: setCurrentEventIdHandler,
        appTheme,
        selectedLanguage,
        selectLanguage: selectLanguageHandler,

        logoutUser,
        isReconnect,
        setIsReconnect,
        websocketSessionId: websocketSessionIdRef.current,
        websocketConnectionInfo,
        setWebsocketConnectionInfo,
        getWebsocketUrl,
        getWebSocketOptions,

        sendMessageToUnreal,
        sendMessageToEvent,
        sendMessageToRoom,
        sendMessageToParticipant,
        sendJSONMessageToWebSocket: sendJsonMessage,
        loadEvents,
      }}
    >
      {children}
    </MytaverseContext.Provider>
  );
};
