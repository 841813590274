import { keyframes } from '@mui/system';

import { colors, COLORS } from '../../../../constants/colors';

import { IHomeBarButtonStyles } from './intefaces';
import { IStyles } from '../../../../interfaces/styles';
import { ScreenSizes } from '../../../../interfaces';
import { useTheme } from '@mui/material';

const iconPulseKeyframe = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 118, 153, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 118, 153, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 118, 153, 0);
  }
`;

export const useStyles = ({
  showPulseAnimation,
}: IHomeBarButtonStyles): IStyles => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: ['none', 'flex'],
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      height: 'fit-content',
    },
    button: {
      backgroundColor: COLORS.DARK_GREY,
      backdropFilter: 'blur(6px)',
      border: `1px solid ${COLORS.NEW_LOGIN_GREY}`,
      padding: '15px',
      borderRadius: 72,
      boxSizing: 'border-box',
      color: showPulseAnimation ? COLORS.ERROR : colors.white,
      '& svg': {
        width: 24,
        height: 24,
      },
      '&:hover': {
        backgroundColor: COLORS.BACKGROUND_COLOR,
      },
      '&.Mui-disabled': {
        backgroundColor: COLORS.DARK_GREY,
        color: showPulseAnimation ? COLORS.ERROR : colors.white,
        opacity: 0.5,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '11px',
        '& svg': {
          width: 18,
          height: 18,
        },
      },
    },
    pulseBox: {
      backgroundColor: COLORS.ERROR,
      animation: `${iconPulseKeyframe} 2s infinite`,
      position: 'absolute',
      borderRadius: 72,
      padding: 2,
      width: 62,
      height: 62,
      boxSizing: 'border-box',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        width: 52,
        height: 52,
      },
    },
  };
};
