/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

import { Avatar } from '@mui/material';
import { IParticipant } from '../../../../../interfaces/participants';
import { useConference } from '../Dolby';
import { useStyles } from './styles';
import { useMytaverseEvent } from '../../../providers';

type ParticipantAvatarProps = {
  participant: IParticipant | undefined;
};

const ParticipantAvatar: React.FC<ParticipantAvatarProps> = ({
  participant,
}) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const { toggleVideo } = useConference();
  const { currentParticipant } = useMytaverseEvent();
  const { cameraMediaStreams } = useConference();
  const classes = useStyles();

  const mediaStream = React.useMemo(() => {
    return (
      cameraMediaStreams.find(
        (stream) => stream.participantId === currentParticipant?.id,
      ) || null
    );
  }, [cameraMediaStreams]);

  React.useEffect(() => {
    if (participant && currentParticipant && mediaStream) {
      // @ts-ignore
      if (mediaStream.type === 'Camera') {
        // @ts-ignore
        navigator.attachMediaStream(videoRef.current, mediaStream?.stream);
      }
    }
  }, [participant, mediaStream]);

  return (
    <Avatar
      src={!participant?.mediaStream ? participant?.avatarImage : ''}
      alt=""
      sx={classes.root}
      onClick={() => toggleVideo()}
    >
      {videoRef && mediaStream && (mediaStream as any).type === 'Camera' ? (
        <video playsInline ref={videoRef} autoPlay muted />
      ) : (
        participant?.avatarString
      )}
    </Avatar>
  );
};

export default ParticipantAvatar;
