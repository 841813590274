import React from 'react';
import { useStyles } from './styles';
import { useAvaturn } from './hooks';
import { Box } from '@mui/material';
import LoggedAppBar from '../../../../components/LoggedAppBar';
import BackButton from '../../components/BackButtonOld';

const Avaturn = () => {
  const classes = useStyles();
  const { handleBackBtnClick } = useAvaturn();

  return (
    <>
      <LoggedAppBar />
      <Box
        component="div"
        sx={classes.iframeWrapper}
        id="avaturn-sdk-container"
      >
        <Box component="div" sx={classes.back}>
          <BackButton onClick={handleBackBtnClick} text="to avatars" />
        </Box>
      </Box>
    </>
  );
};

export default Avaturn;
