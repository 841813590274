import axios from 'axios';

import { IPixelStreamingLog } from '../interfaces/pixelStreamingLogs';
import $api from '../http/axios';

export default class PixelStreamingLogsService {
  static createPixelStreamingLog(
    iPixelStreamingLog: IPixelStreamingLog,
  ): Promise<number> {
    return $api.post(
      `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/pixel-streaming/logs`,
      iPixelStreamingLog,
    );
  }
}
