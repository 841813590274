import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import HomeBottomButtonOptions from './HomeBottomButtonOptions';
import ChatButton from './ChatButton';
import ExitButton from './ExitButton';
import Actions from './Actions';
import ToolBox from './ToolBox';

import { useConference } from '../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../providers';

import { useStyles } from './styles';
import MytaverseAppBar from '../../../../components/MytaverseAppBar';
import VirtualAssistant from './VirtualAssistant/VirtualAssistant';
import ToggleScreenSharingView from './ToggleScreenSharingView';

const HomeBottomBar = () => {
  const classes = useStyles();

  const { videoStarted, toggleVideo } = useConference();
  const { currentRoom } = useMytaverseEvent();

  const showDolbyConferenceControls = !!currentRoom;

  useEffect(() => {
    return () => {
      if (videoStarted) {
        toggleVideo();
      }
    };
  }, [videoStarted]);

  if (!showDolbyConferenceControls) {
    return null;
  }

  return (
    <>
      <MytaverseAppBar position="bottom" sx={classes.leftBar}>
        <Box sx={classes.section}>
          <HomeBottomButtonOptions />
        </Box>
      </MytaverseAppBar>
      <MytaverseAppBar position="bottom" sx={classes.rightBar}>
        <Box sx={classes.section}>
          <ToggleScreenSharingView />
          <VirtualAssistant />
          <ToolBox />
          <Actions />
          <ChatButton />
        </Box>
      </MytaverseAppBar>
      <MytaverseAppBar position="bottom" sx={classes.centerBar}>
        <ExitButton />
      </MytaverseAppBar>
    </>
  );
};

export default HomeBottomBar;
