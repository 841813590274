import React, { useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import CloseIcon from '@mui/icons-material/Close';

import { useMytaverseEvent } from '../../../providers';

import AppModal from '../../../../../components/AppModal';
import MillicastVideoComponent from '../../HomeBottomBar/ShareScreen/MilicastVideo/MilicastVideo';

import { useStyles } from './styles';

const MillicastShareVideoModal = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();
  const {
    isShareVideoModalOpen,
    closeShareVideoModal,
    loadingShareVideoModal,
  } = useMytaverseEvent();

  const handleClick = useCallback(async () => {
    if (loadingShareVideoModal) {
      return;
    }

    await closeShareVideoModal();
  }, [loadingShareVideoModal, closeShareVideoModal]);

  return (
    <AppModal
      isOpen={isShareVideoModalOpen}
      onClick={handleClick}
      sx={classes.modal}
    >
      <Box sx={classes.title} component="div">
        <Box component="div" sx={classes.titleWrapper}>
          <VideocamIcon />
          <Typography sx={classes.titleName}>
            {translate('millicastWebcamShare.share')}
          </Typography>
        </Box>
        <IconButton onClick={handleClick} sx={classes.titleButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={classes.settingsContainer}>
        <MillicastVideoComponent />
      </Box>
    </AppModal>
  );
};

export default withTranslation('common')(MillicastShareVideoModal);
