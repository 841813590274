import React, { useCallback, useEffect, useRef } from 'react';
import { Subject } from 'rxjs';
import { camelCase } from 'lodash';

import { useMytaverseEvent } from '../../../providers';
import { useConference } from '../Dolby';
import { useChatState } from '../../../../../hooks/context';

import { checkIsPositionChangedAction } from './helpers';
import { setSessionStorageValue } from '../../../../../helpers/sessionStorage';

import { CHAT_DRAWER_WIDTH } from '../../../../../constants';

import { IPurewebStreamerMessage } from './interfaces';
import { ISubscription } from '../../../../../interfaces';
import { SpatialType } from '../../../../../interfaces/webSocketConnectionInfo';
import { SessionStorage } from '../../../../../constants/storage';

export const useWindowResolution = () => {
  const { open: isOpenChat } = useChatState();
  const [windowResolution, setWindowResolution] = React.useState({
    heigh: window.innerHeight,
    width: window.innerWidth,
  });

  const setSizes = useCallback(() => {
    setWindowResolution((prevState) => ({
      ...prevState,
      heigh: window.innerHeight,
      width: isOpenChat
        ? window.innerWidth - CHAT_DRAWER_WIDTH
        : window.innerWidth,
    }));
  }, [isOpenChat]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setSizes);

    return () => {
      window.removeEventListener('resize', setSizes);
    };
  }, [isOpenChat]);

  useEffect(() => {
    setWindowResolution((prevState) => ({
      ...prevState,
      heigh: window.innerHeight,
      width: isOpenChat
        ? window.innerWidth - CHAT_DRAWER_WIDTH
        : window.innerWidth,
    }));
  }, [isOpenChat]);

  return { windowResolution };
};

export const useParticipantPositionListener = (
  messageSubject: Subject<string>,
) => {
  const subscriptionRef = useRef<ISubscription>();
  const prevLastJsonMessageRef = useRef<string>('');

  const { setParticipantPosition, setDisabledPersonView, disabledPersonView } =
    useConference();
  const { initMessageSended, setParticipantState, currentParticipant } =
    useMytaverseEvent();

  const handleGameMessage = useCallback(
    async (message: IPurewebStreamerMessage) => {
      const {
        action,
        timestamp,
        playerId,
        playerPosition,
        regionSpatialType,
        isSeatRegion,
      } = message;
      let { regionName } = message;

      if (regionName) {
        regionName = camelCase(regionName);
      }

      if (
        checkIsPositionChangedAction(action) &&
        playerId &&
        playerPosition &&
        initMessageSended &&
        currentParticipant
      ) {
        const { eventId, roomId, CurrentGameSessionId, ...position } =
          playerPosition;

        if (disabledPersonView && !isSeatRegion) {
          setDisabledPersonView(false);
        }

        if (!disabledPersonView && isSeatRegion) {
          setDisabledPersonView(true);
        }

        const currentRegionName = currentParticipant.region
          ? currentParticipant.region.region
          : '';

        if (
          !currentParticipant.roomId ||
          currentParticipant.roomId !== roomId ||
          currentParticipant.gameSessionId !== CurrentGameSessionId ||
          currentRegionName !== regionName
        ) {
          setParticipantState(currentParticipant.id, {
            eventId,
            roomId,
            region: regionName
              ? {
                  timestamp: Date.now(),
                  region: regionName,
                  state: 'joined',
                  regionSpatialType: regionSpatialType
                    ? regionSpatialType
                    : SpatialType.SpatialAudio,
                }
              : null,
            regions: [],
            gameSessionId: CurrentGameSessionId,
          });
        }

        if (currentParticipant.id === playerId) {
          const normalizedPosition = {
            roomId,
            timestamp: timestamp ? parseInt(timestamp, 10) : 0,
            x: Math.trunc(position.x),
            y: Math.trunc(position.y),
            z: Math.trunc(position.z),
            r: Math.trunc(position.r),
          };

          setParticipantPosition(normalizedPosition);
          setSessionStorageValue(
            SessionStorage.ParticipantPosition,
            normalizedPosition,
          );
        }
      }
    },
    [
      initMessageSended,
      setParticipantPosition,
      setParticipantState,
      currentParticipant,
    ],
  );

  useEffect(() => {
    if (subscriptionRef.current) {
      return;
    }

    subscriptionRef.current = messageSubject.subscribe(
      async (value: string) => {
        if (prevLastJsonMessageRef.current !== value) {
          const message: IPurewebStreamerMessage = JSON.parse(value);
          prevLastJsonMessageRef.current = value;

          await handleGameMessage(message);
        }
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    );

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = undefined;
      }
    };
  }, [handleGameMessage, messageSubject]);
};

export const useCurrentParticipantSpeaking = () => {
  const { speakingParticipantIds } = useConference();
  const { currentParticipant } = useMytaverseEvent();
  const [currentParticipantSpeaking, setCurrentParticipantSpeaking] =
    React.useState(false);
  useEffect(() => {
    if (!currentParticipant) {
      return;
    }
    const isSpeaking = speakingParticipantIds.some(
      (id) => id === currentParticipant.id,
    );

    setCurrentParticipantSpeaking(isSpeaking);
  }, [speakingParticipantIds, currentParticipant]);

  return {
    currentParticipantSpeaking,
  };
};
