import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { INotificationView } from './interfaces';

import { useStyles } from './styles';
import { CloseIcon } from '../../icons';

const NotificationView = React.forwardRef<HTMLDivElement, INotificationView>(
  (
    {
      title = '',
      message,
      color,
      background,
      icon: Icon,
      reloadPage,
      onDismiss,
      withReloadButton = false,
      withDismissButton = true,
      onClickClose,
    },
    ref,
  ) => {
    const classes = useStyles({ color, background, title });
    const { t: translate } = useTranslation('common');

    return (
      <Box ref={ref} sx={classes.root}>
        <CloseIcon sx={classes.closeIcon} onClick={onClickClose} />
        <Box sx={classes.titleContainer}>
          <Icon sx={classes.icon} />
          <Typography sx={classes.title}>{title || message}</Typography>
        </Box>
        {!!title && <Typography sx={classes.message}>{message}</Typography>}
        <Box sx={classes.buttonsContainer}>
          {withDismissButton && (
            <Button onClick={onDismiss} sx={classes.button}>
              {translate('notifications.doNotShowAgain')}
            </Button>
          )}
          {withReloadButton && (
            <Button onClick={reloadPage} sx={classes.button}>
              {translate('notifications.reloadPage')}
            </Button>
          )}
        </Box>
      </Box>
    );
  },
);

NotificationView.displayName = 'NotificationView';

export default NotificationView;
