import { COLORS } from '../../../../../constants/colors';
import { SxProps, useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    modal: {
      width: 540,
      border: `2px solid ${COLORS.NEW_BLACK}`,
      boxShadow: 24,
      p: 2,
      pt: 1.5,
    } as SxProps,
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    root: {
      border: 'none !important',
      width: '100%',
      height: '100%',
    },
    startSharing: {
      color: COLORS.WHITE,
      border: `1px solid ${COLORS.WHITE}`,
      fontSize: 16,
      borderRadius: 1,
      textTransform: 'capitalize',
      pt: 1,
      pb: 1,
      mt: 4,
      '&:hover': {
        transform: 'scale(1.01)',
      },
    } as SxProps,
    settingsContainer: {
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiFormControl-root': {
        mt: 0,
        mb: 3,
      },
    },
    titleName: {
      textAlign: 'left',
      color: COLORS.WHITE,
      fontSize: 18,
      lineHeight: 3,
      [breakpoints.down(ScreenSizes.Pc)]: {
        lineHeight: 1.5,
      },
    },

    titleButton: {
      '& svg': {
        color: COLORS.WHITE,
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
      '& svg': {
        color: COLORS.LIGHT_GREY,
      },
    },
  };
};
