import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import AppHeader from '../../../../components/AppHeader';
import Footer from '../../../../components/Footer';

import { DeviceOrientationIcon } from '../../../../icons';

import { useStyles } from './styles';

const OrientationOverlay = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <AppHeader isAuthorized showEventsDropdown />
      <Box sx={classes.container}>
        <Box sx={classes.content}>
          <DeviceOrientationIcon sx={classes.icon} />
          <Typography sx={classes.title}>
            {translate('orientationScreen.title')}
          </Typography>
          <Typography sx={classes.description}>
            {translate('orientationScreen.text')}{' '}
            <Typography component="span" sx={classes.boldText}>
              {translate('orientationScreen.textBold')}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default withTranslation('common')(OrientationOverlay);
