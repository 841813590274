import Draggable from 'react-draggable';
import { useStyles } from './styles';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import ConfigTab from './ConfigTab/ConfigTab';
import MessagesTab from './MessagesTab';

enum DevelopersTerminalTabs {
  Config = 'CONFIG',
  Messages = 'MESSAGES',
}

const DevelopersTerminal = () => {
  const [tabValue, setTabValue] = useState(DevelopersTerminalTabs.Config);
  const classes = useStyles();

  return (
    <Draggable cancel=".no-drag" defaultPosition={{ x: 100, y: 200 }} scale={1}>
      <Box sx={classes.root}>
        <TabContext value={tabValue}>
          <Box sx={{ width: '100%' }}>
            <TabList
              onChange={() => setTabValue(DevelopersTerminalTabs.Config)}
            >
              <Tab
                value={DevelopersTerminalTabs.Config}
                label={<Typography>Config</Typography>}
              />
              <Tab
                value={DevelopersTerminalTabs.Messages}
                label={<Typography>Messages</Typography>}
              />
            </TabList>
            <TabPanel value={DevelopersTerminalTabs.Config}>
              <ConfigTab />
            </TabPanel>
            <TabPanel value={DevelopersTerminalTabs.Messages}>
              <MessagesTab />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Draggable>
  );
};

export default DevelopersTerminal;
