import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenShare } from '@mui/icons-material';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

import HomeBarButton from '../../HomeBarButton';
import WarningButtonBadge from '../../WarningButtonBadge';
import WebcamSharingButton from './WebcamSharing';
import SettingsButton from '../SettingsButton';

import { DolbyService, useConference } from '../../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../../providers';
import { useIsSpeakerMutedNotificationShown } from '../../DashboardContent/Dolby/hooks';
import { useModal } from '../../../../../hooks/modal';
import { useShowInitialMuteModal } from './hooks';
import { useNotification } from '../../../../../components/Notification';

import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';
import { getCatchErrorMessage } from '../../../../../helpers/error';

import { useStyles } from './styles';

const HomeBottomButtonOptions = () => {
  const classes = useStyles();

  const {
    microphones,
    isConferenceInitialized,
    isFirstPersonView,
    setIsFirstPersonView,
    disabledPersonView,
    isConferenceListener,
    screenMediaStreams,
  } = useConference();
  const { muted, setMuted, currentEvent } = useMytaverseEvent();

  const { currentParticipant } = useMytaverseEvent();
  const { speakerMutedNotification, resetSpeakerMutedNotification, showPulse } =
    useIsSpeakerMutedNotificationShown(isConferenceInitialized, muted);
  const { t: translate } = useTranslation('common');

  const initialMuteModalData = useModal();

  const { showNotification, getDolbyNotification } = useNotification();

  const currentParticipantId = currentParticipant?.id || '';
  const microDeviceId = microphones.length ? microphones[0].deviceId : '';

  const showDolbyShareScreen = !!currentParticipant?.isSpeaker;

  const sharingMedia = screenMediaStreams.length !== 0;

  const currentScreenSharing = !!screenMediaStreams.find(
    (s) => s.participantId === currentParticipantId,
  );

  useShowInitialMuteModal({
    eventId: currentEvent?.id || '',
    initialMuteModalData,
  });

  const handleMicroClick = () => {
    sendCustomSentryBreadcrumb({
      message: `${!muted ? 'mute' : 'unmute'} micro`,
    });
    setMuted();
    resetSpeakerMutedNotification(speakerMutedNotification);
  };

  const handleDolbyShareScreenClick = async () => {
    try {
      if (screenMediaStreams.length) {
        sendCustomSentryBreadcrumb({ message: 'Stop dolby share screen' });
        console.log('Stop dolby share screen');
        await DolbyService.stopShareScreen();
      } else {
        sendCustomSentryBreadcrumb({ message: 'Start dolby share screen' });
        console.log('Start dolby share screen');
        await DolbyService.startShareScreen();
      }
    } catch (error: unknown) {
      showNotification(getDolbyNotification());
      throw Error(getCatchErrorMessage(error));
    }
  };

  const handleSetPersonView = useCallback(
    () => setIsFirstPersonView(),
    [setIsFirstPersonView],
  );

  return (
    <>
      <WebcamSharingButton />

      <HomeBarButton
        label={translate('tooltips.toggleMic')}
        handleClick={handleMicroClick}
        showPulseAnimation={!!showPulse}
        disabled={!isConferenceInitialized || isConferenceListener}
        icon={
          <>
            {muted && !!microDeviceId ? (
              <WarningButtonBadge
                classes={{
                  root: classes.badge(microDeviceId),
                  tooltip: classes.badgeMic(microDeviceId),
                }}
              >
                <MicOffIcon />
              </WarningButtonBadge>
            ) : (
              <MicIcon />
            )}
          </>
        }
      />

      {showDolbyShareScreen && (
        <HomeBarButton
          label="Share Screen"
          icon={
            <ScreenShare
              sx={classes.stop(sharingMedia && currentScreenSharing)}
            />
          }
          disabled={sharingMedia && !currentScreenSharing}
          handleClick={handleDolbyShareScreenClick}
        />
      )}

      <HomeBarButton
        label="Toggle Person View"
        icon={isFirstPersonView ? <PersonIcon /> : <VisibilityIcon />}
        disabled={disabledPersonView}
        handleClick={handleSetPersonView}
      />

      <SettingsButton />
    </>
  );
};

export default HomeBottomButtonOptions;
