import { Box, styled, useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const StepBox = styled(Box)(() => {
  const { breakpoints } = useTheme();

  return {
    position: 'relative',
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 36,
    },
    [breakpoints.down(ScreenSizes.PcLarge)]: {
      width: 36,
      height: 36,
      '& svg': {
        fontSize: 22,
      },
    },
  };
});

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    completed: {
      background: appTheme.mainColor,
      color: colors.white,
    },
    loading: {
      color: appTheme.mainColor,
    },
    default: {
      background: colors.oxford_10,
      color: colors.oxford_30,
    },
    circle: {
      position: 'relative',
      width: 56,
      height: 56,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fontSize: 36,
      },
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        width: 36,
        height: 36,
        '& svg': {
          fontSize: 22,
        },
      },
    },
    step: {
      fontWeight: 600,
      fontSize: 20,
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 16,
      },
    },
  };
};
