import { useTheme } from '@mui/material';

import { colors } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    selectionContainer: {
      width: '100%',
      position: 'relative',
      marginTop: '60px',
      marginBottom: '16px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: 4,
      },
    },
    selection: {
      position: 'absolute',
      content: '""',
      inset: 0,
      padding: '16px 24px',
      '& .MuiInputBase-root': {
        width: 'calc(100% - 24px - 8px)',
      },
    },
    helper: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.oxford_40,
      marginBottom: '72px',
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
        marginBottom: 4.5,
      },
    },
  };
};
