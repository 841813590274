import { IAvatar } from './avatar';
import { IMap } from './map';
import { IParticipant } from './participants';
import { IRoom } from './rooms';
import { IStreamService } from './streamService';
import { IMillicastEventTokens } from './millicastTokens';
import { WsConnectionClientType } from './index';

export enum EventRole {
  Admin = 'ADMIN',
  Moderator = 'MODERATOR',
  Coordinator = 'COORDINATOR',
  None = '',
}

export interface IEvent {
  customUrl: string;
  endTime: number;
  id: string;
  image: string;
  name: string;
  startTime: number;
  streamServiceId: string;
  user: string;
  logoImage: string;
  agendaImage: string;
  participants: IParticipant[];
  eventAvatars: IAvatar[];
  rooms: IRoom[];
  maps: IMap[];
  streamService?: IStreamService;
  dolbyConferenceId: string;
  region?: string;
  lastJoinTime?: number;
  role: EventRole;
  loadingAsset?: string;
}

export interface IEventTokens {
  dolbyToken: string;
  streamChatToken: string;
  millicastTokens: IMillicastEventTokens;
}

export enum FollowingStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
}

export interface IFollower {
  following: string; // adminId
  eventId: string;
  participantId: string;
  clientType: WsConnectionClientType;
  followingStatus: FollowingStatus;
}

export interface IFollowingDataResponse {
  followers: IFollower[];
  groupLeads: string[];
}
