import React, { useCallback, useEffect, useRef } from 'react';

import { Box, SwipeableDrawer } from '@mui/material';

import EventDrawerHeader from './EventDrawerHeader';
import EventDrawerContent from './EventDrawerContent';

import { IEventDrawerView } from './interfaces';

import { useStyles } from './styles';
import { useMytaverseEvent } from '../../providers';
import { EventDrawerTabs } from '../../constants';

const EventDrawerView = ({
  isOpen,
  onOpenDrawer,
  onCloseDrawer,
}: IEventDrawerView) => {
  const classes = useStyles();

  const { leftMenuTab, leftMenuScrollHandler, isOpenLeftMenu } =
    useMytaverseEvent();
  const prevElementHeight = useRef(0);
  const participantPage = useRef(1);

  const handleScrollMenu = useCallback(() => {
    if (
      leftMenuTab !== EventDrawerTabs.Participants ||
      !leftMenuScrollHandler
    ) {
      return null;
    }

    const element = document.getElementById('participantsList');
    const rect = element?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    const distanceFromBottom = window.innerHeight - rect.bottom;

    if (!element) {
      return;
    }

    if (
      distanceFromBottom > 0 &&
      prevElementHeight.current < element.clientHeight
    ) {
      prevElementHeight.current = element.clientHeight;
      participantPage.current++;
      leftMenuScrollHandler(participantPage.current);
    }
  }, [leftMenuScrollHandler, leftMenuTab]);

  useEffect(() => {
    if (leftMenuTab !== EventDrawerTabs.Participants || !isOpenLeftMenu) {
      prevElementHeight.current = 0;
      participantPage.current = 1;
    }
  }, [leftMenuTab, isOpenLeftMenu]);

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onClose={onCloseDrawer}
      onOpen={onOpenDrawer}
      sx={classes.root}
    >
      <Box sx={classes.content} component="div">
        <Box sx={classes.backdrop} component="div">
          <Box sx={classes.main} onScroll={handleScrollMenu} component="div">
            <EventDrawerHeader />
            <EventDrawerContent />
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default EventDrawerView;
