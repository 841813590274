import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Hidden } from '@mui/material';

import EventTabs from '../EventTabs';
import LoadingProgress from '../../../../../components/LoadingProgress';
import RoomsTab from './RoomsTab';
import ParticipantsTab from './ParticipantsTab';

import { checkIsLocationsTab, checkIsParticipantsTab } from '../helpers';

import { IEventDrawerContentView } from './interfaces';

import { useStyles } from './styles';

const EventDrawerContentView = ({
  leftMenuTab,
  loading,
  rooms,
  participants,
  t: translate,
}: IEventDrawerContentView) => {
  const classes = useStyles();

  const participantOlineCount = participants.filter(
    ({ roomId }) => roomId,
  ).length;

  return (
    <>
      <Box sx={classes.root} component="div">
        <EventTabs />
        <Typography sx={classes.totalText}>
          {`${translate('eventDrawer.total')}: ${
            participants.length
          } - ${translate('eventDrawer.online')}: ${participantOlineCount}`}
        </Typography>
      </Box>
      {loading ? (
        <LoadingProgress />
      ) : (
        <>
          {checkIsLocationsTab(leftMenuTab) &&
            (rooms.length ? (
              <RoomsTab rooms={rooms} participants={participants} />
            ) : (
              <Typography sx={classes.emptyText}>
                You have no active rooms
              </Typography>
            ))}
          {checkIsParticipantsTab(leftMenuTab) &&
            (participants.length ? (
              <ParticipantsTab participants={participants} />
            ) : (
              <Typography sx={classes.emptyText}>
                You have no participants
              </Typography>
            ))}
        </>
      )}
      {/*TODO styles should be checked one more time*/}
      {/*{checkIsParticipantsTab(leftMenuTab) && (*/}
      {/*  <Button sx={classes.buttonLink}>*/}
      {/*    <LinkIcon sx={classes.iconLink} />*/}
      {/*    Copy Invite Link*/}
      {/*  </Button>*/}
      {/*)}*/}
    </>
  );
};

export default withTranslation('common')(EventDrawerContentView);
