import React from 'react';
import { Box, IconButton, Stack, Toolbar } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import AppLogo from '../../../../../components/AppLogo';
import DashboardBarButton from './DashboardBarButton';
import ParticipantsButton from './ParticipantsButton';
import FullScreenButton from './FullScreenButton';
import UserAvatarButton from '../../../../../components/UserAvatarButton';
import AdminTools from './AdminTools';
import { COLORS } from '../../../../../constants/colors';

import { EventDrawerTabs } from '../../../constants';

import { IDashboardTopBarContentView } from './interfaces';

import { useStyles } from './styles';

const DashboardTopBarContentView = ({
  currentRoom,
  isOpenChat,
  hasMediaStream,
  showInviteUserBtn,
  participantTotal,
  onOpenEventDrawer,
  onOpenConfirmLogoutModal,
  onOpenInviteUserModal,
}: IDashboardTopBarContentView) => {
  const classes = useStyles({
    isOpenChat,
    hasMediaStream,
  });

  return (
    <Toolbar sx={classes.toolbar}>
      {currentRoom ? (
        <>
          <Box sx={classes.root} component="div">
            <DashboardBarButton
              buttonIcon={<LocationOnIcon />}
              onClick={() => onOpenEventDrawer(EventDrawerTabs.Locations)}
            >
              {currentRoom.name || 'Lobby'}
            </DashboardBarButton>
            <ParticipantsButton
              onClick={() => onOpenEventDrawer(EventDrawerTabs.Participants)}
              total={participantTotal}
            />
          </Box>
          <IconButton
            sx={classes.iconButton}
            onClick={() => onOpenEventDrawer(EventDrawerTabs.Locations)}
          >
            <MenuIcon />
          </IconButton>
        </>
      ) : (
        <Box sx={classes.rootLoading}>
          <Stack flexDirection="row" alignItems="center">
            {window.location.hostname.includes('troovrs') ? null : (
              <AppLogo color={COLORS.WHITE} />
            )}
          </Stack>
        </Box>
      )}
      <Stack sx={classes.stack} direction="row">
        <FullScreenButton />
        {showInviteUserBtn ? (
          <DashboardBarButton
            onClick={onOpenInviteUserModal}
            buttonIcon={<PersonAddIcon sx={classes.addPersonIcon} />}
            sx={classes.inviteUserBtn}
          />
        ) : null}
        {showInviteUserBtn ? <AdminTools /> : null}
        <UserAvatarButton />
        <DashboardBarButton
          onClick={onOpenConfirmLogoutModal}
          buttonIcon={<LogoutIcon sx={classes.logoutIcon} />}
        />
      </Stack>
    </Toolbar>
  );
};

export default DashboardTopBarContentView;
