import { useTheme } from '@mui/material';

import { colors } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  const { appTheme } = useMytaverse();

  return {
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundImage: `url(${appTheme.playBackground})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      zIndex: 6,
    },

    content: {
      position: 'absolute',
      color: colors.oxford,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      zIndex: 11,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        gap: 3,
      },

      '& p': {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: '48px',
        textAlign: 'center',
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          fontSize: 22,
        },
      },

      '& button': {
        width: '100%',
        maxWidth: 280,
        height: 56,
        background: colors.white,
        borderRadius: 72,
        color: colors.oxford,
        textTransform: 'capitalize',
        fontSize: 20,
        letterSpacing: 0.1,
        fontWeight: 600,

        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          fontSize: 16,
          height: 'auto',
        },

        '&:hover': {
          background: colors.white_80,
        },
      },
    },

    check: {
      width: 64,
      height: 64,
      color: appTheme.mainColor,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        width: 42,
        height: 42,
      },
    },
  };
};
