import { useTheme } from '@mui/material';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { COLORS } from '../../../../constants/colors';
import { IStyles } from '../../../../interfaces/styles';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = (): IStyles => {
  const { appTheme } = useMytaverse();
  const { breakpoints } = useTheme();

  const {
    buttonTextColor,
    submitButton: { backgroundColor },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
    },
    prevAvatarButtons: {
      flexDirection: 'row',
      columnGap: 2,
      '& button': {
        textTransform: 'capitalize',
        mb: 5,
        border: `1px solid ${backgroundColor}`,
        borderRadius: 2,
        width: 200,
        height: 45,
        color: buttonTextColor,
        fontSize: 16,
        '&:hover': {
          color: COLORS.WHITE,
          background: backgroundColor,
        },
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        columnGap: 2,
        '& button': {
          width: 150,
          fontSize: 14,
        },
      },
      '& a': {
        textDecoration: 'none',
      },
    },
  };
};
