/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';

import {
  UseFullscreenType,
  DocumentWithFullscreen,
  DocumentElementWithFullscreen,
} from './interfaces';

export const useFullscreen: UseFullscreenType = (
  target = document.documentElement as any,
) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const checkFullScreen = useCallback(() => {
    const doc = document as DocumentWithFullscreen;
    return !!(
      doc.fullscreenElement ||
      doc.mozFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.msFullscreenElement
    );
  }, []);

  useEffect(() => {
    const fullscreenListener = () => {
      setIsFullscreen(checkFullScreen());
    };

    document.addEventListener('fullscreenchange', fullscreenListener);

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenListener);
    };
  }, []);

  const requestFullScreen = useCallback(
    (element: DocumentElementWithFullscreen) => {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      }
    },
    [],
  );

  const exitFullscreen = useCallback((doc: DocumentWithFullscreen) => {
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    }
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (!checkFullScreen() && isFullscreen) {
      setIsFullscreen(false);
    }

    if (!document.activeElement) return;

    if (isFullscreen) {
      exitFullscreen(document);
      return;
    }

    if (!isFullscreen) {
      requestFullScreen(target);
      return;
    }
  }, [
    exitFullscreen,
    requestFullScreen,
    checkFullScreen,
    isFullscreen,
    target,
  ]);

  return { toggleFullscreen, isFullscreen };
};
