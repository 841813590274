import React from 'react';

import { useTranslation } from 'react-i18next';

import { useSound } from '../../../../../../hooks/media';

import Sound from '../../../../../../public/sounds/test.mp3';
import { useConference } from '../../../DashboardContent/Dolby';
import DevicesSettingsSoundView from './DevicesSettingsSoundView';
import { useConferenceState } from '../../../../../../hooks/conferenceContext';

const DevicesSettingsSound = () => {
  const {
    microphones,
    speakers,
    selectMicrophone,
    selectSpeaker,
    changingDevice,
  } = useConference();
  const { activeSpeakerDeviceId, activeMicroDeviceId } = useConferenceState();

  const { t: translate } = useTranslation('common');

  const [play, { stop, isPlaying }] = useSound(Sound);

  return (
    <DevicesSettingsSoundView
      translate={translate}
      microphones={microphones}
      speakers={speakers}
      selectedMicrophone={activeMicroDeviceId}
      selectMicrophone={selectMicrophone}
      selectedSpeaker={activeSpeakerDeviceId}
      selectSpeaker={selectSpeaker}
      changingDevice={changingDevice}
      play={play}
      stop={stop}
      isTestingSound={isPlaying}
    />
  );
};

export default DevicesSettingsSound;
