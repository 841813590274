import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { Videocam } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import { useConference } from '../../../../DashboardContent/Dolby';
import { sendCustomSentryBreadcrumb } from '../../../../../../../helpers/sentry';
import { getCatchErrorMessage } from '../../../../../../../helpers/error';
import { useNotification } from '../../../../../../../components/Notification';
import { useMytaverseEvent } from '../../../../../providers';

import { IProps } from './interfaces';
import { useStyles } from './styles';

const DolbyShareWebcamButton = ({
  cameraDeviceId,
  onVideoClick,
  iconColorToggle,
  showWarningBadge,
}: IProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { t: translate } = useTranslation('common');

  const {
    ueWebcamScreenName,
    ueDolbyMediaStreams,
    isConferenceInitialized,
    isConferenceListener,
    startShareDolbyCamera,
    stopShareDolbyCamera,
    videoStarted,
  } = useConference();

  const { currentParticipant } = useMytaverseEvent();

  const { showNotification, getDolbyNotification } = useNotification();

  const currentParticipantId = currentParticipant?.id || '';

  const handleSharingButtonClick = React.useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.currentTarget.blur();

      if (cameraDeviceId) {
        setOpen((prevState) => !prevState);
      }
    },
    [cameraDeviceId],
  );

  const currentCameraSharing = React.useMemo(() => {
    return !!ueDolbyMediaStreams.find(
      (ms) =>
        ms.participantId === currentParticipantId &&
        ms.ueScreenName === ueWebcamScreenName &&
        ms.source === 'CAMERA',
    );
  }, [ueDolbyMediaStreams, ueWebcamScreenName, currentParticipantId]);

  const handleDolbyShareWebcamClick = React.useCallback(async () => {
    try {
      if (ueWebcamScreenName) {
        if (ueDolbyMediaStreams.length !== 0 && currentCameraSharing) {
          sendCustomSentryBreadcrumb({
            message: 'Stop dolby share webcam to UE',
          });
          console.log('Stop dolby share webcam to UE');
          stopShareDolbyCamera();
        } else {
          sendCustomSentryBreadcrumb({
            message: 'Start dolby share webcam to UE',
          });
          console.log('Start dolby share webcam to UE');
          startShareDolbyCamera();
        }
      }
    } catch (error: unknown) {
      showNotification(getDolbyNotification());
      throw Error(getCatchErrorMessage(error));
    }
  }, [
    ueWebcamScreenName,
    ueDolbyMediaStreams,
    currentCameraSharing,
    startShareDolbyCamera,
    stopShareDolbyCamera,
  ]);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.container(open)}>
        <Tooltip title={translate('tooltips.shareCam')} placement="top">
          <IconButton
            sx={classes.button}
            onClick={handleDolbyShareWebcamClick}
            disabled={ueDolbyMediaStreams.length !== 0 && !currentCameraSharing}
            disableRipple
          >
            <VideoCameraFrontIcon
              sx={iconColorToggle(
                ueDolbyMediaStreams.length !== 0 && currentCameraSharing,
              )}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate('tooltips.turnOnCam')}>
          <IconButton
            sx={classes.button}
            onClick={onVideoClick}
            disabled={!isConferenceInitialized || isConferenceListener}
            disableRipple
          >
            <Videocam
              sx={iconColorToggle(
                videoStarted && !!cameraDeviceId && !isConferenceListener,
              )}
            />
          </IconButton>
        </Tooltip>
        <Divider sx={classes.deliver} />
      </Box>

      <Tooltip
        title={translate('tooltips.toggleCam')}
        open={showTooltip && !open}
      >
        <IconButton
          onClick={handleSharingButtonClick}
          disableRipple
          sx={classes.mainButton}
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          {showWarningBadge(
            open ? (
              <CloseIcon sx={classes.close} />
            ) : (
              <Videocam
                sx={iconColorToggle(
                  videoStarted && !!cameraDeviceId && !isConferenceListener,
                )}
              />
            ),
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DolbyShareWebcamButton;
