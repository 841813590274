import React, { useEffect, useRef } from 'react';
import HomeBottomButtonOptionsCamView from './HomeBottomButtonOptionsCamView';
import useAsyncEffect from 'use-async-effect';
import MillicastService from '../ShareScreen/helpers';

const HomeBottomButtonOptionsCam = ({
  selectedCam,
}: {
  selectedCam: string;
}) => {
  const unmountedRef = useRef(false);

  useAsyncEffect(
    async () => {
      const constraints = {
        audio: false,
        video: { deviceId: { exact: selectedCam } },
      };

      const mediaStream = await navigator.mediaDevices.getUserMedia(
        constraints,
      );

      if (unmountedRef.current) {
        MillicastService.stopMediaStreamTracks(mediaStream);
        return;
      }

      const video: any = document.getElementById('videoElement');

      video.srcObject = mediaStream;
      video.onloadedmetadata = () => {
        video.play();
      };

      return mediaStream;
    },
    (mediaStream) => {
      if (mediaStream) {
        MillicastService.stopMediaStreamTracks(mediaStream);
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return <HomeBottomButtonOptionsCamView />;
};

export default HomeBottomButtonOptionsCam;
