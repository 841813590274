/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import React, { useEffect } from 'react';

import EventsService from '../../../../../../services/EventsService';
import { useMytaverseEvent } from '../../../../providers';
import { IMillicastPublisher } from '../../../../../../interfaces/millicastPublisher';
import MillicastScreenView from './MilicastScreenView';

import MillicastService from '../helpers';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../components/Notification';
import { getCatchErrorMessage } from '../../../../../../helpers/error';

const MillicastScreen = () => {
  const {
    currentEvent,
    shareMediaParams,
    shareScreenPublishers,
    setShareScreenPublishers,
    startShareScreen,
    stopShareScreen,
    shareScreenMediaStreams,
    initScreenMediaStream,
    handleStopScreenStream,
    closeShareScreenModal,
    currentRegion,
    loadingShareScreenModal,
    setLoadingShareScreenModal,
    getMillicastStreamTokens,
  } = useMytaverseEvent();

  const { showNotification, getMillicastNotification } = useNotification();

  const onShareScreenStreamClick = React.useCallback(
    async (streamId: string) => {
      setLoadingShareScreenModal(true);

      const isStreamExists = shareScreenPublishers?.find(
        (p) => p.options.mediaStream.id === streamId,
      );

      if (isStreamExists) {
        handleStopScreenStream(streamId);

        return;
      }

      if (currentEvent && shareMediaParams && shareScreenMediaStreams) {
        const streamName = EventsService.getMillicastStreamName(
          shareMediaParams.roomId,
          shareMediaParams.region || '',
          shareMediaParams.screenName,
        );

        let unlockScreen = false;

        try {
          const millicastTokens = await getMillicastStreamTokens(streamName);

          if (!millicastTokens) {
            throw new Error(`Can't load millicast tokens`);
          }

          const currentStream = shareScreenMediaStreams.find(
            (s) => s.id === streamId,
          );
          if (!currentStream) {
            return;
          }
          const publisher = (await MillicastService.broadcast(
            millicastTokens.publishingToken,
            millicastTokens.streamName,
            currentStream,
          )) as unknown as IMillicastPublisher;

          publisher.newPanel = true;
          publisher.region = currentRegion?.region as string;

          await startShareScreen(publisher, millicastTokens, false);
        } catch (error: unknown | Error) {
          unlockScreen = true;
          showNotification(
            getMillicastNotification({
              type: NOTIFICATION_TYPES.ERROR,
              message: `Can't initialize screen: ${getCatchErrorMessage(
                error,
              )}`,
            }),
          );
        }

        setLoadingShareScreenModal(false);
        await closeShareScreenModal(unlockScreen);
      }
    },
    [
      shareScreenPublishers,
      setShareScreenPublishers,
      startShareScreen,
      stopShareScreen,
      currentEvent,
      shareMediaParams,
      shareScreenMediaStreams,
      currentRegion,
      getMillicastStreamTokens,
      closeShareScreenModal,
    ],
  );

  return (
    <MillicastScreenView
      loading={loadingShareScreenModal}
      onShareScreenStreamClick={onShareScreenStreamClick}
      shareScreenPublishers={shareScreenPublishers}
      mediaStreams={shareScreenMediaStreams}
      handleStopStream={handleStopScreenStream}
      initMediaStream={initScreenMediaStream}
    />
  );
};

export default MillicastScreen;
