import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Button, Stack } from '@mui/material';

import SelectAvatarSkinItem from '../SelectAvatarSkinItem';
import Totem from '../Totem';
import Avaturn from '../Avaturn';

import { IPreviousSelectedAvatarView } from './interfaces';

import { useStyles } from './styles';

const PreviousSelectedAvatarView = ({
  customAvatarUrl,
  previousSkin,
  onChangeAvatar,
  onSelectAvatar,
  onSelectPrevAvatar,
}: IPreviousSelectedAvatarView) => {
  const classes = useStyles();

  let avatar;

  if (previousSkin) {
    avatar = (
      <SelectAvatarSkinItem
        src={previousSkin.fullBodyImage}
        alt="skin preview"
        onClick={onSelectAvatar}
      />
    );
  }

  if (!previousSkin && customAvatarUrl) {
    avatar = customAvatarUrl.includes('readyplayer') ? (
      <Totem onClick={onSelectPrevAvatar} />
    ) : (
      <Avaturn onClick={onSelectPrevAvatar} />
    );
  }

  return (
    <Box sx={classes.root}>
      <Stack sx={classes.prevAvatarButtons}>
        <Button onClick={onChangeAvatar}>Change Avatar</Button>
        <Button onClick={onSelectAvatar}>Continue</Button>
      </Stack>
      {avatar}
    </Box>
  );
};

export default withTranslation('common')(PreviousSelectedAvatarView);
