import React, { useCallback, useMemo } from 'react';

import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubble';

import ParticipantStatusIcon from '../../../../../../../components/ParticipantStatusIcon';
import FollowMeIcon from './FollowMeIcon';

import { useChatState } from '../../../../../../../hooks/context';
import { useConference } from '../../../../DashboardContent/Dolby';
import { useRedirectToPrivateChat } from '../../../../../../../hooks/channel';
import { useMytaverseEvent } from '../../../../../providers';
import { useMytaverse } from '../../../../../../../providers/MytaverseProvider';

import { sendCustomSentryBreadcrumb } from '../../../../../../../helpers/sentry';
import { checkIsAdminRole } from '../../../../../../../helpers/role';

import EventsService from '../../../../../../../services/EventsService';

import LocationOnIcon from '../../../../../../../public/images/pin.png';

import { ParticipantItemProps } from './interfaces';
import { WebsocketAction } from '../../../../../../../interfaces/webSocketConnectionInfo';

import { useStyles } from './styles';

const ParticipantItem = ({
  participant,
  currentParticipantId,
}: ParticipantItemProps) => {
  const classes = useStyles();
  const { setPreviewingParticipant } = useConference();
  const {
    currentEvent,
    currentParticipant,
    closeLeftMenu,
    teleportToParticipant,
    pendingFollowersData,
    acceptedFollowersData,
    isTeleporting,
  } = useMytaverseEvent();
  const { sendMessageToEvent } = useMytaverse();
  const { setOpen: setOpenChat } = useChatState();

  const isCurrentParticipant = participant.id === currentParticipantId;
  const isCurrentParticipantAdmin = currentEvent?.role
    ? checkIsAdminRole(currentEvent.role)
    : false;
  const isParticipantOnline = !!participant.roomId;

  const handleParticipantPreview = useCallback(async () => {
    const participantProfile = await EventsService.getParticipantProfile(
      participant.id,
    );
    setPreviewingParticipant(participantProfile);
  }, [participant, setPreviewingParticipant]);

  const redirectToPrivateChat = useRedirectToPrivateChat();

  const handleChatIconClick = useCallback(async () => {
    sendCustomSentryBreadcrumb({
      message: `got to private chat with userId=${participant.userId} from event drawer`,
    });
    closeLeftMenu();
    await redirectToPrivateChat(participant.userId);
    setOpenChat(true);
  }, [closeLeftMenu, participant, redirectToPrivateChat, setOpenChat]);

  const handleTeleportToParticipant = useCallback(async () => {
    closeLeftMenu();
    await teleportToParticipant(participant);
  }, [teleportToParticipant, participant, closeLeftMenu]);

  const disableHoverListener = useMemo(
    () =>
      window.innerWidth < 1615
        ? participant.fullName.length < 14
        : participant.fullName.length < 28,
    [participant],
  );

  const sendFollowMeMessageToEvent = (action: WebsocketAction) => {
    if (!currentEvent?.id) return;

    sendMessageToEvent(currentEvent.id, {
      action,
      followerId: participant.userId,
      ownerId: currentParticipantId,
      ownerName: currentParticipant?.fullName,
      ownerGameSessionId: currentParticipant?.gameSessionId,
      ownerRoomId: currentParticipant?.roomId,
    });
  };

  return (
    <Box sx={classes.root} component="div">
      <Box sx={classes.rootPosition} component="div">
        <Avatar src={participant.avatarImage} sx={classes.avatar}>
          {participant.avatarString}
        </Avatar>
        <Tooltip
          title={participant.fullName}
          disableHoverListener={disableHoverListener}
        >
          <Typography sx={classes.username} onClick={handleParticipantPreview}>
            {participant.fullName}
          </Typography>
        </Tooltip>
      </Box>

      <Box>
        <ParticipantStatusIcon
          isOnline={isParticipantOnline}
          className={isCurrentParticipant ? '' : 'onlineIndicator'}
          sx={classes.statusIcon}
        />
        {!isCurrentParticipant ? (
          <Box sx={classes.tools} className="helpers">
            {isCurrentParticipantAdmin && isParticipantOnline && (
              <FollowMeIcon
                participant={participant}
                pendingFollowersData={pendingFollowersData}
                acceptedFollowersData={acceptedFollowersData}
                currentParticipantId={currentParticipantId}
                sendFollowMeMessageToEvent={sendFollowMeMessageToEvent}
                sx={classes.iconButton}
              />
            )}
            <IconButton sx={classes.iconButton} onClick={handleChatIconClick}>
              <ChatBubbleOutlineOutlinedIcon sx={classes.icon} />
            </IconButton>
            <IconButton
              sx={classes.iconButton}
              disabled={isTeleporting}
              onClick={handleTeleportToParticipant}
            >
              <img src={LocationOnIcon} alt="" />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ParticipantItem;
