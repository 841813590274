import { RefObject, useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import useAsyncEffect from 'use-async-effect/';

import {
  getErrorNotification,
  NOTIFICATION_TYPES,
  useNotification,
} from '../components/Notification';
import { calculateInternetSpeed, getIsLowInternetSpeed } from '../helpers';
import { getNotification } from '../components/Notification/helpers';

import { sendCustomSentryBreadcrumb } from '../helpers/sentry';

import TestImage from '../public/images/speed_test_image.jpg';
import { useMytaverseEvent } from '../modules/dashboard/providers';
import { StreamingProviders } from '../modules/dashboard/providers/MytaverseEventProvider/interfaces';
import StreamService from '../services/StreamService';

export const usePreventGameKeydownListening = <T extends HTMLElement>(
  ref?: RefObject<T | undefined>,
  customCallback?: (event: Event) => void,
): RefObject<T | undefined> => {
  const elementRef = useRef<T | undefined>();

  useEffect(() => {
    setTimeout(() => {
      const element = ref?.current || elementRef.current;

      const callback = (event: Event) => {
        if ((event as KeyboardEvent).key !== 'Escape') {
          if (customCallback) return customCallback(event);

          event.stopPropagation();
        }
      };

      if (element) {
        element.addEventListener('keydown', callback);

        return () => {
          element.removeEventListener('keydown', callback);
        };
      }
    });
  }, [customCallback]);

  return elementRef;
};

export const useDataSwitcher = <T>(data: T[], time: number): T => {
  const [counter, setCounter] = useState(0);

  const dataLength = data.length;

  useEffect(() => {
    if (!dataLength) return;

    const interval = setInterval(() => {
      if (counter === dataLength - 1) {
        setCounter(0);
      } else {
        setCounter((prev) => prev + 1);
      }
    }, time);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [counter, dataLength]);

  return data[counter];
};

const useOnlineStatus = () => {
  const [online, setOnline] = useState(true);

  const checkOnlineStatus = useCallback(async () => {
    try {
      const online = await StreamService.awsPing();

      return online.status >= 200 && online.status < 300;
    } catch (error: unknown | AxiosError | Error) {
      if (axios.isAxiosError(error) && error.code === AxiosError.ERR_NETWORK) {
        return false;
      }

      return true;
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const result = await checkOnlineStatus();
      setOnline(result);
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkOnlineStatus]);

  return online;
};

export const useNoNetworkConnection = () => {
  const isOnline = useOnlineStatus();
  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  useEffect(() => {
    if (isOnline) {
      return;
    }

    showNotification(
      getErrorNotification({
        message: translate('notifications.noInternet'),
        withReloadButton: true,
        closeManually: true,
      }),
    );
    sendCustomSentryBreadcrumb({ message: 'No internet connection' });
  }, [isOnline]);
};

const useCheckInternetConnectionSpeed = () => {
  const intervalTimer = useRef<NodeJS.Timer | null>(null);
  const latencyIntervalTimer = useRef<NodeJS.Timer | null>(null);

  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  const { streamingProvider, gameCastStreamRegion } = useMytaverseEvent();

  useEffect(() => {
    const timerId = intervalTimer.current;
    const notificationData = getNotification({
      message: translate('notifications.unstableInternet'),
      type: NOTIFICATION_TYPES.WARNING,
    });

    const startSpeedChecking = () => {
      const startTime = new Date().getTime();
      const image = new Image();

      image.src = TestImage + '?nnn=' + startTime;
      image.onload = function () {
        const endTime = new Date().getTime();
        const speed = calculateInternetSpeed({ startTime, endTime });
        const isLowInternetSpeed = getIsLowInternetSpeed(speed);
        const message = `Internet connection speed is ${speed} Mbps`;

        if (isLowInternetSpeed) {
          showNotification({ ...notificationData, logMessage: message });
          sendCustomSentryBreadcrumb({ message });
        }
      };
    };

    if (!timerId) {
      startSpeedChecking();
      intervalTimer.current = setInterval(startSpeedChecking, 20000);
    }

    return () => {
      if (!timerId) return;

      clearInterval(timerId);
      intervalTimer.current = null;
    };
  }, [showNotification, translate]);

  useAsyncEffect(async () => {
    if (
      streamingProvider === StreamingProviders.Pureweb ||
      !gameCastStreamRegion ||
      latencyIntervalTimer.current
    ) {
      return;
    }

    latencyIntervalTimer.current = setInterval(async () => {
      const serviceLatency = await StreamService.checkServiceLatency(
        gameCastStreamRegion,
      );
      if (serviceLatency && serviceLatency > 100) {
        const notificationData = getNotification({
          message: translate('notifications.hugeAwsLatency'),
          type: NOTIFICATION_TYPES.WARNING,
          logMessage: `AWS Service latency is ${serviceLatency} ms`,
        });

        showNotification(notificationData);
      }
    }, 30000);

    return () => {
      if (!latencyIntervalTimer.current) {
        return;
      }
      clearInterval(latencyIntervalTimer.current);
    };
  }, [streamingProvider, gameCastStreamRegion]);
};
