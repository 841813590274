import { useMytaverseEvent } from '../../../../providers';
import { useGameCast } from '../GameCastProvider';

export const useGameCastProgressBar = () => {
  const { initMessageSended, currentRoom } = useMytaverseEvent();
  const { isStreamLoaded } = useGameCast();

  const secondStepLoading = !initMessageSended;
  const secondStepCompleted = initMessageSended;
  const thirdStepLoading = secondStepCompleted && !isStreamLoaded;
  const thirdStepCompleted = secondStepCompleted && isStreamLoaded;
  const forthStepLoading = thirdStepCompleted && !currentRoom;
  const forthStepCompleted = thirdStepCompleted && !!currentRoom;

  return {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
  };
};
