import { COLORS, colors } from '../../../../../constants/colors';

export const useStyles = (isOpenChat: boolean) => ({
  root: { height: '100%' },
  fullscreen: {
    display: 'none',
    position: 'absolute',
    top: 180,
    right: 50,
    zIndex: isOpenChat ? 1323 : 343443434334433,
    '& svg': {
      color: colors.myta,
    },
  },
  fullscreenCollapse: {
    display: 'none',
    position: 'absolute',
    top: 180,
    right: 20,
    zIndex: isOpenChat ? 1323 : 343443434334433,
    '& svg': {
      color: colors.myta,
    },
  },
  loading: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: 50,
    color: COLORS.MYTAVERSE_BLUE,
    fontSize: 22,
  },
  loadingServer: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: 100,
    color: COLORS.GREY,
    fontSize: 22,
  },
});
