import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Hidden } from '@mui/material';

import { useMytaverseEvent } from '../../../../providers';

import LoadingProgress from '../../../../../../components/LoadingProgress';
import SearchField from '../../../SearchField';
import ParticipantList from './ParticipantList';

import { ParticipantsTabViewProps } from './interfaces';

import { useStyles } from './styles';

const ParticipantsTabView = ({
  inputValue,
  onInputChange,
  onInputClear,
  activeRooms,
  currentParticipantId,
}: ParticipantsTabViewProps) => {
  const classes = useStyles();
  const { setLeftMenuScrollHandler } = useMytaverseEvent();
  const [page, setPage] = useState(1);

  const participantsList = useMemo(
    () =>
      activeRooms.slice().map((r) => {
        if (r.id === 'offline') {
          return {
            ...r,
            activeParticipants: r.activeParticipants.slice(0, 20 * page),
          };
        }

        return r;
      }),
    [activeRooms, page],
  );

  const handler = useCallback(
    (page: number) => {
      const offlineParticipants = activeRooms
        .find((r) => r.id === 'offline')
        ?.activeParticipants.slice(0, 20 * page);
      setPage(page);
      const elementIndex = participantsList.findIndex(
        (r) => r.id === 'offline',
      );

      if (!offlineParticipants) {
        return;
      }
      participantsList[elementIndex].activeParticipants = offlineParticipants;
    },
    [activeRooms, participantsList],
  );

  useEffect(() => {
    setLeftMenuScrollHandler(() => handler);

    return () => {
      setLeftMenuScrollHandler(null);
    };
  }, [handler, setLeftMenuScrollHandler]);

  return (
    <Box sx={classes.root} component="div">
      <Box component="div">
        <Hidden mdDown>
          <SearchField
            onChange={onInputChange}
            onClear={onInputClear}
            value={inputValue}
            sx={classes.input}
          />
        </Hidden>
        <Box id="participantsList" component="div">
          {participantsList.map((room) => (
            <ParticipantList
              key={room.id}
              name={room.name}
              participants={room.activeParticipants}
              isCollapsible={room.id === 'offline'}
              currentParticipantId={currentParticipantId}
            />
          )) || <LoadingProgress />}
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipantsTabView;
