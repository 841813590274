import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';

import { camelCase } from 'lodash';

import { useSound } from '../../../../../hooks/media';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import {
  getErrorNotification,
  useNotification,
} from '../../../../../components/Notification';
import { NOTIFICATION_TYPES } from '../../../../../components/Notification/constants';
import ROUTES from '../../../../../constants/routes';

import { DolbyService, useConference } from '../Dolby';
import { useHandleUEConnection } from './ueWsConnection';

import { setSessionStorageValue } from '../../../../../helpers/sessionStorage';

import EventsService from '../../../../../services/EventsService';

import FollowMeSound from '../../../../../public/sounds/followMe.mp3';

import { MytaverseLogger } from '../../../../../helpers/logger';

import {
  IParticipantPositionMessage,
  IWebSocketConnectionInfo,
  LastWebsocketJsonMessageType,
  SpatialType,
  WebsocketAction,
  WebsocketMessage,
} from '../../../../../interfaces/webSocketConnectionInfo';
import { SendToBriefcasePropsTypeFull } from '../interfaces';
import { SessionStorage } from '../../../../../constants/storage';
import { IFollowerData } from '../../../../../interfaces/followers';
import { UnknownObjectType } from '../../../../../interfaces';
import { useNavigate } from 'react-router-dom';

//import { stopCommunicationFromUeTimer } from '../../../../../helpers/websocket';

interface HandleJoinedToRoomProps {
  roomId: string | null;
  participantId: string;
  gameSessionId: string | null;
  region?: string | null;
}

interface HandleLeftRoomProps {
  participantId: string;
}

const {
  ConnectionInfo,
  JoinedToRoom,
  LeftRoom,
  JoinedToEvent,
  LeftEvent,
  JoinedToRegion,
  LeftRegion,
  ChangeRoomScale,
  BrowserUrlRequest,
  MuteParticipant,
  ShowParticipantInfo,
  SendToBriefcase,
  ChangeParticipantProfile,
  MessageToWebapp,
  Ue5WsConnected,
  Ue5WsDisconnected,
  FirstPersonViewRegion,
  FreePersonViewRegion,
  MuteConference,
  ParticipantPosition,
  StopDolbyScreenSharing,
  FollowPlayer,
  InviteFollowingByAdmin,
  StopFollowingByAdmin,
  CancelInviteFollowingByAdmin,
  AcceptFollowing,
  DisengageFollowing,
  StopFollowing,
  OpenTeleportModal,
  CloseTeleportModal,
  ShowNotification,
  ShowDemoNotification,
  TeleportFinished,
  OwnerTeleport,
  TerminateSession,
} = WebsocketAction;

const { ShowControls, HideControls } = WebsocketMessage;

export const useGetWebsocketMsg = () => {
  const prevWebsocketMsgStrRef = useRef('');

  const getWebsocketMsg = (
    websocketMsg: LastWebsocketJsonMessageType,
  ): LastWebsocketJsonMessageType => {
    const websocketMsgStr = JSON.stringify(websocketMsg);

    if (prevWebsocketMsgStrRef.current === websocketMsgStr) {
      return null;
    }

    prevWebsocketMsgStrRef.current = websocketMsgStr;

    return websocketMsg;
  };

  return getWebsocketMsg;
};

// TODO should be split
export const useHandleWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const loadingScreenStartTimeRef = useRef<number | null>();

  const { setWebsocketConnectionInfo, sendJSONMessageToWebSocket } =
    useMytaverse();
  const { handleDolbyWSMessages } = useConference();

  const {
    updateParticipant,
    setParticipantState,
    pointsOfInterest,
    setPoiPreviewSrc,
    userFiles,
    setUserFiles,
    setShowControls,
    trackAnalytics,
    shareScreenPublishers,
    shareVideoPublishers,
    currentParticipant,
    setMuted,
    muted,
    setRoomDolbySpatialAudioScale,
    initMessageSended,
    currentRegion,
    setIsTeleporting,
    handleMillicastWSMessage,
    setShowExitButton,
  } = useMytaverseEvent();
  const {
    setPreviewingParticipant,
    setDisabledPersonView,
    setParticipantPosition,
    setScreenMediaStreams,
    screenMediaStreams,
    setUEWebcamScreenName,
  } = useConference();
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  const { handleUe5WsConnected, handleUe5WsDisconnected } =
    useHandleUEConnection();

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      !currentParticipant.roomId &&
      !loadingScreenStartTimeRef.current
    ) {
      await trackAnalytics('LOADING_SCREEN_START', {});
      loadingScreenStartTimeRef.current = new Date().getTime();
    }
  }, [trackAnalytics, currentParticipant]);

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      currentParticipant.roomId &&
      loadingScreenStartTimeRef.current
    ) {
      await trackAnalytics('LOADING_SCREEN_FINISH', {
        duration: new Date().getTime() - loadingScreenStartTimeRef.current,
      });
      loadingScreenStartTimeRef.current = null;
    }
  }, [currentParticipant, trackAnalytics]);

  const handleJoinedToRoom = useCallback(
    ({ roomId, participantId, gameSessionId }: HandleJoinedToRoomProps) => {
      if (
        !roomId ||
        !participantId ||
        !initMessageSended ||
        (currentParticipant && currentParticipant.id === participantId)
      ) {
        if (currentRegion) {
          sendJSONMessageToWebSocket({
            action: WebsocketAction.JoinedToRegion,
            timestamp: Date.now(),
            participantId: participantId,
            region: currentRegion.region,
          });
        }
        return;
      }

      MytaverseLogger.log(
        `Participant ${participantId} joined to room ${roomId}`,
      );

      setParticipantState(participantId, {
        roomId,
        region: currentRegion || null,
        regions: [],
        gameSessionId,
        isDemoUser: currentParticipant?.isDemoUser,
      });
    },
    [setParticipantState, currentParticipant, initMessageSended, currentRegion],
  );

  const handleLeftRoom = useCallback(
    ({ participantId }: HandleLeftRoomProps) => {
      if (!participantId) {
        return;
      }

      MytaverseLogger.log(`Participant ${participantId} left to room`);

      setParticipantState(participantId, {
        roomId: null,
        region: null,
        regions: [],
        gameSessionId: null,
      });
    },
    [setParticipantState],
  );

  const handleTeleportFinished = useCallback(() => {
    setTimeout(() => {
      //e5WebsocketConnected with timeout
      setIsTeleporting(false);
    }, 3000);
  }, [setIsTeleporting]);

  const handleOwnerTeleport = useCallback(
    (ownerId: string, followerIds: string[]) => {
      if (!currentParticipant || ownerId === currentParticipant.id) return;
      if (followerIds.indexOf(currentParticipant.id) === -1) return;

      MytaverseLogger.log(`Teleport ownerId: ${ownerId}`);
      setIsTeleporting(true);
    },
    [currentParticipant, setIsTeleporting],
  );

  const handleStopDolbySharing = useCallback(
    (mediaStreamId: string) => {
      setScreenMediaStreams((prev) =>
        prev.filter((s) => s.stream.id !== mediaStreamId),
      );
    },
    [setScreenMediaStreams, screenMediaStreams],
  );

  const handleShowParticipantInfo = useCallback(
    async (participantId = '') => {
      if (!participantId) {
        return;
      }

      const participantProfile = await EventsService.getParticipantProfile(
        participantId,
      );
      if (participantProfile) {
        setPreviewingParticipant(participantProfile);
      }
    },
    [setPreviewingParticipant],
  );

  const handleSendToBriefcase = useCallback(
    ({
      fileName,
      downloadUrl = '',
      mediaType,
    }: SendToBriefcasePropsTypeFull) => {
      if (!fileName || !pointsOfInterest) {
        return;
      }

      setPoiPreviewSrc({
        downloadUrl,
        fileName,
        mediaType,
      });

      const existingFile = userFiles.some(
        ({ displayName }) => displayName === fileName,
      );

      if (existingFile) {
        return;
      }

      const file = pointsOfInterest.find(
        ({ displayName }) => displayName === fileName,
      );

      if (file) {
        setUserFiles((prev) => Array.from(new Set([file, ...prev])));
      }
    },
    [pointsOfInterest, setPoiPreviewSrc, setUserFiles, userFiles],
  );

  const trackPoiClick = useCallback(
    (fileName: string) => {
      if (!fileName || !pointsOfInterest) {
        return;
      }

      const poi = pointsOfInterest.find(
        ({ displayName }) => displayName === fileName,
      );

      if (poi) {
        const { id, asset, displayImage, displayName, mediaType, room } = poi;

        trackAnalytics('POI_CLICK', {
          poi: { id, asset, displayImage, displayName, mediaType, room },
        }).then(() => {});
      }
    },
    [pointsOfInterest, trackAnalytics],
  );

  const handleParticipantPositionChanged = useCallback(
    ({
      timestamp,
      gameSessionId,
      eventId,
      roomId,
      x,
      y,
      z,
      r,
      regionName,
      regionSpatialType,
    }: IParticipantPositionMessage) => {
      if (!currentParticipant) {
        return;
      }

      const currentRegionName = currentParticipant.region
        ? currentParticipant.region.region
        : '';

      if (
        !currentParticipant.roomId ||
        currentParticipant.roomId !== roomId ||
        currentParticipant.gameSessionId !== gameSessionId ||
        currentRegionName !== regionName
      ) {
        setParticipantState(currentParticipant.id, {
          eventId,
          roomId,
          region: regionName
            ? {
                timestamp: Date.now(),
                region: regionName,
                state: 'joined',
                regionSpatialType:
                  regionSpatialType && regionSpatialType.length !== 0
                    ? regionSpatialType
                    : SpatialType.SpatialAudio,
              }
            : null,
          regions: [],
          gameSessionId,
        });
      }

      setParticipantPosition({
        roomId,
        timestamp,
        x: Math.trunc(x),
        y: Math.trunc(y),
        z: Math.trunc(z),
        r: Math.trunc(r),
      });
      setSessionStorageValue(SessionStorage.ParticipantPosition, {
        roomId,
        x,
        y,
        z,
        r,
      });
    },
    [currentParticipant, setParticipantPosition, setParticipantState],
  );

  const handleChangeParticipantProfile = useCallback(
    async (participantId: string) => {
      if (!participantId) {
        return;
      }

      const updatedParticipant = await EventsService.getParticipant(
        participantId,
      );

      if (updatedParticipant) {
        updateParticipant(participantId, updatedParticipant);
      }
    },
    [updateParticipant],
  );

  const handleMessageToWebapp = useCallback(
    (message: WebsocketMessage | undefined) => {
      if (message === HideControls) {
        setShowControls(false);
        return;
      }

      if (message === ShowControls) {
        setTimeout(() => {
          setShowControls(true);
        }, 8000);
        return;
      }
    },
    [setShowControls],
  );

  const handleMuteConference = useCallback(
    ({ conferenceId, mutedBy }: { conferenceId: string; mutedBy: string }) => {
      const currentConferenceId = DolbyService.getConferenceId();
      if (
        conferenceId === currentConferenceId &&
        currentParticipant &&
        mutedBy !== currentParticipant.id &&
        !muted
      ) {
        setMuted(true);
        showNotification(
          getSuccessNotification({
            title: translate('notifications.mutedByModerator.title'),
            message: translate('notifications.mutedByModerator.muted'),
          }),
        );
      }
    },
    [currentParticipant, muted, setMuted, showNotification],
  );

  const handleFirstPersonViewRegion = useCallback(() => {
    setDisabledPersonView(true);
  }, [setDisabledPersonView]);

  const handleFreePersonViewRegion = useCallback(() => {
    setDisabledPersonView(false);
  }, [setDisabledPersonView]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const {
      action,
      timestamp,
      gameSessionId = '',
      eventId = '',
      roomId = '',
      x,
      y,
      z,
      r,
      data,
      message,
      participantId = '',
      fileName = '',
      downloadUrl = '',
      regionSpatialType = SpatialType.SpatialAudio,
      mediaType = '',
      conferenceId,
      mutedBy,
      mediaStreamId,
      playerPosition,
      dolbySpatialAudioScale,
    } = lastJsonMessage;

    let { region = '', regionName = '' } = lastJsonMessage;

    if (region) {
      region = camelCase(region);
    }

    if (regionName) {
      regionName = camelCase(regionName);
    }

    switch (action) {
      case ConnectionInfo: {
        setWebsocketConnectionInfo(data);
        break;
      }

      case JoinedToRoom: {
        handleJoinedToRoom({ roomId, participantId, region, gameSessionId });
        break;
      }

      case LeftRoom: {
        handleLeftRoom({
          participantId,
        });
        break;
      }

      case TeleportFinished: {
        handleTeleportFinished();
        break;
      }
      case OwnerTeleport: {
        const { ownerId, followerIds } = lastJsonMessage;
        if (ownerId && followerIds?.length) {
          handleOwnerTeleport(ownerId, followerIds);
        }
        break;
      }
      case ParticipantPosition: {
        handleParticipantPositionChanged({
          timestamp: timestamp ? parseInt(timestamp) : 0,
          gameSessionId: gameSessionId || playerPosition.CurrentGameSessionId,
          eventId: eventId || playerPosition.eventId,
          roomId: roomId || playerPosition.roomId,
          regionName,
          regionSpatialType,
          x: x || playerPosition.x,
          y: y || playerPosition.y,
          z: z || playerPosition.z,
          r: r || playerPosition.r,
        });

        setUEWebcamScreenName(playerPosition.dolbyWebcamScreenId || null);

        const { showExitButton = false } = lastJsonMessage;
        setShowExitButton(showExitButton);

        break;
      }
      case ChangeRoomScale: {
        if (roomId && dolbySpatialAudioScale) {
          setRoomDolbySpatialAudioScale(roomId, dolbySpatialAudioScale);
        }

        break;
      }
      case ShowParticipantInfo: {
        handleShowParticipantInfo(participantId);
        break;
      }
      case SendToBriefcase: {
        if (!mediaType) {
          return;
        }

        handleSendToBriefcase({ fileName, downloadUrl, mediaType });
        trackPoiClick(fileName);

        break;
      }
      case ChangeParticipantProfile: {
        handleChangeParticipantProfile(participantId);
        break;
      }
      case MessageToWebapp: {
        handleMessageToWebapp(message);
        break;
      }

      case Ue5WsConnected: {
        handleUe5WsConnected();
        break;
      }
      case Ue5WsDisconnected: {
        handleUe5WsDisconnected();
        break;
      }

      case FirstPersonViewRegion: {
        handleFirstPersonViewRegion();

        break;
      }

      case FreePersonViewRegion: {
        handleFreePersonViewRegion();
        break;
      }

      case MuteConference: {
        if (!conferenceId || !mutedBy) {
          return;
        }

        handleMuteConference({
          conferenceId,
          mutedBy,
        });
        break;
      }

      case StopDolbyScreenSharing: {
        handleStopDolbySharing(mediaStreamId as string);
        break;
      }

      default:
        break;
    }
  }, [
    lastJsonMessage,
    shareScreenPublishers,
    handleChangeParticipantProfile,
    handleJoinedToRoom,
    handleMessageToWebapp,
    handleSendToBriefcase,
    handleShowParticipantInfo,
    handleUe5WsConnected,
    handleUe5WsDisconnected,
    handleFirstPersonViewRegion,
    handleFreePersonViewRegion,
    setRoomDolbySpatialAudioScale,
    setWebsocketConnectionInfo,
    trackPoiClick,
    setParticipantState,
    handleMuteConference,
    shareVideoPublishers,
  ]);

  // MILLICAST WS handlers
  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    handleMillicastWSMessage(lastJsonMessage);
  }, [lastJsonMessage, handleMillicastWSMessage]);

  // Dolby WS handlers
  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    handleDolbyWSMessages(lastJsonMessage);
  }, [lastJsonMessage, handleDolbyWSMessages]);
};

export const useHandleEventWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { participants, setParticipantState } = useMytaverseEvent();

  const handleJoinedToEvent = useCallback(
    async ({ participantId, eventId }: IWebSocketConnectionInfo) => {
      //stopCommunicationFromUeTimer();
      setParticipantState(participantId, {
        eventId,
        roomId: null,
        region: null,
      });
    },
    [participants, setParticipantState],
  );

  const handleLeftToEvent = useCallback(
    (participantId = '') => {
      if (!participantId) {
        return;
      }

      setParticipantState(participantId, {
        eventId: null,
        roomId: null,
        region: null,
      });
    },
    [setParticipantState],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data, participantId = '' } = lastJsonMessage;

    switch (action) {
      case JoinedToEvent: {
        handleJoinedToEvent(data);
        break;
      }
      case LeftEvent: {
        handleLeftToEvent(participantId);
        break;
      }
      default:
        break;
    }
  }, [lastJsonMessage, handleJoinedToEvent, handleLeftToEvent]);
};

export const useHandleMuteParticipantWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const [showMutedByAdminNotification, setShowMutedByAdminNotification] =
    useState(false);

  const { setMuted } = useMytaverseEvent();
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const handleMuteParticipant = useCallback(
    (muted: boolean) => {
      if (showMutedByAdminNotification) {
        return;
      }

      setMuted(muted);
      setShowMutedByAdminNotification(true);
      showNotification(
        getSuccessNotification({
          title: translate('notifications.mutedByModerator.title'),
          message: translate(
            `notifications.mutedByModerator.${muted ? 'muted' : 'unmuted'}`,
          ),
          onClose: () => setShowMutedByAdminNotification(false),
        }),
      );
    },
    [
      getSuccessNotification,
      setMuted,
      showMutedByAdminNotification,
      showNotification,
      translate,
    ],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === MuteParticipant) {
      handleMuteParticipant(data?.muted || true);
    }
  }, [handleMuteParticipant, lastJsonMessage]);
};

export const useHandleBrowserUrlRequestWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const [openBrowserUrlDialog, setOpenBrowserUrlDialog] = useState(false);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    if (lastJsonMessage.action === BrowserUrlRequest) {
      setOpenBrowserUrlDialog(true);
    }
  }, [lastJsonMessage]);

  return {
    openBrowserUrlDialog,
    setOpenBrowserUrlDialog,
  };
};

export const useHandleJoinedToRegionWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { currentParticipantId, setParticipantState } = useMytaverseEvent();

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, timestamp, region, participantId } = lastJsonMessage;

    if (!region || !participantId || currentParticipantId === participantId) {
      return;
    }

    switch (action) {
      case JoinedToRegion:
        setParticipantState(participantId, {
          region: {
            timestamp: timestamp ? parseInt(timestamp, 10) : Date.now(),
            region,
            state: 'joined',
          },
        });
        break;

      case LeftRegion:
        setParticipantState(participantId, {
          region: null,
        });
        break;

      default:
        break;
    }
  }, [lastJsonMessage, currentParticipantId, setParticipantState]);
};

export const useHandleFollowMeWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const {
    currentEvent,
    currentParticipant,
    setPendingFollowersData,
    setAcceptedFollowersData,
  } = useMytaverseEvent();
  const { sendMessageToEvent } = useMytaverse();
  const [play] = useSound(FollowMeSound, {
    volume: 0.15,
  });

  useEffect(() => {
    if (!lastJsonMessage || !currentEvent?.id) {
      return;
    }

    const {
      action,
      followerId = '',
      ownerId = '',
      isEventMessage = false,
      message = '',
    } = lastJsonMessage;

    const followerData: IFollowerData = {
      userId: followerId,
      adminId: ownerId,
    };

    const redirectedActions = [
      AcceptFollowing,
      DisengageFollowing,
      StopFollowing,
    ];

    const redirectMessageToEvent = (
      message: UnknownObjectType<string, string>,
    ) => {
      sendMessageToEvent(currentEvent?.id || '', {
        ...message,
        isEventMessage: true,
      });
    };

    const handleMessage = async () => {
      if (redirectedActions.includes(action) && !isEventMessage) {
        redirectMessageToEvent({
          action,
          followerId,
          ownerId,
        });
        return;
      }

      switch (action) {
        case FollowPlayer: {
          if (followerData.userId === currentParticipant?.userId) {
            play();
          }

          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.sendFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
              groupLeadId: followerData.adminId,
            });
            await EventsService.acceptFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setAcceptedFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case InviteFollowingByAdmin: {
          if (followerData.userId === currentParticipant?.userId) {
            play();
          }

          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.sendFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
              groupLeadId: followerData.adminId,
            });
          }

          setPendingFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case StopFollowingByAdmin:
        case StopFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.declineFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setAcceptedFollowersData((prev) =>
            prev.filter(
              (data) =>
                !(
                  data.userId === followerData.userId &&
                  data.adminId === followerData.adminId
                ),
            ),
          );
          break;
        }
        case CancelInviteFollowingByAdmin:
        case DisengageFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.declineFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setPendingFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          break;
        }
        case AcceptFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.acceptFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setPendingFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          setAcceptedFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case ShowNotification: {
          showNotification(
            getErrorNotification({
              message: message,
              withReloadButton: true,
              closeManually: true,
            }),
          );
          break;
        }
        case ShowDemoNotification: {
          showNotification({
            message,
            type: NOTIFICATION_TYPES.WARNING,
            closeManually: true,
            withDismissButton: false,
          });
          break;
        }
        case TerminateSession: {
          navigate(ROUTES.LOGIN);
          break;
        }
      }
    };

    handleMessage();
  }, [
    lastJsonMessage,
    currentParticipant?.userId,
    currentEvent?.id,
    setPendingFollowersData,
    play,
    setAcceptedFollowersData,
    sendMessageToEvent,
  ]);
};

export const useHandleTeleportingModalWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { setIsTeleportingToRoomByUnreal } = useMytaverseEvent();

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action } = lastJsonMessage;

    const handleMessage = async () => {
      switch (action) {
        case OpenTeleportModal: {
          MytaverseLogger.log('Open teleport modal');
          setIsTeleportingToRoomByUnreal(true);
          break;
        }
        case CloseTeleportModal: {
          MytaverseLogger.log('Close teleport modal');
          setIsTeleportingToRoomByUnreal(false);
          break;
        }
      }
    };

    handleMessage();
  }, [lastJsonMessage, setIsTeleportingToRoomByUnreal]);
};
