import { colors, COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  avatar: {
    backgroundColor: COLORS.DARK_GREY,
    borderRadius: 72,
    m: 1,
    width: 56,
    height: 56,
    boxSizing: 'border-box',
  },
  stop: (currentSharing: boolean) => ({
    color: currentSharing ? `#EB3B67 !important` : `${colors.white} !important`,
  }),
  badge: (deviceId: boolean) => ({
    '& .MuiBadge-badge': {
      display: deviceId ? 'none' : 'flex',
      fontWeight: 'bold',
      right: -1,
    },
  }),
  badgeMic: (deviceId: boolean) => ({
    display: deviceId ? 'none' : 'flex',
  }),
  milicast: {
    p: 2,
    width: 400,
  },
});
