/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Config,
  Flags,
  MessageRecv,
  PixelStreaming,
  PixelStreamingApplicationStyle,
  SPSApplication,
  TextParameters,
  Logger,
} from '@tensorworks/libspsfrontend';

import { useCoreWeave } from './CoreWeaveProvider';
import { Box, IconButton } from '@mui/material';
import CoreWeaveLoader from './CoreWeaveLoader/CoreWeaveLoader';
import { useMytaverseEvent } from '../../../providers';
import PlayScreen, { PlayScreenLoading } from '../../PlayScreen';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useConference } from '../Dolby';
import { useStyles } from './styles';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';
import { getNotification } from '../../../../../components/Notification/helpers';

const PixelStreamingApplicationStyles = new PixelStreamingApplicationStyle({});

PixelStreamingApplicationStyles.applyStyleSheet();

class MessageExtendedConfig extends MessageRecv {
  peerConnectionOptions!: RTCConfiguration;
  engineVersion!: string;
  platform!: string;
  frontendToSendOffer!: boolean;
}

class ScalablePixelStreaming extends PixelStreaming {
  public handleOnConfig(messageExtendedConfig: MessageExtendedConfig) {
    console.log('Pixel streaming: Handle On Config Message');
    this._webRtcController.handleOnConfigMessage(messageExtendedConfig);
    this._webRtcController.streamMessageController.registerMessageHandler(
      0,
      'TextboxEntry',
      (message: unknown | Array<number>) => {
        this._webRtcController.sendMessageController.sendMessageToStreamer(
          'TextboxEntry',
          message as Array<number>,
        );
      },
    );
  }
  
}

export const PixelStreamingWrapper = () => {
  const videoParent = useRef<HTMLDivElement>(null);
  const {
    setPixelStreaming,
    webSocketAddress,
    coreweaveLoading,
    dataChannelOpened,
    playStream,
    togglePlayStream,
    isPlayButtonPressed,
  } = useCoreWeave();
  const { sendJSONMessageToWebSocket } = useMytaverse();
  const {
    currentRoom,
    ue5WebsocketConnected,
    sharingWindowFullsceen,
    setSharingWindowFullscreen,
    handleMinimizeSharingScreen,
    isOpenLeftMenu,
    isMinimizedScreenSharing,
  } = useMytaverseEvent();
  const { screenMediaStreams } = useConference();
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);
  const classes = useStyles(isOpenLeftMenu);
  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  useEffect(() => {
    if (!webSocketAddress) {
      return;
    }

    Logger.SetLoggerVerbosity(0.1);
    Logger.Error = (stack: string, message: string) => {
      if (message.includes('unhandled')) {
        return;
      }
      console.log(message);
    };

    Logger.Log = () => {};

    const config = new Config({
      useUrlParams: false,
      initialSettings: {
        OfferToReceive: true,
        TimeoutIfIdle: false,
        AutoConnect: true,
        MaxReconnectAttempts: 100,
        AutoPlayVideo: false,
        StartVideoMuted: false,
        HoveringMouse: true,
        AFKTimeout: 3600,
      },
    });

    if (webSocketAddress != '') {
      config.setTextSetting(
        TextParameters.SignallingServerUrl,
        webSocketAddress,
      );
    }

    try {
      console.log('Starting pixel streaming');
      const stream = new ScalablePixelStreaming(config);

      stream.webSocketController.onConfig = (
        messageExtendedConfig: MessageExtendedConfig,
      ) => {
        stream.config.setFlagEnabled(
          Flags.BrowserSendOffer,
          messageExtendedConfig.frontendToSendOffer,
        );

        stream.handleOnConfig(messageExtendedConfig);
      };

      const spsApplication = new SPSApplication({
        stream: stream,
      });

      spsApplication.uiFeaturesElement.remove();
      console.log('Pixel streaming: setPixelStreaming');
      setPixelStreaming(spsApplication.stream);
      console.log('Pixel streaming: setRootElement');
      setRootElement(spsApplication.rootElement);
    } catch (error) {
      console.error('Error setting up Pixel Streaming', error);
      showNotification(
        getNotification({
          message: translate('notifications.kickOutError'),
          type: NOTIFICATION_TYPES.ERROR,
          withReloadButton: true,
          closeManually: true,
        }),
      );
    }


  }, [webSocketAddress]);

  useEffect(() => {
    if (
      coreweaveLoading ||
      !videoParent.current ||
      !rootElement ||
      !dataChannelOpened ||
      !playStream
    ) {
      return;
    }

    videoParent.current.appendChild(rootElement);
  }, [
    coreweaveLoading,
    dataChannelOpened,
    rootElement,
    videoParent,
    playStream,
  ]);

  React.useEffect(() => {
    if (currentRoom) {
      sendJSONMessageToWebSocket({
        action: 'CHANGE_SCREEN_RESOLUTION',
        width: 1980,
        height: 1080,
      });
    }
  }, [currentRoom]);

  React.useEffect(() => {
    if (playStream) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [playStream]);

  if (coreweaveLoading && !currentRoom) {
    return <CoreWeaveLoader />;
  }

  return currentRoom ? (
    <>
      <Box
        id="coreweaveWrapper"
        sx={{
          width: '100vw',
          height: '100vh',
          position: 'relative',
          zIndex: playStream ? 10 : -10,
          display: playStream ? 'block' : 'hidden',
        }}
        ref={videoParent}
      ></Box>
      {screenMediaStreams.length && !isMinimizedScreenSharing ? (
        <>
          <IconButton
            sx={classes.fullscreen}
            id="gameFull"
            onClick={setSharingWindowFullscreen}
          >
            {sharingWindowFullsceen ? (
              <FullscreenExitIcon />
            ) : (
              <FullscreenIcon />
            )}
          </IconButton>
          <IconButton
            sx={classes.fullscreenCollapse}
            id="gameCollapse"
            onClick={handleMinimizeSharingScreen}
          >
            <CloseFullscreenIcon />
          </IconButton>
        </>
      ) : null}

      {!playStream && !ue5WebsocketConnected && isPlayButtonPressed ? (
        <PlayScreenLoading />
      ) : null}
      {!playStream && (!isPlayButtonPressed || ue5WebsocketConnected) ? (
        <PlayScreen
          buttonHandler={togglePlayStream}
          isDisabled={!ue5WebsocketConnected}
        />
      ) : null}
    </>
  ) : (
    <CoreWeaveLoader />
  );
};
