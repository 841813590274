import { useEffect, useState } from 'react';

import { useConference } from '../../Dolby';
import { useMytaverseEvent } from '../../../../providers';

export const usePurewebProgressBar = () => {
  const [dolbyLoadingIndicator, setDolbyLoadingIndicator] = useState(false);

  const { isDolbyInitialized, isConferenceInitialized } = useConference();
  const { initMessageSended } = useMytaverseEvent();

  const secondStepLoading = !dolbyLoadingIndicator;
  const secondStepCompleted = dolbyLoadingIndicator;
  const thirdStepLoading = secondStepCompleted && !initMessageSended;
  const thirdStepCompleted = secondStepCompleted && initMessageSended;
  const forthStepLoading = thirdStepCompleted && !isConferenceInitialized;
  const forthStepCompleted = thirdStepCompleted && isConferenceInitialized;

  useEffect(() => {
    if (isDolbyInitialized) {
      setTimeout(() => {
        setDolbyLoadingIndicator(true);
      }, 1500);
    }
  }, [isDolbyInitialized]);

  return {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
  };
};
