import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import { PixelStreaming } from '@tensorworks/libspsfrontend';
import { useMytaverseEvent } from '../../../providers';
import { ICoreWeaveContext } from './interfaces';
import { useCurrentParticipantSpeaking } from '../Pureweb/hooks';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { useConference } from '../Dolby';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import PixelStreamingLogsService from '../../../../../services/PixelStreamingLogsService';

import { MytaverseLogger } from '../../../../../helpers/logger';

// @ts-ignore
const CoreWeaveContext = React.createContext<ICoreWeaveContext>({});

export const useCoreWeave = () => useContext(CoreWeaveContext);

type CoreWeaveProviderProps = {
  children: ReactNode;
};

export const CoreWeaveProvider: React.FC<CoreWeaveProviderProps> = ({
  children,
}) => {
  const [webSocketAddress, setWebSocketAddress] = useState<string | null>(null);
  const [pixelStreaming, setPixelStreaming] = useState<PixelStreaming | null>(
    null,
  );
  const [playStream, setPlayStream] = useState(false);
  const [coreweaveLoading, setCoreweaveLoading] = useState(true);
  const [dataChannelOpened, setDataChannelOpened] = useState(false);
  const [connectedPod, setConnectedPod] = useState('');

  const {
    initMessageHandler,
    initMessageSended,
    setInitMessageSended,
    setGameReadyToPlay,
    currentRoom,
    muted,
    currentParticipant,
    currentEvent,
    gameSound,
    ue5CoreWeaveDisabled,
    isReconnectDisabled,
  } = useMytaverseEvent();
  const { conference } = useConference();
  const {
    sendJSONMessageToWebSocket,
    userId,
    currentEventId,
    sendMessageToUnreal,
  } = useMytaverse();
  const { currentParticipantSpeaking } = useCurrentParticipantSpeaking();

  const pixelSteamingSettingFlag = useRef<NodeJS.Timer | null>(null);
  const isPlayButtonPressedRef = useRef(false);

  useEffect(() => {
    return () => {
      setPlayStream(false);
      setGameReadyToPlay(false);
      setConnectedPod('');
      if (pixelSteamingSettingFlag.current) {
        clearInterval(pixelSteamingSettingFlag.current);
        pixelSteamingSettingFlag.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!currentEvent) {
      return;
    }

    let wsUrl = `wss://sps.tenant-7efe6c-myta.lga1.ingress.coreweave.cloud/${currentEvent.id}/ws`;

    // special override for BMS
    if (currentEvent.id === '3567985690203645') { //demo event
      wsUrl = `wss://pixel-demo.dev2.mytaverse.io`;
    }
    const override = Cookies.get('PIXEL_STREAMING_OVERRIDE');
    if (override) {
      wsUrl = override;
    }

    setWebSocketAddress(wsUrl);
  }, [currentEvent]);

  const sendParticipantState = React.useCallback(() => {
    if (!currentRoom) {
      return;
    }

    const message = {
      action: WebsocketAction.ParticipantState,
      speaking: currentParticipantSpeaking || false,
      muted,
      dolbyConferenceAlias: conference ? conference.alias : null,
      dolbySpatialAudioStyle: conference?.params?.spatialAudioStyle
        ? conference.params.spatialAudioStyle
        : 'disabled',
      dolbyParticipantId: currentParticipant?.dolbyParticipantId,
      worldSoundLevel: gameSound,
    };

    sendJSONMessageToWebSocket(message);
  }, [
    currentRoom,
    currentParticipantSpeaking,
    currentParticipant,
    muted,
    sendJSONMessageToWebSocket,
    conference,
    gameSound,
  ]);

  useEffect(() => {
    if (!pixelStreaming) {
      return;
    }

    if (isReconnectDisabled) {
      pixelStreaming.config.setSettings({
        MaxReconnectAttempts: 0,
      });

      return;
    }

    pixelStreaming.config.setSettings({
      MaxReconnectAttempts: 100,
    });
  }, [isReconnectDisabled, pixelStreaming]);
  // Sync participants state
  useEffect(() => {
    const interval = setInterval(async () => {
      sendParticipantState();
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sendParticipantState]);

  useEffect(() => {
    if (!ue5CoreWeaveDisabled) {
      return;
    }

    setPlayStream(false);
  }, [ue5CoreWeaveDisabled]);

  useEffect(() => {
    // Hide control buttons for Coreweave screens with play button
    if (dataChannelOpened && !isPlayButtonPressedRef.current) {
      setGameReadyToPlay(false);
    }
  }, [dataChannelOpened, isPlayButtonPressedRef.current]);

  useEffect(() => {
    if (
      !initMessageHandler ||
      !pixelStreaming ||
      !dataChannelOpened ||
      playStream ||
      initMessageSended ||
      !connectedPod
    ) {
      return;
    }

    const message = initMessageHandler();

    if (!message) {
      return;
    }

    pixelStreaming.emitUIInteraction(message);

    setTimeout(() => {
      pixelStreaming.emitUIInteraction(message);
    }, 1000);

    setInitMessageSended(true);

    sendMessageToUnreal({
      action: 'CLIENT_CONNECTION_DATA',
      streamingProvider: 'COREWEAVE',
      spsPodId: connectedPod,
    });

    console.log(
      JSON.stringify({
        action: 'CLIENT_CONNECTION_DATA',
        streamingProvider: 'COREWEAVE',
        spsPodId: connectedPod,
      }),
    );

    pixelStreaming.config.setSettings({
      HoveringMouse: true,
    });

    MytaverseLogger.log(`INIT ${JSON.stringify(message)}`);
  }, [
    initMessageHandler,
    initMessageSended,
    pixelStreaming,
    dataChannelOpened,
    playStream,
    connectedPod,
  ]);

  useEffect(() => {
    if (!dataChannelOpened || !pixelStreaming) {
      return;
    }
    const message = {
      action: 'CHANGE_WORLD_SOUND',
      value: gameSound,
    };

    pixelStreaming.emitUIInteraction(JSON.stringify(message));
  }, [gameSound, dataChannelOpened, pixelStreaming]);

  const handleConnectToInstance = React.useCallback(
    async (payload: string) => {
      try {
        if (!userId || !currentEventId) {
          return;
        }

        const message = JSON.parse(payload);

        if (message.state === 'READY') {
          MytaverseLogger.log(`Connected to SPS pod ${message.id}`);

          setInitMessageSended(false);
          setDataChannelOpened(false);
          setConnectedPod(message.id);

          await PixelStreamingLogsService.createPixelStreamingLog({
            participantId: userId,
            eventId: currentEventId,
            timestamp: Date.now(),
            pixelStreamingProvider: 'COREWEAVE',
            pixelStreamingProviderData: {
              id: message.id,
            },
          });
        }

        if (message.state === 'PENDING') {
          setCoreweaveLoading(true);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [userId, currentEventId],
  );

  useEffect(() => {
    if (!pixelStreaming) {
      return;
    }

    pixelStreaming.addEventListener('videoInitialized', handleInitializeButton);
    pixelStreaming.addEventListener('settingsChanged', handleSettingChanged);
    pixelStreaming.addEventListener('dataChannelOpen', handleDataChannelOpened);
    pixelStreaming.addEventListener('playStream', handlePlayStream);

    document.addEventListener('resize', handleResize);
    pixelStreaming.webSocketController.signallingProtocol.addMessageHandler(
      'instanceState',
      handleConnectToInstance,
    );

    return () => {
      pixelStreaming.removeEventListener(
        'dataChannelOpen',
        handleDataChannelOpened,
      );
      pixelStreaming.removeEventListener(
        'videoInitialized',
        handleInitializeButton,
      );
      pixelStreaming.removeEventListener('playStream', handlePlayStream);
      pixelStreaming.removeEventListener(
        'settingsChanged',
        handleSettingChanged,
      );
      document.removeEventListener('resize', handleResize);
    };
  }, [pixelStreaming]);

  const handleDataChannelOpened = useCallback(() => {
    if (!pixelStreaming) {
      return;
    }

    MytaverseLogger.log('DATA CHANNEL OPENED');

    if (!pixelSteamingSettingFlag.current) {
      pixelSteamingSettingFlag.current = setInterval(() => {
        pixelStreaming.config.setSettings({
          HoveringMouse: true,
        });
      }, 1000);
    }

    setDataChannelOpened(true);
  }, [setDataChannelOpened, pixelStreaming]);

  const handleInitializeButton = () => {
    if (!pixelStreaming) {
      return;
    }

    pixelStreaming.config.setSettings({
      HoveringMouse: true,
    });

    setCoreweaveLoading(false);
  };

  const handlePlayStream = () => {
    if (!pixelStreaming) {
      return;
    }

    process.env.REACT_APP_MYTAVERSE_ENV === 'PROD' ||
      pixelStreaming.requestShowFps();

    pixelStreaming.config.setSettings({
      HoveringMouse: true,
    });

    setPlayStream(true);
    setGameReadyToPlay(true);

    if (!isPlayButtonPressedRef.current) {
      isPlayButtonPressedRef.current = true;
    }
  };

  const handleResize = () => {
    if (!pixelStreaming) {
      return;
    }

    pixelStreaming.config.setSettings({
      HoveringMouse: true,
    });
  };

  const handleSettingChanged = () => {
    if (!pixelStreaming) {
      return;
    }

    if (!pixelStreaming.config.getSettings().HoveringMouse) {
      pixelStreaming.config.setSettings({
        HoveringMouse: true,
      });
    }
  };

  const togglePlayStream = useCallback(() => {
    if (!pixelStreaming) {
      return;
    }

    pixelStreaming.play();
  }, [pixelStreaming]);

  useEffect(() => {
    if (ue5CoreWeaveDisabled) {
      setPlayStream(false);
    }

    if (isPlayButtonPressedRef.current && !ue5CoreWeaveDisabled) {
      togglePlayStream();
    }
  }, [ue5CoreWeaveDisabled, isPlayButtonPressedRef.current]);

  React.useEffect(() => {
    return () => {
      setInitMessageSended(false);
    };
  }, []);

  return (
    <CoreWeaveContext.Provider
      value={{
        webSocketAddress,
        pixelStreaming,
        coreweaveLoading,
        dataChannelOpened,
        playStream,
        connectedPod,
        setPixelStreaming,
        setWebSocketAddress,
        togglePlayStream,
        handleInitializeButton,
        isPlayButtonPressed: isPlayButtonPressedRef.current,
      }}
    >
      {children}
    </CoreWeaveContext.Provider>
  );
};
