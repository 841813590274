import { colors } from '../../../../../../constants/colors';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr',
      width: '100%',
      maxWidth: 400,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: 'unset',
      },
    },
    tabs: {
      padding: 2,
      background: colors.gray,
      minHeight: 0,
      '& .MuiTabs-scroller': {
        display: 'flex',
      },
      '& .MuiTabs-flexContainer': {
        display: 'inline-flex',
        justifyContent: 'center',
        columnGap: 4,
        width: '100%',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        py: 1,
        mx: -1,
      },
    },
    tab: {
      color: colors.white,
      opacity: 0.4,
      minHeight: 0,
      p: 0,
      '&.Mui-selected': {
        color: colors.white,
        opacity: 1,
      },
    },
    tabContent: {
      height: 332,
    },
    content: {
      height: '100%',
    },
  };
};
