import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Backdrop, Box, Typography, Stack } from '@mui/material';

import MilicastScreen from '../../HomeBottomBar/ShareScreen/MilicastScreen';
import { useMytaverseEvent } from '../../../providers';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { useStyles } from './styles';
import useAsyncEffect from 'use-async-effect';

const MillicastShareScreenModal = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();
  const {
    initScreenMediaStream,
    closeShareScreenModal,
    loadingShareScreenModal,
  } = useMytaverseEvent();

  useAsyncEffect(async () => {
    await initScreenMediaStream();
  }, []);

  return (
    <Modal
      open
      onClose={async () => {
        if (loadingShareScreenModal) {
          return;
        }

        await closeShareScreenModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={classes.modal}>
        <Stack
          flexDirection="row"
          alignItems="center"
          mb={5}
          justifyContent="center"
        >
          <ScreenShareIcon sx={classes.icon} />
          <Typography sx={classes.title}>
            {translate('millicastScreen.share')}
          </Typography>
        </Stack>
        <MilicastScreen />
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(MillicastShareScreenModal);
