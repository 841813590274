import { useTheme } from '@mui/material';
import { COLORS } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';
import { ScreenSizes } from '../../../../../interfaces';
import { IDashboardTopBarContentStyles } from './interfaces';
import { useMytaverseEvent } from '../../../providers';

export const useStyles = ({
  isOpenChat,
  hasMediaStream,
}: IDashboardTopBarContentStyles): IStyles => {
  const { breakpoints } = useTheme();
  const { isMinimizedScreenSharing } = useMytaverseEvent();

  return {
    root: {
      display: ['none', 'flex'],
      mt: 1,
      columnGap: 2,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mt: 0,
        columnGap: 1,
      },
    },
    rootLoading: {
      display: 'flex',
      mt: 1,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        p: '6px 12px',
        minHeight: 'unset',
      },
    },
    iconButton: {
      display: ['block', 'none'],
      padding: 0,
    },
    stack: {
      mt: 1,
      mr:
        isOpenChat || (hasMediaStream && !isMinimizedScreenSharing)
          ? 50
          : 'unset',
      columnGap: 2,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mt: 0,
        columnGap: 1,
      },
    },
    inviteUserBtn: {
      width: 40,
      height: 40,
      minWidth: 40,
    },
    addPersonIcon: {
      color: COLORS.GREY,
    },
    logoutIcon: { color: 'red' },
    fullscreen: {
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '6px 10px',
        minWidth: 'unset',
        fontSize: 14,
        '& svg': {
          mr: 0,
        },
      },
    },
  };
};
