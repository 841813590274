import { ILastWebsocketJsonMessage } from '../../../../interfaces/webSocketConnectionInfo';

export interface IDashboardContentView {
  openBrowserUrlDialog: boolean;
  onSubmitBrowserUrl: (url: string) => void;
  onCloseBrowserUrlDialog: () => void;
}

export type ParticipantPosition = {
  roomId?: string;
  timestamp: number;
  x: number;
  y: number;
  z: number;
  r: number;
};

export enum PoiMediaType {
  VIDEO = 'video',
  WEBSITE = 'websiteUrl',
  IMAGE = 'image',
  FILE = 'file',
  THREE_D_OBJECT_VIEW = '3dObjectView',
  THREE_D_PROP = '3dProp',
}

type SendToBriefcasePropsType = Pick<
  ILastWebsocketJsonMessage,
  'fileName' | 'downloadUrl'
>;

export interface SendToBriefcasePropsTypeFull extends SendToBriefcasePropsType {
  mediaType?: PoiMediaType;
}
