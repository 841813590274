import { useEffect } from 'react';

import { useDeviceChangeType } from './interfaces';

export const useDeviceChange: useDeviceChangeType = (setState) => {
  useEffect(() => {
    const changeDevice = () => setState(true);

    navigator.mediaDevices?.addEventListener('devicechange', changeDevice);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', changeDevice);
    };
  }, [setState]);
};
