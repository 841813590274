import React, { useEffect, useRef, useCallback } from 'react';

import { IdleTimeout, VideoStream } from '@pureweb/platform-sdk-react';
import {
  Resolution,
  streamResolutionConfiguration,
} from '@pureweb/platform-sdk';

import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import PurewebLoader from './PurewebLoader';

import { useMytaverseEvent } from '../../../providers';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useConference } from '../Dolby';

import purewebLogger from './logger';

import { PurewebViewProps } from './interfaces';

import { useStyles } from './styles';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';

const PurewebView = ({
  videoRef,
  streamerStatus,
  inputEmitter,
  videoStream,
  clientOptions,
  enterPureweb,
  isOpenChat,
}: PurewebViewProps) => {
  const {
    sharingWindowFullsceen,
    setSharingWindowFullscreen,
    currentRoom,
    handleMinimizeSharingScreen,
    isMinimizedScreenSharing,
  } = useMytaverseEvent();
  const { screenMediaStreams } = useConference();

  const { logoutUser } = useMytaverse();
  const classes = useStyles(isOpenChat);
  const notificationRef = useRef<NodeJS.Timeout | null>(null);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (!notificationRef.current && enterPureweb) {
      notificationRef.current = setTimeout(() => {
        purewebLogger.error('Connection timeout');

        new Promise<void>((resolve) => {
          showNotification({
            message: 'This page will be reloaded',
            type: NOTIFICATION_TYPES.WARNING,
          });
          setTimeout(() => {
            resolve();
          }, 10000);
        }).then(() => {
          window.location.reload();
        });
      }, 300000);
    }

    if (notificationRef.current && !enterPureweb) {
      clearTimeout(notificationRef.current);
    }

    return () => {
      if (notificationRef.current) {
        clearTimeout(notificationRef.current);
      }
    };
  }, [enterPureweb]);

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }, []);

  return (
    <Box>
      {currentRoom ? (
        <Box style={classes.root}>
          {screenMediaStreams.length && !isMinimizedScreenSharing ? (
            <>
              <IconButton
                sx={classes.fullscreen}
                id="gameFull"
                onClick={setSharingWindowFullscreen}
              >
                {sharingWindowFullsceen ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )}
              </IconButton>
              <IconButton
                sx={classes.fullscreenCollapse}
                id="gameCollapse"
                onClick={handleMinimizeSharingScreen}
              >
                <CloseFullscreenIcon />
              </IconButton>
            </>
          ) : null}

          <IdleTimeout
            Status={streamerStatus}
            WarningThreshold={1200}
            ExitThreshold={60}
            WarningCallback={exitFullscreen}
            ExitCallback={() => {
              logoutUser();
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: 50,
            }}
          />
          {clientOptions ? (
            <VideoStream
              VideoRef={videoRef}
              Emitter={inputEmitter}
              Stream={videoStream}
              UseNativeTouchEvents={clientOptions.UseNativeTouchEvents}
              UsePointerLock={clientOptions.UsePointerLock}
              PointerLockRelease={clientOptions.PointerLockRelease}
              Resolution={streamResolutionConfiguration(
                clientOptions.Resolution ?? Resolution.fhd,
              )}
            />
          ) : null}
          <Box />
        </Box>
      ) : (
        <PurewebLoader />
      )}
    </Box>
  );
};

export default PurewebView;
