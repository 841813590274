import { useTheme } from '@mui/material';
import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { colors } from '../../../../constants/colors';
import { IStylesProps } from './interfaces';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = ({ isPlaying }: IStylesProps) => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    handleSoundBtn: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: isPlaying ? colors.red_80 : appTheme.loadingPage.mainColor,
      textTransform: 'capitalize',
      transition: 'none',
      boxShadow: 'none',
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
    selectionContainer: {
      width: '100%',
      position: 'relative',
      marginTop: '33px',
      marginBottom: '33px',
    },
    selection: {
      position: 'absolute',
      content: '""',
      inset: 0,
      padding: '16px 24px',
      '& .MuiInputBase-root': {
        width: 'calc(100% - 24px - 8px)',
      },
    },
    helper: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.oxford_40,
      marginBottom: '49px',
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
        marginBottom: 4.5,
      },
    },
  };
};
