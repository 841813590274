import axios from 'axios';

export default class AvaturnService {
  static createUser(): Promise<{ id: string }> {
    return axios
      .post(
        `${process.env.REACT_APP_AVATURN_API_URL}users/new`,
        {},
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AVATURN_TOKEN}`,
          },
        },
      )
      .then((res) => res.data);
  }

  static createSession(avaturnId: string): Promise<{
    url: string;
    id: string;
    custom_upload_url: string;
  }> {
    return axios
      .post(
        `${process.env.REACT_APP_AVATURN_API_URL}sessions/new`,
        {
          user_id: avaturnId,
          config: {
            type: 'create_or_edit_existing',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AVATURN_TOKEN}`,
          },
        },
      )
      .then((res) => res.data);
  }
}
