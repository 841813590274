import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 1,
      padding: '28px 48px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        padding: '28px 32px',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        padding: '8px 16px',
        '& svg': {
          width: 125,
        },
      },
    },
    box: {
      display: 'flex',
      flex: 1,
    },
  };
};
