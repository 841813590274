import { Box } from '@mui/material';

import { useStyles } from './styles';

const LoadingStepDelimiter = () => {
  const classes = useStyles();

  return <Box sx={classes.root} />;
};

export default LoadingStepDelimiter;
