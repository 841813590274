import React, { useEffect, useRef } from 'react';

import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import GameCastLoader from './GameCastLoader';
import { PlayScreenLoading } from '../../PlayScreen';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import { useGameCast } from './GameCastProvider';
import { useConference } from '../Dolby';

import { useStyles } from './styles';

const GameCast = () => {
  const { videoRef } = useGameCast();
  const {
    currentRoom,
    setSharingWindowFullscreen,
    sharingWindowFullsceen,
    isOpenLeftMenu,
    handleMinimizeSharingScreen,
    isMinimizedScreenSharing,
    isGamecastCrash,
    ue5WebsocketConnected,
  } = useMytaverseEvent();
  const classes = useStyles(isOpenLeftMenu);
  const { sendJSONMessageToWebSocket } = useMytaverse();
  const { screenMediaStreams } = useConference();

  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (currentRoom) {
      sendJSONMessageToWebSocket({
        action: 'CHANGE_SCREEN_RESOLUTION',
        width: 1980,
        height: 1080,
      });
      window.dispatchEvent(new Event('resize'));
    }
  }, [currentRoom]);

  useEffect(() => {
    if (ue5WebsocketConnected && isFirstLoad.current) {
      isFirstLoad.current = false;
    }
  }, [ue5WebsocketConnected]);

  return (
    <>
      {currentRoom ? null : <GameCastLoader />}
      <Box sx={{ visibility: currentRoom ? 'visible' : 'hidden' }}>
        {screenMediaStreams.length && !isMinimizedScreenSharing ? (
          <>
            <IconButton
              sx={classes.fullscreen}
              id="gameFull"
              onClick={setSharingWindowFullscreen}
            >
              {sharingWindowFullsceen ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </IconButton>
            <IconButton
              sx={classes.fullscreenCollapse}
              id="gameCollapse"
              onClick={handleMinimizeSharingScreen}
            >
              <CloseFullscreenIcon />
            </IconButton>
          </>
        ) : null}
        <video playsInline disablePictureInPicture autoPlay ref={videoRef} />
        {!ue5WebsocketConnected || isGamecastCrash ? (
          <PlayScreenLoading
            text={isFirstLoad.current ? 'loading.teleporting' : null}
          />
        ) : null}
      </Box>
    </>
  );
};

export default GameCast;
