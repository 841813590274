import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use(
  (
    config: AxiosRequestConfig<{
      metadata: {
        requestStartTime: number;
      };
    }>,
  ) => {
    (config.headers as any).Authorization = `${Cookies.get('token')}`;

    if (config.url?.includes('/ping')) {
      const requestStartTime = Date.now();

      const metadata = {
        requestStartTime,
      };

      config.data = config.data ? { ...config.data, metadata } : { metadata };
    }
    return config;
  },
);

$api.interceptors.response.use((response: AxiosResponse) => {
  if (response.config.url?.includes('/ping')) {
    if (response.config.data) {
      const { metadata } = JSON.parse(response.config.data);
      if (metadata) {
        const duration = Date.now() - metadata.requestStartTime;

        const data = JSON.parse(response.config.data);
        response.data = { ...data, requestDuration: duration };
      }
    }
  }

  return response;
});

export default $api;
