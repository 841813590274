import React, { useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { IShareScreenPreview } from './interfaces';
import { useStyles } from './styles';

const ShareScreenPreview = ({
  mediaStream,
  handleStopStream,
  loading,
  onShareScreenStreamClick,
  disabled,
}: IShareScreenPreview) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { t: translation } = useTranslation('common');

  const track =
    mediaStream && mediaStream.getTracks
      ? (mediaStream?.getTracks()[0].getSettings() as MediaTrackSettings & {
          displaySurface?: string;
        })
      : null;

  const classes = useStyles();
  useEffect(() => {
    if (!mediaStream) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    navigator.attachMediaStream(videoRef.current, mediaStream);
    if (mediaStream) {
      mediaStream.getVideoTracks()[0].onended = () =>
        handleStopStream(mediaStream.id);
    }
  }, [mediaStream]);

  return (
    <Box sx={classes.root}>
      <video ref={videoRef} autoPlay playsInline muted={true} />
      <Box sx={classes.notificationWrapper}>
        <Typography sx={classes.notification}>
          {translation('sharingModal.important')}:
        </Typography>
        <Typography sx={classes.notificationText}>
          {translation('sharingModal.importantText')}
        </Typography>
      </Box>
      {track?.displaySurface === 'window' ? (
        <Typography sx={classes.notificationWindow}>
          {translation('sharingModal.windowSurface')}
        </Typography>
      ) : null}
      <Button
        variant="contained"
        onClick={() => onShareScreenStreamClick(mediaStream.id)}
        disabled={loading || !mediaStream || disabled}
        sx={classes.button}
      >
        {loading ? <CircularProgress size={20} /> : 'Start Screen Sharing'}
      </Button>
    </Box>
  );
};

export default ShareScreenPreview;
