import React, { Dispatch, SetStateAction } from 'react';
import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import { IEvent, IFollower } from '../../../../interfaces/event';
import {
  IParticipant,
  IParticipantRegion,
} from '../../../../interfaces/participants';
import { IRoom } from '../../../../interfaces/rooms';
import { IUser } from '../../../../interfaces/user';
import { IFollowerData } from '../../../../interfaces/followers';
import { EventDrawerTabs } from '../../constants';
import { IInitMessage } from '../../components/DashboardContent/Pureweb/interfaces';
import PurewebClientOptions from '../../components/DashboardContent/Pureweb/helpers';
import { IAvatar } from '../../../../interfaces/avatar';
import { SendToBriefcasePropsTypeFull } from '../../components/DashboardContent/interfaces';
import {
  IMillicastEventTokens,
  IMillicastStreamTokens,
  IMillicastTokens,
} from '../../../../interfaces/millicastTokens';
import { IPointOfInterest } from '../../../../interfaces/pointsOfInterest';
import { IStreamService } from '../../../../interfaces/streamService';
import { IShareMediaParams } from '../../../../interfaces/shareMediaParams';
import { IMillicastPublisher } from '../../../../interfaces/millicastPublisher';

export interface EventParticipantDataType {
  currentEvent?: IEvent;
  participant: IUser;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export type UpdateParticipantType = (
  participantId: string,
  newParticipantInfo: IParticipant,
) => void;

export interface ParticipantsStateHookProps {
  currentParticipantId: string;
  currentEventId: string | undefined;
  currentParticipant: IParticipant | undefined;
  setCurrentParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | undefined>
  >;
  participants: IParticipant[];
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  currentRoom: IRoom | null;
  setCurrentRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  currentRegion: IParticipantRegion | null;
  setCurrentRegion: React.Dispatch<
    React.SetStateAction<IParticipantRegion | null>
  >;
  setIsParticipantRoomChanged: React.Dispatch<React.SetStateAction<boolean>>;
  rooms: IRoom[];
}

export type SetParticipantStateType = (
  participantId: string,
  data: {
    eventId?: string | null;
    roomId?: string | null;
    region?: IParticipantRegion | null;
    regions?: IParticipantRegion[];
    gameSessionId?: string | null;
    isDemoUser?: boolean;
  },
) => void;

export enum SendMutedStateMessageEnum {
  MUTE = 'MUTE',
  UNMUTE = 'UNMUTE',
}

export enum InitMessageActions {
  INIT = 'INIT',
}

export interface UseInitMessageProps {
  token?: string;
  websocketSessionId: string;
  currentSkin: IAvatarSkin | null;
  dolbyToken: string;
  currentParticipant?: IParticipant;
  currentEvent?: IEvent;
  customAvatarUrl: string | null;
  teleportingToRoom: IRoom | null;
  isReconnect: boolean;
}

export enum StreamingProviders {
  Pureweb = 'PURE_WEB',
  GameCast = 'GAME_CAST',
  CoreWeave = 'CORE_WEAVE',
}

export interface IMapFollower {
  pending: IFollowerData[];
  accepted: IFollowerData[];
}

export type MapFollowersType = (followers: IFollower[]) => IMapFollower;

export interface IMytaverseEventContext {
  currentParticipantId: string;

  isOpenLeftMenu: boolean;

  openLeftMenu: (tab?: EventDrawerTabs) => void;
  closeLeftMenu: () => void;
  setParticipantsSound: (level: number) => void;
  participantsSound: number;

  eventLoaded: boolean;
  setEventLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  leftMenuTab: EventDrawerTabs;
  setLeftMenuTab: (tab: EventDrawerTabs) => void;
  customAvatarUrl: string | null;
  setCustomAvatarUrl: (url: string | null) => void;
  setGameSound: (level: number) => void;
  initMessageHandler: () => IInitMessage | null;
  gameSound: number;
  clientOptions: PurewebClientOptions | null;
  rooms: IRoom[];
  reaction: string | null;
  emoji: string | null;
  setEmoji: (emoji: string | null) => void;
  setReaction: (reaction: string | null) => void;
  sharingWindowFullsceen: boolean;
  setSharingWindowFullscreen: () => void;
  handleMinimizeSharingScreen: () => void;
  isMinimizedScreenSharing: boolean;
  setIsMinimizedScreenSharing: React.Dispatch<React.SetStateAction<boolean>>;
  currentEvent: IEvent | undefined;

  currentRoom: IRoom | null | undefined;
  setCurrentRoom: Dispatch<SetStateAction<IRoom | null>>;
  gameCastStreamId: string;
  setGameCastStreamId: Dispatch<SetStateAction<string>>;
  gameCastStreamRegion: string;
  setGameCastStreamRegion: Dispatch<SetStateAction<string>>;
  currentRegion: IParticipantRegion | null;
  setCurrentRegion: (region: IParticipantRegion | null) => void;
  gameCastStreamRequestSended: boolean;
  setGameCastStreamRequestSended: React.Dispatch<React.SetStateAction<boolean>>;
  participants: IParticipant[];
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  currentParticipant: IParticipant | undefined;
  setCurrentParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | undefined>
  >;
  shareMillicastVideoWithSound: boolean;
  setShareMillicastVideoWithSound: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  teleportingToRoom: IRoom | null;
  setTeleportingToRoom: Dispatch<SetStateAction<IRoom | null>>;
  teleportToRoom: (room: IRoom | null) => void;
  isTeleportingToRoomByUnreal: boolean;
  setIsTeleportingToRoomByUnreal: Dispatch<SetStateAction<boolean>>;

  teleportToParticipant: (participant: IParticipant | null) => Promise<void>;
  currentRoomDolbySpatialAudioScale: number;
  setRoomDolbySpatialAudioScale: (
    roomId: string,
    dolbySpatialAudioScale: number,
  ) => void;
  updateRoomScale: (value: number) => void;
  avatars: IAvatar[];
  previousSkin: IAvatarSkin | null;
  ue5WebsocketConnected: boolean;
  setUe5WebsocketConnected: React.Dispatch<React.SetStateAction<boolean>>;
  avatarSkins: IAvatarSkin[];
  getSkins: (avatarId: string) => void;
  selectSkin: (skin: IAvatarSkin) => void;
  loading: boolean;
  loadingSkins: boolean;
  loadingAvatars: boolean;
  isTeleporting: boolean;
  setIsTeleporting: React.Dispatch<React.SetStateAction<boolean>>;
  openAdminSettingsModal: boolean;
  setOpenAdminSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
  gamecastSessionArn: string | null;
  setGamecastSessionArn: React.Dispatch<React.SetStateAction<string | null>>;
  isGamecastCrash: boolean;
  setIsGamecastCrash: React.Dispatch<React.SetStateAction<boolean>>;
  muted: boolean;
  poiPreviewSrc: SendToBriefcasePropsTypeFull | null;
  setPoiPreviewSrc: React.Dispatch<
    React.SetStateAction<SendToBriefcasePropsTypeFull | null>
  >;
  setMuted: (value?: boolean) => void;
  dolbyToken: string;
  streamChatToken: string;
  millicastTokens: IMillicastEventTokens | null;
  getMillicastStreamTokens: (
    screenName: string,
  ) => Promise<IMillicastStreamTokens | null>;
  streamingProvider: StreamingProviders | null;
  setStreamingProvider: Dispatch<SetStateAction<StreamingProviders | null>>;
  pointsOfInterest: IPointOfInterest[] | null;
  resetPreviousSkin: () => void;
  streamService?: IStreamService;
  userFiles: IPointOfInterest[];
  setUserFiles: React.Dispatch<React.SetStateAction<IPointOfInterest[]>>;
  micUsingByMillicast: boolean;
  setMicUsingByMillicast: React.Dispatch<React.SetStateAction<boolean>>;
  setParticipantState: SetParticipantStateType;
  updateParticipant: UpdateParticipantType;
  setSpeakingParticipants: (speakingParticipantIds: string[]) => void;
  loadEventAvatars: () => void;

  isShareScreenModalOpen: boolean;
  openShareScreenModal: (params: IShareMediaParams) => Promise<void>;
  closeShareScreenModal: (unlockScreen?: boolean) => Promise<void>;

  openMillicastScreenBroadcastingDataModal: boolean;
  setOpenMillicastScreenBroadcastingDataModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  loadingShareScreenModal: boolean;
  setLoadingShareScreenModal: React.Dispatch<React.SetStateAction<boolean>>;

  isShareVideoModalOpen: boolean;
  openShareVideoModal: (params: IShareMediaParams) => Promise<void>;
  closeShareVideoModal: (unlockScreen?: boolean) => Promise<void>;

  loadingShareVideoModal: boolean;
  setLoadingShareVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
  leftMenuScrollHandler: ((page: number) => void) | null;
  setLeftMenuScrollHandler: React.Dispatch<
    React.SetStateAction<((page: number) => void) | null>
  >;
  shareMediaParams?: IShareMediaParams | null;
  setShareMediaParams: React.Dispatch<
    React.SetStateAction<IShareMediaParams | null>
  >;

  showControls: boolean;
  setShowControls: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentSkin: React.Dispatch<React.SetStateAction<IAvatarSkin | null>>;
  currentSkin: IAvatarSkin | null;
  setCurrentAvatarId: React.Dispatch<React.SetStateAction<string | null>>;
  currentAvatarId: string | null;
  initMessageSended: boolean;
  openLeaveRegionDialog: boolean;
  setOpenLeaveRegionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openCameraPublisherDialog: boolean;
  setOpenCameraPublisherDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openTeleportToRoomDialog: boolean;
  setOpenTeleportToRoomDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTeleportRoom: IRoom | null;
  setSelectedTeleportRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  initScreenMediaStream: (force?: boolean) => Promise<void>;
  handleStopScreenStream: (streamId: string) => Promise<void>;
  setInitMessageSended: React.Dispatch<React.SetStateAction<boolean>>;
  enableScreenResolutionMessages: boolean;
  setEnableScreenResolutionMessages: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  shareVideoPublishers: IMillicastPublisher | null;
  setShareVideoPublishers: (publisher: IMillicastPublisher | null) => void;

  shareVideoMediaStream: MediaStream | null;
  setShareVideoMediaStream: React.Dispatch<
    React.SetStateAction<MediaStream | null>
  >;

  shareScreenPublishers?: IMillicastPublisher[];
  setShareScreenPublishers: (publisher: any) => void;
  developersTerminalMessages: string[];
  setDevelopersTerminalMessages: React.Dispatch<React.SetStateAction<string[]>>;
  openDevelopersTerminal: boolean;
  setOpenDevelopersTerminal: React.Dispatch<React.SetStateAction<boolean>>;
  shareScreenMediaStreams?: MediaStream[];
  setShareScreenMediaStreams: React.Dispatch<
    React.SetStateAction<MediaStream[]>
  >;
  isReconnectDisabled: boolean;
  setIsReconnectDisabled: React.Dispatch<React.SetStateAction<boolean>>;

  resetPreviousCustomSkin: () => void;
  previousCustomAvatar: string | null;
  startShareVideo: (
    shareVideoPublishers: any,
    shareVideoTokens: IMillicastTokens,
    muted: boolean,
  ) => void;
  stopShareVideo: () => void;

  startShareScreen: (
    shareScreenPublishers: IMillicastPublisher,
    shareScreenTokens: IMillicastTokens,
    muted: boolean,
  ) => void;
  stopShareScreen: (streamId: string) => void;
  cleanStates: () => void;

  trackAnalytics: (type: string, payload: any) => Promise<void>;

  pendingFollowersData: IFollowerData[];
  setPendingFollowersData: Dispatch<SetStateAction<IFollowerData[]>>;
  acceptedFollowersData: IFollowerData[];
  setAcceptedFollowersData: Dispatch<SetStateAction<IFollowerData[]>>;

  handleMillicastWSMessage: (lastJSONMessage: any) => void;

  gameReadyToPlay: boolean;
  setGameReadyToPlay: Dispatch<SetStateAction<boolean>>;

  ue5CoreWeaveDisabled: boolean;
  setUe5CoreWeaveDisabled: Dispatch<SetStateAction<boolean>>;

  gameCastVideoRef: HTMLVideoElement | null;
  setGameCastVideoRef: (videoRef: HTMLVideoElement | null) => void;

  showExitButton: boolean;
  setShowExitButton: Dispatch<SetStateAction<boolean>>;
}
