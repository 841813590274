export interface CreateGameSessionResponse {
  Status: string;
  SignalResponse: string;
  WebSdkProtocolUrl: string;
  Arn?: string;
  StreamGroupId?: string;
  ApplicationArn?: string;
  UserId?: string;
}

export interface GameCastStreamSession {
  attachInput: () => void;
  closeConnection: () => void;
  detachInput: () => void;
  id: string;
  stats: {
    getRtcStatsReport: () => void;
  };
}

enum ConnectionState {
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
  Failed = 'failed',
  Closed = 'closed',
}

interface IClientConnectionCallbacks {
  connectionState?: (state: ConnectionState) => void;
  channelError?: (e: any) => void;
  serverDisconnect?: (reasoncode: 'idle' | 'terminated' | string) => void;
  applicationMessage?: (message: Uint8Array) => void;
}

export enum GamecastServerDisconnectReason {
  Idle = 'idle',
  Terminated = 'terminated',
}

interface GamepadFilterResult {
  suppressButtonIndexes: ReadonlyArray<number>;
}

interface InputConfiguration {
  autoKeyboard?: boolean;
  autoMouse?: boolean;
  autoGamepad?: boolean;
  keyboardFilter?: null | ((event: KeyboardEvent) => boolean);
  mouseFilter?: null | ((event: MouseEvent) => boolean);
  gamepadFilter?: null | ((event: Gamepad) => boolean | GamepadFilterResult);
  hapticFeedback?: boolean;
  setCursor?: true | false | 'visibility' | ((cursorStyle: string) => void);
  autoPointerLock?: true | false | 'fullscreen';
}

export interface GameCastArgs {
  videoElement: HTMLVideoElement;
  inputConfiguration?: InputConfiguration;
  clientConnection?: IClientConnectionCallbacks;
}

export interface GameCast {
  id: string;
  enableMicrophone(): Promise<void>;
  generateSignalRequest(): Promise<string>;
  processSignalResponse(signalResponse: string): Promise<void>;
  attachInput(): void;
  detachInput(): void;
  getRTCStats(): Promise<RTCStatsReport>;
  close(): void;
  processKeyboardEvent(e: KeyboardEvent): boolean;
  processMouseEvent(e: MouseEvent): boolean;
  addGamepad(gamepad: Gamepad): boolean;
  removeGamepad(gamepad: Gamepad): boolean;
  processGamepads(): boolean;
  sendApplicationMessage(message: Uint8Array): boolean;
}
