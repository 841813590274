import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect/';

import { IAvatarSkin } from '../../../../../interfaces/avatarSkin';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import EventsService, {
  IEventParticipantData,
} from '../../../../../services/EventsService';
import ParticipantService from '../../../../../services/ParticipantsService';

import {
  EventParticipantDataType,
  InitMessageActions,
  ParticipantsStateHookProps,
  SetParticipantStateType,
  UseInitMessageProps,
} from '../interfaces';
import { getInitialMapParticipants } from '../../../../../helpers/participant';
import { IParticipant } from '../../../../../interfaces/participants';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { IInitMessage } from '../../../components/DashboardContent/Pureweb/interfaces';
import { ParticipantPosition } from '../../../components/DashboardContent/interfaces';
import { getSessionStorageValue } from '../../../../../helpers/sessionStorage';
import { isMobile } from 'react-device-detect';
import { SessionStorage } from '../../../../../constants/storage';

import { MytaverseLogger } from '../../../../../helpers/logger';

export const useEventParticipantData = ({
  currentEvent,
  participant,
  setLoading,
}: EventParticipantDataType) => {
  const { selectedLanguage, userId } = useMytaverse();
  const firstRenderSettings = useRef<IEventParticipantData | null>(null);
  const settingsRequestTimeout = useRef<NodeJS.Timeout | null>(null);

  const [participantsSound, setParticipantsSound] = useState(1);
  const [gameSound, setGameSound] = useState(0.5);

  const [customAvatarUrl, setCustomAvatarUrl] = useState<string | null>(null);
  const [currentAvatarId, setCurrentAvatarId] = useState<string | null>(null);
  const [currentSkin, setCurrentSkin] = useState<IAvatarSkin | null>(null);

  useAsyncEffect(async () => {
    if (firstRenderSettings.current || !currentEvent || !participant) return;

    const settings = await EventsService.getEventUserData(
      currentEvent.id,
      participant.id,
    );

    if (settings) {
      const { selectedLanguage, participantsSound, gameSound } = settings;

      if (participantsSound !== undefined && participantsSound !== null) {
        setGameSound(participantsSound);
      }

      if (gameSound !== undefined && gameSound !== null) {
        setGameSound(gameSound);
      }

      firstRenderSettings.current = {
        eventId: currentEvent.id,
        participantId: participant.id,
        selectedLanguage,
        participantsSound,
        gameSound,
      };
    }
  }, [participant, currentEvent]);

  useAsyncEffect(async () => {
    //TODO create request to set setting
    if (!participant || !currentEvent) return;

    if (!firstRenderSettings.current) {
      firstRenderSettings.current = {
        eventId: currentEvent.id,
        participantId: participant.id,
        selectedLanguage,
        participantsSound,
        gameSound,
      };
    }

    const isEqualSettings = isEqual(firstRenderSettings.current, {
      eventId: currentEvent.id,
      participantId: participant.id,
      selectedLanguage,
      participantsSound,
      gameSound,
    });

    if (!isEqualSettings) {
      if (settingsRequestTimeout.current) {
        clearTimeout(settingsRequestTimeout.current);
        settingsRequestTimeout.current = null;
      }

      settingsRequestTimeout.current = setTimeout(async () => {
        firstRenderSettings.current = {
          eventId: currentEvent.id,
          participantId: participant.id,
          selectedLanguage,
          participantsSound,
          gameSound,
        };

        await EventsService.setEventUserData(currentEvent.id, participant.id, {
          selectedLanguage,
          participantsSound,
          gameSound,
        });

        settingsRequestTimeout.current = null;
      }, 1000);
    }
  }, [
    selectedLanguage,
    participantsSound,
    gameSound,
    participant,
    currentEvent,
  ]);

  const setParticipantsSoundHandle = useCallback(
    (level: any) => {
      localStorage.setItem('participantsSound', String(level));
      setParticipantsSound(level);
    },
    [setParticipantsSound],
  );

  const setGameSoundHandle = useCallback(
    (level: number) => {
      localStorage.setItem('worldSoundLevel', String(level));
      setGameSound(level);
    },
    [setGameSound],
  );

  const selectSkin = useCallback(
    async (skin: IAvatarSkin) => {
      setLoading(true);
      if (!currentEvent || !participant) return;
      try {
        await EventsService.setEventSkin({
          avatar: skin.idAvatar,
          event: currentEvent.id || '',
          player: participant.email,
          skin: skin.idSkinModel,
        });
        setCustomAvatarUrl(null);
        setCurrentSkin(skin);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [setLoading, setCustomAvatarUrl, setCurrentSkin],
  );

  const selectSkinHandler = useCallback(
    async (skin: IAvatarSkin) => {
      if (!currentEvent || !userId) {
        return;
      }

      await EventsService.setEventUserData(currentEvent.id, userId, {
        skinId: skin.idSkinModel,
        avatarId: skin.avatar,
        customAvatarUrl: '',
      });

      selectSkin(skin);
    },
    [selectSkin, participant, currentEvent],
  );

  return {
    participantsSound,
    gameSound,
    customAvatarUrl,
    currentAvatarId,
    currentSkin,
    setCurrentSkin,
    setCurrentAvatarId,
    setCustomAvatarUrl,
    setGameSound,
    setParticipantsSound,
    setGameSoundHandle,
    setParticipantsSoundHandle,
    selectSkinHandler,
  };
};

export const useParticipantsState = ({
  currentParticipantId,
  currentEventId,
  currentParticipant,
  setParticipants,
  setCurrentParticipant,
  currentRoom,
  setCurrentRoom,
  currentRegion,
  setCurrentRegion,
  setIsParticipantRoomChanged,
  rooms,
}: ParticipantsStateHookProps) => {
  const { sendJSONMessageToWebSocket } = useMytaverse();

  const syncEventParticipantsState = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const participantsInfo = await EventsService.getEventParticipantsInfo(
      currentEventId,
    );
    const participantRoles = await EventsService.getEventParticipantsRoles(
      currentEventId,
    );

    setParticipants((participants) =>
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantRoles,
      ),
    );
    setCurrentParticipant((prev) => {
      if (!prev) {
        return;
      }

      const participantInfo = participantsInfo.find(
        (participantInfo) =>
          participantInfo.participantId === currentParticipantId,
      );

      if (participantInfo && participantInfo.roomId !== prev?.roomId) {
        return {
          ...prev,
          roomId: participantInfo.roomId,
        };
      }

      return prev;
    });
  }, [currentEventId, currentParticipantId]);

  // Set current room
  useAsyncEffect(async () => {
    if (!currentParticipant) {
      return;
    }

    const webSocketMessages = [];

    if (
      currentParticipant.roomId &&
      (!currentRoom ||
        currentRoom.id !== currentParticipant.roomId ||
        currentRoom.gameSessionId !== currentParticipant.gameSessionId)
    ) {
      const room = rooms.find(({ id }) => id === currentParticipant.roomId);

      if (!room) {
        return;
      }

      // Indicate that it's not a newcomer, just room has been changed
      if (currentRoom && currentRoom.id) {
        setIsParticipantRoomChanged(true);
      }

      setCurrentRoom({
        ...room,
        gameSessionId: currentParticipant.gameSessionId,
      });

      webSocketMessages.push({
        action: WebsocketAction.JoinedToRoom,
        timestamp: Date.now(),
        participantId: currentParticipant.id,
        eventId: currentParticipant.eventId,
        roomId: room.id,
        regionName: currentParticipant.region
          ? currentParticipant.region.region
          : null,
        gameSessionId: currentParticipant.gameSessionId,
        isSpeaker: currentParticipant.isSpeaker,
        isDemoUser: currentParticipant.isDemoUser,
      });
    } else if (!currentParticipant.roomId && currentRoom) {
      setCurrentRoom(null);
      setCurrentRegion(null);
    }

    const currentRegionName = currentRegion ? currentRegion.region : null;
    const currentParticipantRegionName = currentParticipant.region
      ? currentParticipant.region.region
      : null;

    if (currentRegionName !== currentParticipantRegionName) {
      setCurrentRegion(currentParticipant.region);

      if (currentParticipant.region) {
        webSocketMessages.push({
          action: WebsocketAction.JoinedToRegion,
          timestamp: Date.now(),
          participantId: currentParticipant.id,
          region: currentParticipant.region.region,
        });

        MytaverseLogger.log(
          `${currentParticipant.fullName} joined to ${currentParticipant.region?.region} region`,
        );
      }

      if (currentRegionName && !currentParticipantRegionName) {
        webSocketMessages.push({
          action: WebsocketAction.LeftRegion,
          timestamp: Date.now(),
          participantId: currentParticipant.id,
          region: currentRegion?.region,
        });

        MytaverseLogger.log(
          `${currentParticipant.fullName} left ${currentRegionName} region`,
        );
      }
    }

    if (webSocketMessages.length !== 0) {
      await Promise.all(
        webSocketMessages.map((webSocketMessage) =>
          sendJSONMessageToWebSocket(webSocketMessage),
        ),
      );
    }
  }, [currentParticipant, currentRoom, currentRegion]);

  // Sync participants state
  useEffect(() => {
    const interval = setInterval(async () => {
      await syncEventParticipantsState();
    }, 30000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [syncEventParticipantsState]);

  const setParticipantState: SetParticipantStateType = useCallback(
    (participantId, participantState) => {
      if (currentParticipantId === participantId) {
        setCurrentParticipant((prev) => {
          if (!prev) {
            return prev;
          }

          return {
            ...prev,
            ...participantState,
          };
        });
      }

      setParticipants((prev) => {
        const isNewParticipant = !prev.some(
          ({ userId }) => userId === participantId,
        );

        if (isNewParticipant) {
          return [
            ...prev,
            {
              ...ParticipantService.getEmptyParticipant(participantId),
              ...participantState,
            } as IParticipant,
          ];
        }

        return prev.map((participant) => {
          if (participant.id === participantId) {
            return {
              ...participant,
              ...participantState,
            };
          }

          return participant;
        });
      });
    },
    [currentParticipantId],
  );

  return {
    setParticipantState,
  };
};

export const useInitMessage = ({
  token,
  websocketSessionId,
  currentParticipant,
  currentSkin,
  dolbyToken,
  currentEvent,
  customAvatarUrl,
  teleportingToRoom,
  isReconnect,
}: UseInitMessageProps) => {
  const webSocketUrl = new URL(
    process.env.REACT_APP_SPATIAL_MANAGER_WEB_SOCKET_URL as string,
  );

  webSocketUrl.searchParams.append('token', token as string);
  webSocketUrl.searchParams.append('clientType', 'UE5');
  webSocketUrl.searchParams.append('session', websocketSessionId);

  const storageParticipantPosition =
    getSessionStorageValue<ParticipantPosition>(
      SessionStorage.ParticipantPosition,
    );
  const defaultParticipantPosition = {
    timestamp: 0,
    x: 0,
    y: 0,
    z: 0,
    r: 0,
  };

  const initMessageHandler = useCallback((): IInitMessage | null => {
    if (
      !token ||
      !currentParticipant ||
      !teleportingToRoom ||
      !currentEvent ||
      !(currentSkin || customAvatarUrl) ||
      !dolbyToken
    ) {
      return null;
    }

    const message: IInitMessage = {
      action: InitMessageActions.INIT,
      token,
      webSocketUrl,
      participantId: currentParticipant.id,
      changePositionInterval: 1,
      eventId: currentEvent.id,
      avatarId: currentSkin ? currentSkin.avatar : null,
      skinId: currentSkin ? currentSkin.idSkinModel || currentSkin.id : null,
      roomId: teleportingToRoom.id,
      screenHeight: window.innerHeight >= 1080 ? 1080 : window.innerHeight,
      screenWidth: window.innerWidth >= 1980 ? 1980 : window.innerWidth,
      downloadAvatarUrl: customAvatarUrl || '',
      isMobile,
      dolbyToken,
      isSpeaker: currentParticipant.isSpeaker,
      useLastParticipantPosition: !!storageParticipantPosition,
      lastParticipantPosition:
        storageParticipantPosition ?? defaultParticipantPosition,
      isReconnected: isReconnect,
    };

    return message;
  }, [
    token,
    websocketSessionId,
    currentParticipant,
    currentSkin,
    dolbyToken,
    currentEvent,
    customAvatarUrl,
    teleportingToRoom,
    isMobile,
    customAvatarUrl,
    isReconnect,
  ]);

  return {
    initMessageHandler,
  };
};
