import { colors } from '../../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    width: 480,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      '& button': {
        background: colors.mytaDark,
      },
    },
    '& video': {
      '@media screen and ( max-height: 750px )': {
        height: 290,
        objectFit: 'cover',
      },
    },
  },

  button: {
    display: 'flex',
    flexDirection: 'column',
    width: 480,
    height: 48,
    textTransform: 'capitalize',
    background: colors.myta,
    mt: 1,
    fontWeight: 400,
    fontSize: 20,
  },
  guideTitle: {
    fontSize: 20,
    letterSpacing: '0.1px',
    fontWeight: 500,
    mb: 1,
  },

  notification: {
    mt: 5,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 20,
  },

  notificationText: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 20,
    mb: 4,
  },

  notificationWindow: {
    mt: 1,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 20,
  },

  notificationWrapper: {
    '@media screen and ( max-height: 750px )': {
      display: 'none',
    },
  },
});
