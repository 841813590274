import { COLORS } from '../../../../../../constants/colors';
import { IMillicastPublisher } from '../../../../../../interfaces/millicastPublisher';

export const useStyles = (
  shareScreenPublishers: IMillicastPublisher | null,
) => ({
  root: {
    width: '100%',
  },
  videoContainer: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
    width: '100%',

    '& video': {
      left: 0,
      top: 0,
      width: '100%',
      position: 'absolute',
      border: 0,
    },

    '& .MuiBox-root': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
  controls: {
    display: 'flex',
    gap: 1.5,
    alignItems: 'center',
    my: 1,
    '& .MuiFormControl-root': {
      mt: 0,
      mb: 0,
    },
    '& .MuiInputBase-root': {
      mt: 0,
    },
    '& .MuiCheckbox-root': {
      px: 1,
      py: '7px',
    },
    '& .MuiSelect-select': {
      fontWeight: 500,
    },
  },

  button: {
    width: '100%',
    marginTop: 2,
    marginBottom: 1,
    backgroundColor: shareScreenPublishers ? COLORS.RED : COLORS.MYTAVERSE_BLUE,

    '&:disabled': {
      color: COLORS.MEDIUM_GREY,
      backgroundColor: shareScreenPublishers
        ? COLORS.RED
        : COLORS.MYTAVERSE_BLUE,
    },
  },
  checkbox: {
    border: `1px solid ${COLORS.GREY}`,
    borderRadius: 1,
    px: 1,
    '& svg': {
      color: COLORS.WHITE,
    },
    '& label': {
      zIndex: 99999,
      mr: 0,
    },
  },
  checkboxTitle: {
    color: COLORS.WHITE,
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
});
