import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import EventsService from '../../../../services/EventsService';

import { IAvatar } from '../../../../interfaces/avatar';
import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import { IEvent } from '../../../../interfaces/event';
import {
  IParticipant,
  IParticipantRegion,
  ParticipantState,
} from '../../../../interfaces/participants';
import { IRoom } from '../../../../interfaces/rooms';
import { IStreamService } from '../../../../interfaces/streamService';
import ParticipantsService from '../../../../services/ParticipantsService';
import ROUTES from '../../../../constants/routes';
import { useLocation } from 'react-router-dom';
import { IPointOfInterest } from '../../../../interfaces/pointsOfInterest';
import { useNotification } from '../../../../components/Notification';
import { getInitialMapParticipants } from '../../../../helpers/participant';
import { getInitialMapRooms } from '../../../../helpers/room';

import { EventDrawerTabs } from '../../constants';
import {
  useEventParticipantData,
  useInitMessage,
  useParticipantsState,
} from './hooks';
import { useMillicast } from './hooks/millicast';
import {
  StreamingProviders,
  SendMutedStateMessageEnum,
  UpdateParticipantType,
  IMytaverseEventContext,
} from './interfaces';
import AnalyticsService from '../../../../services/AnalyticsService';
import { SendToBriefcasePropsTypeFull } from '../../components/DashboardContent/interfaces';
import { useChatState } from '../../../../hooks/context';
import { mapFollowers } from './helpers';
import PurewebClientOptions from '../../components/DashboardContent/Pureweb/helpers';
import { DolbyService } from '../../components/DashboardContent/Dolby';
import { useInitGameCastStream } from './hooks/streamProviders';
import { IFollowerData } from '../../../../interfaces/followers';
import { WebsocketAction } from '../../../../interfaces/webSocketConnectionInfo';
import { MytaverseLogger } from '../../../../helpers/logger';
import { useBackButton } from './hooks/eventListeners';

const MytaverseEventContext =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  React.createContext<IMytaverseEventContext>({});

export const useMytaverseEvent = () => useContext(MytaverseEventContext);

type MytaverseEventProviderProps = {
  children: ReactNode;
};

export const MytaverseEventProvider: React.FC<MytaverseEventProviderProps> = ({
  children,
}) => {
  const {
    currentEventId,
    token,
    userId,
    user,
    sendJSONMessageToWebSocket,
    sendMessageToEvent,
    websocketSessionId,
    isReconnect,
    setIsReconnect,
  } = useMytaverse();
  const { t: translate } = useTranslation('common');

  const [openLeftMenu, setOpenLeftMenu] = React.useState<boolean>(false);
  const [leftMenuTab, setLeftMenuTab] = React.useState<EventDrawerTabs>(
    EventDrawerTabs.Locations,
  );
  const [reaction, setReaction] = React.useState<string | null>(null);
  const [emoji, setEmoji] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openAdminSettingsModal, setOpenAdminSettingsModal] = useState(false);
  const [loadingAvatars, setLoadingAvatars] = React.useState<boolean>(false);
  const [loadingSkins, setLoadingSkins] = React.useState<boolean>(false);
  const [muted, setMuted] = useState(true);
  const [currentEvent, setCurrentEvent] = React.useState<IEvent>();
  const [eventLoaded, setEventLoaded] = React.useState<boolean>(false);
  const [rooms, setRooms] = React.useState<IRoom[]>([]);
  const [currentRoom, setCurrentRoom] = React.useState<IRoom | null>(null);
  const [leftMenuScrollHandler, setLeftMenuScrollHandler] = useState<
    ((page: number) => void) | null
  >(null);
  const [developersTerminalMessages, setDevelopersTerminalMessages] = useState<
    string[]
  >([]);
  const [openLeaveRegionDialog, setOpenLeaveRegionDialog] =
    React.useState(false);
  const [gamecastSessionArn, setGamecastSessionArn] = React.useState<
    string | null
  >(null);
  const [isGamecastCrash, setIsGamecastCrash] = useState(false);
  const [gameReadyToPlay, setGameReadyToPlay] = React.useState(true);
  const [openCameraPublisherDialog, setOpenCameraPublisherDialog] =
    React.useState(false);
  const [ue5WebsocketConnected, setUe5WebsocketConnected] =
    React.useState(false);
  const [ue5CoreWeaveDisabled, setUe5CoreWeaveDisabled] = useState(false);
  const [currentRegion, setCurrentRegion] =
    React.useState<IParticipantRegion | null>(null);
  const [
    currentRoomDolbySpatialAudioScale,
    setCurrentRoomDolbySpatialAudioScale,
  ] = React.useState<number>(80);
  const [isTeleporting, setIsTeleporting] = useState(false);
  const [poiPreviewSrc, setPoiPreviewSrc] =
    React.useState<SendToBriefcasePropsTypeFull | null>(null);
  const [initMessageSended, setInitMessageSended] = React.useState(false);
  const [previousSkin, setPreviousSkin] = React.useState<IAvatarSkin | null>(
    null,
  );
  const [isReconnectDisabled, setIsReconnectDisabled] = useState(false);
  const [gameCastStreamRequestSended, setGameCastStreamRequestSended] =
    useState(false);
  const [previousCustomAvatar, setPreviousCustomAvatar] = React.useState<
    string | null
  >(null);
  const [pointsOfInterest, setPointsOfInterest] = React.useState<
    IPointOfInterest[] | null
  >(null);
  const [userFiles, setUserFiles] = React.useState<IPointOfInterest[]>([]);

  const [currentParticipant, setCurrentParticipant] = React.useState<
    IParticipant | undefined
  >();
  const [teleportingToRoom, setTeleportingToRoom] =
    React.useState<IRoom | null>(null);
  const [isTeleportingToRoomByUnreal, setIsTeleportingToRoomByUnreal] =
    React.useState(false);
  const [participants, setParticipants] = React.useState<IParticipant[]>([]);
  const [loadingNewParticipants, setLoadingNewParticipants] =
    React.useState(false);
  const [avatars, setAvatars] = React.useState<IAvatar[]>([]);
  const [avatarSkins, setAvatarSkins] = React.useState<IAvatarSkin[]>([]);
  const [streamService, setStreamService] = React.useState<IStreamService>();
  const [streamingProvider, setStreamingProvider] =
    React.useState<StreamingProviders | null>(null);
  const [openTeleportToRoomDialog, setOpenTeleportToRoomDialog] =
    React.useState(false);
  const [selectedTeleportRoom, setSelectedTeleportRoom] =
    React.useState<IRoom | null>(null);
  const [dolbyToken, setDolbyToken] = React.useState<string>('');
  const [streamChatToken, setStreamChatToken] = React.useState<string>('');
  const [enableScreenResolutionMessages, setEnableScreenResolutionMessages] =
    useState(false);
  const [showExitButton, setShowExitButton] = React.useState(false);

  const [showControls, setShowControls] = React.useState<boolean>(true);
  const [sharingWindowFullsceen, setSharingWindowFullscreen] =
    React.useState(false);
  const [isMinimizedScreenSharing, setIsMinimizedScreenSharing] =
    React.useState(false);
  const [openDevelopersTerminal, setOpenDevelopersTerminal] = useState(false);
  const [pendingFollowersData, setPendingFollowersData] = useState<
    IFollowerData[]
  >([]);
  const [acceptedFollowersData, setAcceptedFollowersData] = useState<
    IFollowerData[]
  >([]);
  const [preserveAcceptedFollowersId, setPreserveAcceptedFollowersId] =
    useState<string[]>([]);
  const [isParticipantRoomChanged, setIsParticipantRoomChanged] =
    useState(false);
  const [gameCastVideoRef, setGameCastVideoRef] =
    React.useState<HTMLVideoElement | null>(null);

  const clientOptions = React.useMemo(() => {
    if (
      !streamService ||
      !currentEvent ||
      streamingProvider !== StreamingProviders.Pureweb
    ) {
      return null;
    }

    const purewebClientOptions = new PurewebClientOptions(
      streamService.purewebProjectId,
      streamService.purewebModelId,
    );

    if (currentEvent.region) {
      purewebClientOptions.regionOverride = currentEvent.region;
    }

    return purewebClientOptions;
  }, [currentEvent, streamService, streamingProvider]);

  const {
    millicastTokens,
    getMillicastStreamTokens,
    setMillicastTokens,
    shareMillicastVideoWithSound,
    shareMediaParams,
    shareVideoPublishers,
    shareScreenPublishers,
    shareVideoMediaStream,
    shareScreenMediaStreams,
    isShareVideoModalOpen,
    openShareVideoModal,
    closeShareVideoModal,
    isShareScreenModalOpen,
    openShareScreenModal,
    closeShareScreenModal,
    micUsingByMillicast,
    setMicUsingByMillicast,
    initScreenMediaStream,
    handleStopScreenStream,
    stopShareScreenHandler,
    startShareScreenHandler,
    stopShareVideoHandler,
    startShareVideoHandler,
    setShareScreenMediaStreams,
    setShareVideoMediaStream,
    setShareVideoPublishers,
    setShareScreenPublishers,
    setShareMediaParams,
    setShareMillicastVideoWithSound,

    loadingShareVideoModal,
    setLoadingShareVideoModal,

    loadingShareScreenModal,
    setLoadingShareScreenModal,

    openMillicastScreenBroadcastingDataModal,
    setOpenMillicastScreenBroadcastingDataModal,

    handleMillicastWSMessage,
  } = useMillicast({
    currentRoom,
    currentRegion,
    currentParticipant: currentParticipant as IParticipant,
    muted,
    setMuted,
    setOpenCameraPublisherDialog,
  });

  const { setParticipantState } = useParticipantsState({
    currentParticipantId: userId as string,
    currentEventId,

    currentParticipant,
    setParticipants,

    participants,
    setCurrentParticipant,

    currentRoom,
    setCurrentRoom,

    currentRegion,
    setCurrentRegion,
    setIsParticipantRoomChanged,
    rooms,
  });

  const {
    participantsSound,
    gameSound,
    customAvatarUrl,
    currentAvatarId,
    currentSkin,
    setCurrentSkin,
    setCurrentAvatarId,
    setCustomAvatarUrl,
    setGameSoundHandle,
    setParticipantsSoundHandle,
    selectSkinHandler,
  } = useEventParticipantData({
    currentEvent,
    participant: user,
    setLoading,
  });

  const { pathname } = useLocation();
  const {
    showNotification,
    getBadRequestNotification,
    // getStreamingNotification,
  } = useNotification();
  const { setOpen: setOpenChat } = useChatState();

  const { initMessageHandler } = useInitMessage({
    currentEvent,
    currentParticipant,
    currentSkin,
    customAvatarUrl,
    dolbyToken,
    teleportingToRoom,
    token,
    websocketSessionId,
    isReconnect,
  });

  const {
    streamId: gameCastStreamId,
    setStreamId: setGameCastStreamId,
    streamRegion: gameCastStreamRegion,
    setStreamRegion: setGameCastStreamRegion,
  } = useInitGameCastStream(currentEvent, streamingProvider);

  const cleanStates = useCallback(() => {
    setAvatars([]);
    setAvatarSkins([]);
    setRooms([]);
    setParticipants([]);
    setOpenChat(false);
    setCurrentParticipant(undefined);
    setCurrentRoom(null);
    setCurrentEvent(undefined);

    sendJSONMessageToWebSocket({
      action: 'LEAVE_EVENT',
    });
  }, [
    setAvatars,
    setAvatarSkins,
    setRooms,
    setParticipants,
    setCurrentParticipant,
    setCurrentRoom,
    setCurrentEvent,
    sendJSONMessageToWebSocket,
  ]);

  useBackButton({
    cleanStates,
    initMessageSended,
    ue5WebsocketConnected,
    setUe5WebsocketConnected,
  });

  useEffect(() => {
    if (currentRoom) {
      localStorage.setItem('currentRoomId', currentRoom.id);
      console.log(`Room changed to ${currentRoom.name}`);
    }
  }, [currentRoom]);

  useEffect(() => {
    if (userFiles) {
      localStorage.setItem('userFiles', JSON.stringify(userFiles) as string);
    }
  }, [userFiles, currentRoom]);

  useEffect(() => {
    if (currentRoom) {
      setUserFiles([]);
    }
  }, [currentRoom]);

  useAsyncEffect(async () => {
    if (!currentRoom) {
      return;
    }

    if (pointsOfInterest) {
      setPointsOfInterest(null);
    }

    const pointsInterest = await EventsService.getPointOfInterest(
      currentRoom.id,
    );

    if (pointsInterest.length) {
      setPointsOfInterest(pointsInterest);
    }
  }, [currentRoom]);

  useEffect(() => {
    if (!currentEventId) {
      return;
    }

    sendJSONMessageToWebSocket({
      action: muted
        ? SendMutedStateMessageEnum.MUTE
        : SendMutedStateMessageEnum.UNMUTE,
      timestamp: Date.now(),
    });
  }, [muted, currentEventId, sendJSONMessageToWebSocket]);

  useAsyncEffect(async () => {
    if (!currentEvent) {
      return;
    }

    const streamingProvider = await EventsService.getEventStreamingProvider(
      currentEvent.id,
    ).then((res) =>
      res && res.streamingProvider
        ? res
        : { streamingProvider: StreamingProviders.Pureweb },
    );

    if (streamingProvider && streamingProvider.streamingProvider) {
      setStreamingProvider(streamingProvider.streamingProvider);
    }
  }, [currentEvent]);

  useAsyncEffect(async () => {
    if (loadingNewParticipants) {
      return;
    }

    const loadingParticipants = participants.filter(
      (p) => p.state === ParticipantState.Loading,
    );

    if (loadingParticipants.length === 0) {
      return;
    }

    setLoadingNewParticipants(true);
    const loadedParticipants = await Promise.allSettled(
      loadingParticipants.map(async (p: IParticipant) => {
        const participant = await EventsService.getParticipantProfile(p.userId);

        return {
          ...p,
          ...EventsService.prepareParticipant(participant),
        };
      }),
    );

    if (loadedParticipants.length !== 0) {
      setParticipants((prev) => {
        return prev.map((participant) => {
          const loadedParticipant = loadedParticipants.find(
            (p) => p.status === 'fulfilled' && p.value.id === participant.id,
          );

          if (!loadedParticipant || loadedParticipant.status !== 'fulfilled') {
            return participant;
          }

          return {
            ...loadedParticipant.value,
            key: participant.key,
            eventId: participant.eventId,
            roomId: participant.roomId,
            region: participant.region,
            regions: participant.regions,
            gameSessionId: participant.gameSessionId,
            state: ParticipantState.Loaded,
          };
        });
      });
    }

    setLoadingNewParticipants(false);
  }, [loadingNewParticipants, participants]);

  const loadEventAvatars = useCallback(async () => {
    setLoadingAvatars(true);
    if (currentEventId) {
      const eventAvatars = await EventsService.getEventAvatars(currentEventId);
      setAvatars(eventAvatars);
    }
    setLoadingAvatars(false);
  }, [currentEventId, setAvatars]);

  const loadEventParticipants = useCallback(async () => {
    if (!currentEventId) {
      return;
    }
    const [participants, participantsInfo, participantsRoles] =
      await Promise.all([
        EventsService.getEventParticipants(currentEventId),
        EventsService.getEventParticipantsInfo(currentEventId),
        EventsService.getEventParticipantsRoles(currentEventId),
      ]);

    setParticipants(
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantsRoles,
      ),
    );

    const currentParticipant = participants.find((p) => p.userId === userId);
    const dolbySessionParticipant = DolbyService.getSessionParticipant();

    if (currentParticipant) {
      setCurrentParticipant({
        ...currentParticipant,
        dolbyParticipantId: dolbySessionParticipant
          ? dolbySessionParticipant.id
          : null,
      });
    }
  }, [
    setParticipants,
    setCurrentParticipant,
    getInitialMapParticipants,
    currentEventId,
  ]);

  const loadEventRooms = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const [rooms, roomsInfoScale] = await Promise.all([
      EventsService.getEventRooms(currentEventId),
      EventsService.getEventRoomsInfo(currentEventId),
      EventsService.getEventInfo(currentEventId),
    ]);

    setRooms(getInitialMapRooms(rooms, roomsInfoScale));

    setCurrentRoom(null);

    let teleportingToRoom;
    const currentRoomId = localStorage.getItem('currentRoomId');

    if (currentRoomId) {
      teleportingToRoom = rooms.find((room) => room.id === currentRoomId);
    }

    if (!teleportingToRoom) {
      teleportingToRoom = rooms.find((room) => room.isDefaultRoom);
    }

    if (teleportingToRoom) {
      localStorage.setItem('currentRoomId', teleportingToRoom.id);
      setTeleportingToRoom(teleportingToRoom);
    } else {
      showNotification(
        getBadRequestNotification({
          message: translate('notifications.noRoomInEvent'),
        }),
      );
    }
  }, [
    currentEventId,
    setRooms,
    setCurrentRoom,
    setTeleportingToRoom,
    showNotification,
    getInitialMapRooms,
    getBadRequestNotification,
  ]);

  const loadEventTokens = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const eventTokens = await EventsService.getEventTokens(currentEventId);

    setDolbyToken(eventTokens.dolbyToken);
    setStreamChatToken(eventTokens.streamChatToken);
    setMillicastTokens(eventTokens.millicastTokens);
  }, [currentEventId]);

  useEffect(() => {
    if (currentRoom && currentRoom.dolbySpatialAudioScale) {
      setCurrentRoomDolbySpatialAudioScale(currentRoom.dolbySpatialAudioScale);
    }
  }, [currentRoom]);

  const loadEventStreamServices = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    setStreamService(streamService);
  }, [currentEventId, setStreamService, streamingProvider]);

  useAsyncEffect(async () => {
    if (pathname === ROUTES.HOME_PAGE) {
      await loadEventStreamServices();
      await loadEventParticipants();
      await loadEventRooms();
      await loadEventTokens();
    }
  }, [pathname, currentEventId]);

  // TODO merge it with load participant settings data
  useAsyncEffect(async () => {
    try {
      if (currentEventId && token && userId) {
        setLoading(true);
        setEventLoaded(false);

        setLoadingAvatars(true);

        setPreviousCustomAvatar(null);
        setPreviousSkin(null);
        setCurrentSkin(null);
        setCurrentAvatarId(null);
        setCustomAvatarUrl(null);
        setAvatarSkins([]);

        const event = await EventsService.getEvent(currentEventId);
        setCurrentEvent(event);

        const eventData = await EventsService.getEventUserData(
          currentEventId,
          userId,
        );

        if (eventData) {
          if (
            eventData.skinId &&
            eventData.avatarId &&
            eventData.customAvatarUrl?.length === 0
          ) {
            const prevSkin = await EventsService.getPrevSkin(eventData.skinId);
            setPreviousSkin(prevSkin);
            setCurrentSkin(prevSkin);
            setCurrentAvatarId(prevSkin.avatar);
          }

          if (
            !eventData.skinId &&
            !eventData.avatarId &&
            eventData.customAvatarUrl?.length !== 0
          ) {
            if (eventData.customAvatarUrl) {
              setPreviousCustomAvatar(eventData.customAvatarUrl);
              setCustomAvatarUrl(eventData.customAvatarUrl);
            }
          }
        }

        const followingData = await EventsService.getFollowingData(
          currentEventId,
        );

        const { pending, accepted } = mapFollowers(followingData.followers);

        setPendingFollowersData(pending);
        setAcceptedFollowersData(accepted);

        setLoadingAvatars(false);

        setLoading(false);
      } else {
        setCurrentEvent(undefined);

        setRooms([]);
        setCurrentRoom(null);

        setParticipants([]);
        setCurrentParticipant(undefined);
      }
    } catch (error) {
      showNotification(
        getBadRequestNotification({ message: (error as Error).message }),
      );
    }
  }, [userId, currentEventId, pathname]);

  const ownerTeleportToRoomPreserveData = React.useCallback(() => {
    if (acceptedFollowersData.length === 0 || !currentParticipant) return;

    const followers = acceptedFollowersData
      .filter((follower) => follower.adminId === currentParticipant.userId)
      .map((follower) => follower.userId);

    if (followers.length === 0) return;

    setPreserveAcceptedFollowersId([...new Set(followers)]);
  }, [currentParticipant, acceptedFollowersData]);

  const ownerTeleportToRoom = React.useCallback(() => {
    if (
      preserveAcceptedFollowersId.length === 0 ||
      !currentParticipant ||
      !currentEventId
    )
      return;

    sendMessageToEvent(currentEventId, {
      action: WebsocketAction.OwnerTeleport,
      ownerId: currentParticipant.userId,
      followerIds: [...preserveAcceptedFollowersId],
      ownerRoomId: currentParticipant.roomId,
      gameSessionId: currentParticipant.gameSessionId || null,
    });
  }, [
    currentParticipant,
    acceptedFollowersData,
    preserveAcceptedFollowersId,
    sendMessageToEvent,
    currentEventId,
  ]);

  useEffect(() => {
    if (isParticipantRoomChanged) {
      ownerTeleportToRoom();
      setIsParticipantRoomChanged(false);
      setPreserveAcceptedFollowersId([]);
    }
  }, [
    isParticipantRoomChanged,
    ownerTeleportToRoom,
    setPreserveAcceptedFollowersId,
  ]);

  useEffect(() => {
    if (
      currentEvent &&
      currentParticipant &&
      currentParticipant.eventId !== currentEvent.id
    ) {
      sendJSONMessageToWebSocket({
        action: 'JOIN_TO_EVENT',
        timestamp: Date.now(),
        eventId: currentEvent.id,
        analyticsPayload: {
          event: {
            id: currentEvent.id,
            name: currentEvent.name,
          },
          participant: {
            id: currentParticipant.id,
            name: `${currentParticipant.firstName} ${currentParticipant.lastName}`,
            email: currentParticipant.email,
          },
        },
      });
    }
  }, [currentEvent, currentParticipant]);

  useEffect(() => {
    if (avatars.length) {
      if (currentAvatarId) {
        getAvatarSkins(currentAvatarId);
      }
    }
  }, [avatars]);

  const getAvatarSkins = useCallback(
    async (avatarId: string) => {
      setLoadingSkins(true);

      if (avatars.length && currentEventId) {
        const skins = await EventsService.getEventAvatarSkins(
          currentEventId,
          avatarId,
        );
        setAvatarSkins(skins);
      }

      setLoadingSkins(false);
    },
    [setLoadingSkins, setAvatarSkins, currentEventId, avatars],
  );

  const updateParticipant: UpdateParticipantType = useCallback(
    (participantId, newParticipantInfo) => {
      if (currentParticipant && currentParticipant.id === participantId) {
        setCurrentParticipant((prevParticipant) => ({
          ...prevParticipant,
          ...newParticipantInfo,
          isSpeaker: !!prevParticipant?.isSpeaker,
        }));
      }

      setParticipants((prevParticipants) =>
        prevParticipants.map((prevParticipant) =>
          prevParticipant.id === participantId
            ? {
                ...prevParticipant,
                ...newParticipantInfo,
              }
            : prevParticipant,
        ),
      );
    },
    [currentParticipant],
  );

  const resetPreviousSkin = useCallback(() => {
    if (previousSkin) {
      setPreviousSkin(null);
    }
  }, [previousSkin, setPreviousSkin]);

  const resetPreviousCustomSkin = useCallback(() => {
    if (previousCustomAvatar) {
      setPreviousCustomAvatar(null);
    }
  }, [previousCustomAvatar, setPreviousCustomAvatar]);

  const setRoomDolbySpatialAudioScale = useCallback(
    (roomId: string, dolbySpatialAudioScale: number) => {
      if (currentRoom && currentRoom.id === roomId) {
        setCurrentRoomDolbySpatialAudioScale(dolbySpatialAudioScale);
      }
      /*
      setCurrentRoom((prev) => {
        if (!prev || prev.id !== roomId) {
          return prev;
        }

        return {
          ...prev,
          dolbySpatialAudioScale,
        };
      });
       */

      setRooms((prev) =>
        prev.map((room) => {
          if (room.id === roomId) {
            return {
              ...room,
              dolbySpatialAudioScale,
            };
          }

          return room;
        }),
      );
    },
    [currentRoom],
  );

  const updateRoomScale = useCallback(
    async (value: number) => {
      if (currentRoom && currentEventId) {
        await EventsService.updateRoomSpatialScale(
          currentRoom.id as string,
          value,
          currentEventId,
        );

        setRoomDolbySpatialAudioScale(currentRoom.id, value);
      }
    },
    [currentRoom, currentEventId, setRoomDolbySpatialAudioScale],
  );

  const setSpeakingParticipants = React.useCallback(
    (speakingParticipantIds: string[]) => {
      if (currentParticipant) {
        const speaking: boolean =
          speakingParticipantIds.indexOf(currentParticipant.userId) !== -1;

        if (currentParticipant.speaking !== speaking) {
          setCurrentParticipant({
            ...currentParticipant,
            speaking,
          });
        }
      }
    },
    [currentParticipant, setCurrentParticipant, setParticipants],
  );

  const teleportToRoom = React.useCallback(
    (room: IRoom | null) => {
      setTeleportingToRoom(room);
      //TODO add is speaker to message
      if (room && currentParticipant && !isTeleporting) {
        MytaverseLogger.log(`Teleporting to room: ${room.id} ${room.name}`);
        setIsTeleporting(true);
        ownerTeleportToRoomPreserveData();
        sendJSONMessageToWebSocket({
          action: WebsocketAction.TeleportToRoom,
          roomId: room.id,
          isSpeaker: currentParticipant.isSpeaker,
        });
      }
    },
    [
      setTeleportingToRoom,
      sendJSONMessageToWebSocket,
      currentParticipant,
      setIsTeleporting,
      isTeleporting,
      ownerTeleportToRoomPreserveData,
    ],
  );

  const teleportToParticipant = React.useCallback(
    async (participant: IParticipant | null) => {
      if (!participant || !currentRoom) {
        return;
      }

      MytaverseLogger.log(
        `Teleporting to participant: ${participant.userId} ${participant.fullName}`,
      );

      const { roomId } = await ParticipantsService.getParticipantRoom(
        participant.id,
      );

      if (currentRoom.id !== roomId) {
        setIsTeleporting(true);
        ownerTeleportToRoomPreserveData();
      }

      sendJSONMessageToWebSocket({
        action: WebsocketAction.TeleportToParticipant,
        participantId: participant.userId,
        roomId,
        CurrentGameSessionId: participant.gameSessionId || null,
      });
    },
    [currentRoom, sendJSONMessageToWebSocket, ownerTeleportToRoomPreserveData],
  );

  const teleportRef = useRef<NodeJS.Timeout | null>(null);
  const ue5WebsocketConnectedRef = useRef(false);

  useEffect(() => {
    ue5WebsocketConnectedRef.current = ue5WebsocketConnected;
  }, [ue5WebsocketConnected]);

  useEffect(() => {
    if (!initMessageSended) {
      return;
    }

    setIsReconnect(true);
  }, [initMessageSended]);

  useEffect(() => {
    if (isTeleporting && currentRoom && !teleportRef.current) {
      teleportRef.current = setInterval(() => {
        if (ue5WebsocketConnectedRef.current && teleportRef.current) {
          setIsTeleporting(false);
          clearInterval(teleportRef.current);
        }
      }, 5000);

      return;
    }

    if (teleportRef.current && !isTeleporting) {
      clearInterval(teleportRef.current);
      teleportRef.current = null;
      return;
    }

    return () => {
      if (teleportRef.current) {
        clearInterval(teleportRef.current);
        teleportRef.current = null;
      }
    };
  }, [isTeleporting, currentRoom]);

  useEffect(() => {
    if (isTeleportingToRoomByUnreal) {
      ownerTeleportToRoomPreserveData();
    }
  }, [isTeleportingToRoomByUnreal, ownerTeleportToRoomPreserveData]);

  const closeLeftMenu = React.useCallback(() => {
    setOpenLeftMenu(false);
  }, [setOpenLeftMenu]);

  const openLeftMenuHandler = React.useCallback(
    (tab: any) => {
      setOpenLeftMenu(true);
      if (tab) {
        setLeftMenuTab(tab);
      }
    },
    [setOpenLeftMenu, setLeftMenuTab],
  );

  const setMutedHandler = React.useCallback(
    (value: boolean | undefined) => {
      setMuted(value || !muted);
    },
    [muted, setMuted],
  );

  const setEmojiHandler = React.useCallback(
    (emoji: string | null) => {
      setEmoji(emoji);

      sendJSONMessageToWebSocket({
        action: 'SEND_EMOJI',
        emoji,
      });
    },
    [setEmoji, sendJSONMessageToWebSocket],
  );

  const setReactionHandler = React.useCallback(
    (reaction: string | null) => {
      setReaction(reaction);

      sendJSONMessageToWebSocket({
        action: 'TOGGLE_REACTION',
        reactionId: reaction,
      });
    },
    [setReaction, sendJSONMessageToWebSocket],
  );

  useEffect(() => {
    if (shareScreenPublishers.length) {
      const isSameScreenRegion = shareScreenPublishers.some(
        (s) => s.region !== currentRegion?.region,
      );
      if (isSameScreenRegion) {
        setOpenLeaveRegionDialog(true);
      }

      return;
    }

    if (shareVideoPublishers) {
      const isSameVideoRegion =
        shareVideoPublishers.region !== currentRegion?.region;

      if (isSameVideoRegion) {
        setOpenLeaveRegionDialog(true);
      }
    }
  }, [currentRegion, shareScreenPublishers, shareVideoPublishers]);

  const getSkinsHandler = React.useCallback(
    (avatarId: string) => {
      localStorage.setItem('avatarId', avatarId);
      getAvatarSkins(avatarId);
    },
    [getAvatarSkins],
  );

  const setSharingWindowFullscreenHandle = useCallback(() => {
    setSharingWindowFullscreen(!sharingWindowFullsceen);
  }, [setSharingWindowFullscreen, sharingWindowFullsceen]);

  const handleMinimizeSharingScreen = useCallback(() => {
    setIsMinimizedScreenSharing(!isMinimizedScreenSharing);
  }, [isMinimizedScreenSharing, setIsMinimizedScreenSharing]);

  const trackAnalytics = React.useCallback(
    async (type: string, payload: any = {}) => {
      if (token && currentEvent && user && websocketSessionId) {
        await AnalyticsService.track(token, type, new Date().getTime(), {
          eventId: currentEventId,
          participantId: userId,
          sessionId: websocketSessionId,
          payload: {
            event: {
              id: currentEvent.id,
              name: currentEvent.name,
            },
            participant: {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
            },
            ...payload,
          },
        });
      }
    },
    [token, currentEvent, user, websocketSessionId],
  );

  return (
    <MytaverseEventContext.Provider
      value={{
        currentParticipantId: userId as string,

        isOpenLeftMenu: openLeftMenu,
        reaction,
        emoji,
        muted,
        eventLoaded,
        userFiles,
        previousSkin,
        previousCustomAvatar,
        leftMenuTab,
        rooms,
        currentEvent,
        currentRoom,
        currentRegion,
        avatars,
        avatarSkins,
        currentParticipant,
        clientOptions,
        gameCastStreamRequestSended,
        isTeleporting,
        setIsTeleporting,
        setGameCastStreamRequestSended,
        setCurrentParticipant,
        initMessageHandler,
        gameReadyToPlay,
        setGameReadyToPlay,
        participants,
        teleportingToRoom,
        pointsOfInterest,
        loading,
        loadingSkins,
        loadingAvatars,
        dolbyToken,
        streamChatToken,
        setLeftMenuScrollHandler,
        streamService,
        openLeaveRegionDialog,
        customAvatarUrl,
        leftMenuScrollHandler,
        showControls,
        currentSkin,
        currentAvatarId,
        poiPreviewSrc,
        initMessageSended,
        sharingWindowFullsceen,
        participantsSound,
        selectedTeleportRoom,
        gameSound,
        openTeleportToRoomDialog,
        openAdminSettingsModal,
        openCameraPublisherDialog,
        gamecastSessionArn,
        setGamecastSessionArn,
        setOpenCameraPublisherDialog,
        setOpenAdminSettingsModal,
        streamingProvider,
        gameCastStreamId,
        developersTerminalMessages,
        setDevelopersTerminalMessages,
        setGameCastStreamId,
        gameCastStreamRegion,
        setGameCastStreamRegion,
        setStreamingProvider,
        setGameSound: setGameSoundHandle,
        setUe5WebsocketConnected,
        ue5WebsocketConnected,
        isMinimizedScreenSharing,
        openDevelopersTerminal,
        isReconnectDisabled,
        setIsReconnectDisabled,
        setOpenDevelopersTerminal,
        setIsMinimizedScreenSharing,
        setOpenTeleportToRoomDialog,
        setSelectedTeleportRoom,
        setParticipantsSound: setParticipantsSoundHandle,
        setOpenLeaveRegionDialog,
        setSharingWindowFullscreen: setSharingWindowFullscreenHandle,
        setInitMessageSended,
        loadEventAvatars,
        setPoiPreviewSrc,
        setCurrentAvatarId,
        setCurrentSkin,
        setShowControls,
        setParticipants,
        openLeftMenu: openLeftMenuHandler,
        closeLeftMenu,
        setReaction: setReactionHandler,
        setEventLoaded,
        setMuted: setMutedHandler,
        setEmoji: setEmojiHandler,
        setUserFiles,
        resetPreviousSkin,
        resetPreviousCustomSkin,
        selectSkin: selectSkinHandler,
        setLeftMenuTab,
        setCurrentRoom,
        setCurrentRegion,
        currentRoomDolbySpatialAudioScale,
        isGamecastCrash,
        setIsGamecastCrash,
        getSkins: getSkinsHandler,
        cleanStates,
        setTeleportingToRoom,
        teleportToRoom,
        teleportToParticipant,
        isTeleportingToRoomByUnreal,
        setIsTeleportingToRoomByUnreal,
        setParticipantState,
        updateParticipant,
        setSpeakingParticipants,

        setRoomDolbySpatialAudioScale,
        updateRoomScale,

        setCustomAvatarUrl,

        trackAnalytics,

        pendingFollowersData,
        setPendingFollowersData,
        acceptedFollowersData,
        setAcceptedFollowersData,

        // millicast
        handleMillicastWSMessage,

        startShareVideo: startShareVideoHandler,
        stopShareVideo: stopShareVideoHandler,
        startShareScreen: startShareScreenHandler,
        stopShareScreen: stopShareScreenHandler,

        millicastTokens,
        getMillicastStreamTokens,
        handleMinimizeSharingScreen,
        shareMillicastVideoWithSound,
        shareMediaParams,
        shareVideoPublishers,
        shareScreenPublishers,
        shareVideoMediaStream,
        shareScreenMediaStreams,
        isShareVideoModalOpen,
        openShareVideoModal,
        closeShareVideoModal,
        isShareScreenModalOpen,
        openShareScreenModal,
        closeShareScreenModal,
        micUsingByMillicast,
        setMicUsingByMillicast,
        initScreenMediaStream,
        enableScreenResolutionMessages,
        setEnableScreenResolutionMessages,
        handleStopScreenStream,
        setShareScreenMediaStreams,
        setShareVideoMediaStream,
        setShareVideoPublishers,
        setShareScreenPublishers,
        setShareMediaParams,
        setShareMillicastVideoWithSound,

        loadingShareVideoModal,
        setLoadingShareVideoModal,

        loadingShareScreenModal,
        setLoadingShareScreenModal,

        showExitButton,
        setShowExitButton,

        openMillicastScreenBroadcastingDataModal,
        setOpenMillicastScreenBroadcastingDataModal,

        ue5CoreWeaveDisabled,
        setUe5CoreWeaveDisabled,

        gameCastVideoRef,
        setGameCastVideoRef,
      }}
    >
      {children}
    </MytaverseEventContext.Provider>
  );
};
