import React from 'react';

import { IconButton, Typography, Box, Skeleton, Tooltip } from '@mui/material';

import { IEventDropdownItemView } from './interfaces';

import { useStyles } from './styles';

const EventDropdownItemView = ({
  event,
  fontColor,
}: IEventDropdownItemView) => {
  const classes = useStyles(fontColor);

  return (
    <Box sx={classes.root} component="div">
      <IconButton sx={classes.iconButton}>
        {event.logoImage ? (
          <img src={event.logoImage || event.image} alt="logo" />
        ) : (
          <Skeleton variant="rectangular" sx={classes.img} />
        )}
      </IconButton>
      <Tooltip title={event.name} disableHoverListener={event.name.length < 26}>
        <Typography variant="h6" component="div" sx={classes.text}>
          {event.name}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default EventDropdownItemView;
