import { useTheme } from '@mui/material';

import { colors } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${colors.oxford_40}`,
      borderRadius: 4,
      width: '100%',
      maxWidth: 390,
      paddingBottom: '56.25%',
      overflow: 'hidden',
      position: 'relative',
      marginTop: '38px',
      marginBottom: '16px',
      '& video': {
        left: 0,
        top: 0,
        width: '100%',
        position: 'absolute',
        pointerEvents: 'auto',
        border: 0,
      },
    },
    selection: {
      position: 'absolute',
      content: '""',
      bottom: 0,
      width: '100%',
      backgroundColor: colors.black_60,
      backdropFilter: 'blur(11px)',
      padding: '10px 16px',
    },
    helper: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.oxford_40,
      marginBottom: '35px',
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
      },
    },
  };
};
