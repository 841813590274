import React from 'react';

import { SwipeableDrawer, Box } from '@mui/material';
import { Chat, Streami18n, SupportedTranslations } from 'stream-chat-react';

import ArrowButton from './ArrowButton';
import ChatHeader from './ChatHeader';
import PrivateTab from './PrivateTab';
import EventTab from './EventTab';
import LoadingProgress from '../../../../components/LoadingProgress';
import ChatTabs from './ChatTabs';

import { checkIsGlobalTab } from './helpers';

import { IChatDrawerView } from './interfaces';

import 'stream-chat-react/dist/css/index.css';
import { useStyles } from './styles';

const ChatDrawerView = ({
  isOpenDrawer,
  toggleDrawer,
  streamClient,
  isLoading,
  language,
  activeTab,
  hasScreenMediaStreams,
  isExpanded,
  setIsExpanded,
}: IChatDrawerView) => {
  const isGlobalTab = checkIsGlobalTab(activeTab);

  const classes = useStyles({ isLoading, hasScreenMediaStreams, isExpanded });

  const i18nInstance = new Streami18n({
    language: language.toLowerCase() as SupportedTranslations,
  });

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpenDrawer}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      hideBackdrop
      disableBackdropTransition
      sx={classes.root}
    >
      <Chat client={streamClient} darkMode i18nInstance={i18nInstance}>
        <ChatHeader />
        {isLoading ? (
          <LoadingProgress />
        ) : (
          <>
            <ChatTabs sx={classes.tabs} />
            {isGlobalTab ? <EventTab /> : <PrivateTab />}
          </>
        )}
      </Chat>
      <Box sx={classes.collapseWrap}>
        <ArrowButton
          onClick={() => setIsExpanded(!isExpanded)}
          forwardIcon={isExpanded}
          sx={classes.collapse}
        />
      </Box>
    </SwipeableDrawer>
  );
};

export default ChatDrawerView;
